@import "../../../index.scss";

.mainContainer {
  background-color: #415aa4f8;
  border-radius: 95px;
  background-image: url("#{$S3-PUBLIC-IMAGES}/CenterBox_lg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  height: 70vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 3rem 3rem 3rem;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
  width: 100%;
  height: 96%;
}

@media (orientation: portrait) {
  @media (max-width: 950px) {
    .mainContainer {
      width: 90vw;
      padding: 2.5rem 2.5rem 3.2rem 2.5rem;
    }
  }
}

@media (orientation: landscape) {
  @media (max-width: 950px) {
    .mainContainer {
      width: 90vw;
    }
  }

  @media (max-height: 600px) {
    .mainContainer {
      height: 80vh;
      padding: 1.5rem;
    }
  }
}
