@import '../../../responsive';

.menu {
  background: white;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  padding: 1rem;

  @include desktop {
    gap: 0.5rem;
    flex-direction: row;
    background: none;
    height: auto;
    align-items: center;

    .link {
      padding: 0 0.5rem;
    }
  }
}

.link {
  padding: 0 1rem;
  text-decoration: none;
  color: hsl(var(--brand));
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}