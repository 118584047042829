html,
body {
    margin: 0px;
    padding: 0px;
    font-family: "Open Sans", sans-serif;
    overflow-x: hidden;
}

@font-face {
    font-family: "tintco_demoregular";
    src: url("../fonts/tintco_demo-webfont.woff2") format("woff2"),
        url("../fonts/tintco_demo-webfont.woff") format("woff"),
        url("../fonts/tintco_demo-webfont.ttf") format("truetype"),
        url("../fonts/tintco_demo-webfont.svg#tintco_demoregular") format("svg");
}

#section1 {
    overflow: scroll;
    overflow-x: hidden;
    position: relative;
}

.arrow_mobile {
    display: none;
}

.forms_box {
    float: left;
    width: 100%;
}

.form_bg {
    float: left;
    width: 100%;
    position: relative;
}

.form_contain {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form_box_main {
    float: none;
    width: 100%;
    margin: 0 auto;
    max-width: 420px;
    height: 350px;
    overflow: hidden;
    overflow-y: scroll;
}

.about_new_page {
    float: left;
    width: 100%;
}

.about_top_pg {
    float: left;
    width: 100%;
    background: url(../images/home/about_top_bg.png) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 0px;
}

.about_top_pg2 {
    float: left;
    width: 100%;
    background: url(../images/home/about_bottom_bg.png) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 0px;
}

.about_top_pg2 h1 {
    color: #591c59 !important;
}

.about_top_pg2 .about_top_contain::after {
    background: #a881b1 !important;
}


.about_new_page {
    float: left;
    width: 100%;
    height: 100%;
}

.advisor {
    float: left;
    width: 100%;
}


.greenc {
    color: #3B5A12;
}

.ad_img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    float: left;
    width: 100%;
}

.advisor_box {
    float: left;
    width: 100%;
    padding: 80px 0;
}

.ad_cotain p {
    font-weight: 500;
}


.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(197, 221, 239, 0.7);
    z-index: 999;
    box-shadow: 0px 0px 44px #dfdfdf;
    transition: 0.5s ease;
    z-index: 99999999;
}

.form_box_main .arrowbtn2 {
    display: none;
}

.form_box_main::-webkit-scrollbar {
    width: 10px;
    margin-left: 5px !important;
}

/* Track */
.form_box_main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px blue;
    border-radius: 10px;
}

/* Handle */
.form_box_main::-webkit-scrollbar-thumb {
    background: blue;
    border-radius: 10px;
}

/* Handle on hover */
.form_box_main::-webkit-scrollbar-thumb:hover {
    background: blue;
}

.form_box_main form {
    padding-right: 35px;
}

.mobile2 {
    display: none !important;
}

.left_girl_section {
    z-index: 99999999 !important;
}

.right_boy_section {
    z-index: 999999999 !important;
}

.about_supermclub {
    float: left;
    width: 100%;
    background: #f8f9f6;
    padding: 100px 0px;
    text-align: center;
    background: url(../images/home/about_bg.png) no-repeat;
    background-position: top center;
    background-size: cover;
    margin-top: -50px;
    position: relative;
}

.container_mini {
    margin: 0 auto;
    max-width: 50%;
}

.font1 {
    font-family: "tintco_demoregular";
}



.about_middle_section {
    float: left;
    width: 100%;
    margin-top: 75px;
    position: relative;
}


.mobile {
    display: none;
}



.about_middle_section::after {
    width: 500px;
    height: 100%;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background: #63065c;
}

.about_right_img {
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.big_number {
    font-size: 45px;
    font-weight: 700;
    color: #63065c;
}

.about_bottom {
    float: left;
    width: 100%;
}

.about_left {
    float: left;
    width: 100%;
    text-align: left;
}

.about_left p {
    margin: 0;
    margin-bottom: 29px;
}

.big_number {
    font-size: 45px;
    font-weight: 700;
}


.navbar-light .navbar-nav .nav-link {
    color: rgb(99 6 92);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: .5px;
    padding: 0px 20px;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgb(99 6 92);
}

header .main_btn {
    padding: 7px 20px !important;
    color: #fff !important;
    margin-left: 15px;
}

.container_fluid {
    max-width: 1280px;
}

.navbar-expand-lg .navbar-nav {
    align-items: center;
}

.mobile_show {
    display: none;
}

header {
    padding: 17px 0px;
}

.inner_pages {
    float: left;
    width: 100%;
    padding: 100px 0px;
    padding-top: 140px;
}


.title {
    font-size: 54px;
    letter-spacing: 2px;
    position: relative;
    color: #3b5a12;
}

.title::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #3b5a12;
    width: 100%;
    height: 5px;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
    width: 120px;
    left: 0;
    right: 0;
    bottom: -13px;
}

.form_contain .subtitle {
    position: relative;
    text-transform: capitalize;
}

.form_contain .subtitle::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #3b5a12;
    width: 100%;
    height: 5px;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
    width: 120px;
    left: 0;
    right: 0;
    bottom: -13px;
}

.form_box_main form .main_btn {
    width: 104px !important;
    padding: 9px;
    font-size: 14px;
}

.plus_minus {
    width: 25px;
}



.para_text {
    font-size: 21px;
    font-weight: 500;
    letter-spacing: .8px;
    margin-top: 57px;
    line-height: 40px;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.paragraph {
    font-size: 18px;
}

.about_ul {
    display: inline-block;
    float: left;
    width: 100%;
    padding-left: 60px;
    margin-bottom: 35px;
}

.about_ul li {
    display: inline-block;
    width: 50%;
    float: left;
    text-align: left;
    font-size: 20px;
    margin: 15px 0px;
}

.about_ul li img {
    width: 54px;
}

.arrowbtn {
    width: 36px;
    margin-left: 10px;
}

.main_btn {
    font-size: 20px;
    color: #3b5a12;
    font-weight: 700;
    letter-spacing: .8px;
}

.about_margin {
    margin-bottom: 48px;
}

.value_box {
    text-align: left;
}

h5 {
    font-size: 24px;
    letter-spacing: 2px;
}

.container_middle {
    margin: 0 auto;
    max-width: 850px;
}

.value_box img {
    float: left;
    width: 155px;
}

.teacher_signup {
    height: auto !important;
    overflow: hidden;
}



.value_box {
    float: left;
    width: 100%;
    margin: 45px 0px;
}

.value_caption {
    float: left;
    width: 100%;
}

.policy a {
    color: #000;
    text-decoration: underline;
}


h5 {
    margin: 12px 0px;
}

.core_value {
    background: url('../images/home/about_bg2.png') no-repeat !important;
    background-size: cover !important;
    float: left;
    width: 100%;
    background-position: bottom !important;
}

.inner_about {
    background: url('../images/home/about_bg3.png') no-repeat !important;
    background-size: cover !important;
    float: left;
    width: 100%;
    background-position: bottom !important;
    margin-top: 35px;
}

.about_top_contain {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    float: left;
    width: 100%;
    position: relative;
    z-index: 999;
}

.about_top_contain::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    background: #acc789;
    height: 50%;
    float: left;
    width: 100%;
    z-index: 9;
}

.about_top_image {
    float: left;
    position: relative;
    z-index: 99;
    left: 92px;
}

.about_top_caption {
    text-align: center;
    position: relative;
    z-index: 99;
    float: left;
    width: 100%;
    padding-left: 55px;
}

.about_top_contain h1 {
    color: #3c5a2a;
}

.purple {
    float: left;
    width: 100%;
    background: url('../images/home/purple.png') no-repeat !important;
    background-size: cover !important;
    background-position: center;
    padding: 80px 0px;
    padding-bottom: 140px;
}

.container {
    margin: 0 auto;
    max-width: 1180px;
}

.purplec {
    color: #63065c !important;
}

.purplec::after {
    border-color: #63065c !important;
}

.subtitle {
    /* margin: 16px 0px; */
    font-size: 39px;
}

.bold {
    font-weight: 600 !important;
}

.padding_right {
    padding-right: 48px;
}

.padding_left {
    padding-left: 55px;
}

.btn2 {
    background: #62065b;
    color: #fff;
    padding: 13px 25px;
    width: auto;
    display: block;
    float: left;
    border-radius: 8px;
    font-size: 17px;
    font-weight: 500 !important;
    display: inline-block;
}

.btn2 img {
    margin-left: 10px;
}

.black .form_control {
    color: #000;
    border-color: #ab692e59;
}

.black .form_control:focus {
    border-color: #ab692e59;
}

/* .black .form_control::placeholder {
    color: #000;
} */

.banner_img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}



.why_superme {
    float: left;
    width: 100%;
    padding: 56px 0px;
}

.form_left {
    float: left;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: auto;
    transform: translate(0%, -50%);
    width: 408px;
}

.form_img {
    float: left;
    width: 100%;
    overflow-x: hidden;
}

.form {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: url(../images/home/formbg.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    height: 678px;
    margin-top: -80px;
}

.para_margin {
    margin: 30px 0px;
}

.policy ul {
    list-style: disc;
    padding-left: 20px;
}

.black {
    color: #000;
}

.policy ul li {
    margin-bottom: 10px;
}

.policy span {
    display: block;
}

.base_footer p {
    color: #fff;
    padding-top: 11px;
}

.base_footer ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.base_footer ul li {
    margin-left: 20px;
}

.base_footer {
    float: left;
    width: 100%;
    margin-top: 40px;
    padding: 10px 0px;
    border-top: 2px solid #6c2167;
}

footer h5 {
    font-weight: 600;
    color: #fff;
    letter-spacing: 0;
    font-size: 21px;
}

.logo2 {
    display: none;
}

.subscribe {
    font-weight: 300 !important;
    font-size: 18px;
    line-height: 27px;
}

footer ul a {
    font-size: 17px;
    color: #fff;
    margin: 10px 0pc;
    display: block;
}

.arrowbtn2 {
    width: 25px;
}

.form_left p {
    color: #fff;
    font-weight: 400;
}

h3 {
    font-size: 29px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fff;
    margin-bottom: 25px;
    margin-top: -10px;
}

form .form_control {
    background: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #fff;
    color: #fff;
    padding-left: 0;
    margin-bottom: 27px;
    font-weight: 500;
    position: relative;
}

.form_contain form .form_control {
    margin-bottom: 10px;
    text-transform: unset;
}

form .form_control::placeholder {
    color: #fff;
    opacity: 1;
}

.footer_form {
    position: relative;
}

.footer_form label {
    position: absolute;
    right: 5px;
    top: 17px;
}

.footer_form label img {
    width: 22px;
}

.main_banner {
    width: 100vw;
    background: url("../images/website/banner.png") no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    /* float: left; */
    /* position: relative; */
}

.banner_caption {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    float: none;
    width: 80%;
    margin: 0 auto;
}

.main_heading {
    font-size: 72px;
    padding: 0 !important;
}

.banner_caption .para_text {
    margin-top: 19px;
    line-height: 39px;
}


.banner_img img {
    width: 75%;
}

.banner_caption a {
    margin: 0 auto;
    float: none;
    width: 219px;
    margin-top: 35px;
}




.social {
    display: flex;
}

.footer_links li img {
    margin-right: 10px;
}

.footer_colum_padding {
    padding-left: 30px;
}

.form_control:focus {
    color: #212529;
    outline: 0;
    box-shadow: none;
    background: transparent;
    border-color: #fff;
}

.btn3 img {
    margin-left: 20px;
}

.btn3 {
    color: #fff;
    background: transparent;
    border: 2px solid #fff;
}

.btn2:hover {
    background: #3b5a12;
    color: #fff;
}

.btn1:hover {
    color: #833b71;
}

.btn3:hover {
    color: #fff;
    background: #63065c;
    border: 2px solid #63065c;
}

.btn1:hover img {
    filter: hue-rotate(219deg);
}

footer a:hover {
    text-decoration: none;
    color: #8cc63f;
}

.school_name {
    position: relative;
    width: 100%;
}

.school_name input {
    position: relative;
}

button {
    border: none;
    background: transparent;
}

.school_btn {
    position: relative;
    right: 18px;
    top: 20px;
    display: flex;
}

.forms_box p {
    text-transform: capitalize;
}

.forms_box h5 {
    text-transform: capitalize;
}


@media(max-width:1600px) {
    .about_top_image img {
        height: 450px;
    }

    .about_top_contain h1 {
        color: #3c5a2a;
        font-size: 68px;
    }

    .main_heading {
        font-size: 63px;
        padding: 0 !important;
    }

    .forms_box {
        float: left;
        width: 100%;
        margin-top: 59px;
    }

    .form_box_main {
        margin: 0 auto;
        max-width: 355px;
        height: 289px;
    }

    .school_btn {
        position: relative;
        right: 18px;
        top: 11px;
        display: flex;
    }

    .para_text {
        font-size: 18px;
    }

    .title {
        font-size: 44px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 54px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 50px;
        margin-bottom: 20px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 35px;
    }

    .para_margin {
        margin: 24px 0px;
    }






}



@media(max-width:1440px) {
    .main_heading {
        font-size: 53px;
        padding: 0 !important;
    }

    .about_middle_section::after {
        width: 380px;
        height: 100%;
    }

    .caption_form {
        width: 90% !important
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -100px;
    }

    .paragraph {
        font-size: 16px;
    }

    .form {
        height: 558px;
        margin-top: -80px;
    }

    footer ul a {
        font-size: 16px;
    }

    .para_text {
        font-size: 18px;
    }

    .title {
        font-size: 44px;
        letter-spacing: 2px;
    }

    .form_contain {
        width: 100%;
    }

    .about_ul li img {
        width: 54px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 50px;
        margin-bottom: 20px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 35px;
    }

    .para_margin {
        margin: 24px 0px;
    }






}



@media(max-width:1366px) {
    .about_middle_section::after {
        width: 320px;
        height: 100%;
    }

    .about_top_contain h1 {
        color: #3c5a2a;
        font-size: 60px;
    }

    .about_top_image img {
        height: 400px;
    }

    .about_top_image {
        float: left;
        position: relative;
        z-index: 99;
        left: 15px;
    }

    .big_number {
        font-size: 35px;
        font-weight: 700;
    }

    .banner_caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        float: none;
        width: 93%;
        margin: 0 auto;
    }

    .form_box_main {
        margin: 0 auto;
        max-width: 350px;
    }

    .caption_form {
        width: 100% !important;
    }

    .main_heading {
        font-size: 47px;
        padding: 0 !important;
        width: 80%;
        margin: 0 auto;
    }

    .banner_caption .para_text {
        margin-top: 12px;
        line-height: 32px;
    }

    .banner_caption a {
        margin: 0 auto;
        float: none;
        width: 219px;
        margin-top: 20px;
    }

    .title {
        font-size: 38px;
        letter-spacing: 2px;
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -100px;
    }

    .paragraph {
        font-size: 16px;
    }

    .form {
        height: 480px;
        margin-top: -80px;
    }

    .footer_form label {
        position: absolute;
        right: 5px;
        top: 7px;
    }

    .arrowbtn2 {
        width: 21px;
    }

    footer ul a {
        font-size: 16px;
    }

    .para_text {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-top: 43px;
        line-height: 33px;
    }

    .title {
        font-size: 44px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 54px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 50px;
        margin-bottom: 20px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 35px;
    }

    .para_margin {
        margin: 24px 0px;
    }






}


@media(max-width:1280px) {
    .about_top_contain h1 {
        color: #3c5a2a;
        font-size: 57px;
    }

    .advisor_box {
        float: left;
        width: 100%;
        padding: 60px 0;
    }

    .main_btn {
        font-size: 18px;
        color: #3b5a12;
        font-weight: 700;
        letter-spacing: 0px;
    }

    .banner_caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        float: none;
        width: 93%;
        margin: 0 auto;
    }

    form .form_control {
        font-size: 15px;
    }

    .btn2 {
        background: #62065b;
        color: #fff;
        padding: 10px 25px;
        width: auto;
        display: block;
        float: left;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500 !important;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 25px;
        margin-top: -10px;
    }

    .container {
        margin: 0 auto;
        max-width: 1080px;
    }

    .main_heading {
        font-size: 47px;
        padding: 0 !important;
        width: 80%;
        margin: 0 auto;
    }

    .banner_caption .para_text {
        margin-top: 12px;
        line-height: 32px;
        font-size: 16px;
    }

    .banner_caption a {
        margin: 0 auto;
        float: none;
        width: 219px;
        margin-top: 20px;
    }

    .title {
        font-size: 38px;
        letter-spacing: 2px;
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -100px;
    }

    .paragraph {
        font-size: 16px;
    }

    .form {
        height: 480px;
        margin-top: -80px;
    }

    .footer_form label {
        position: absolute;
        right: 5px;
        top: 7px;
    }

    .arrowbtn2 {
        width: 21px;
    }

    footer ul a {
        font-size: 15px;
    }

    .para_text {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-top: 43px;
        line-height: 33px;
    }

    .title {
        font-size: 36px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 54px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 50px;
        margin-bottom: 20px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 30px;
        letter-spacing: 1.5px;
    }

    .para_margin {
        margin: 24px 0px;
    }

    .btn3 {
        background: transparent !important;
    }

    .about_top_image img {
        height: 360px;
    }




}


@media(max-width:1200px) {
    .base_footer p {
        color: #fff;
        padding-top: 11px;
        font-size: 15px;
    }



    .navbar-light .navbar-nav .nav-link {
        color: rgb(99 6 92);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: .5px;
        padding: 0px 20px;
    }

    .main_btn {
        font-size: 18px;
        color: #3b5a12;
        font-weight: 700;
        letter-spacing: 0px;
    }

    .padding_right {
        padding-right: 32px;
    }

    .banner_caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        float: none;
        width: 93%;
        margin: 0 auto;
    }

    form .form_control {
        font-size: 15px;
    }

    .btn2 {
        background: #62065b;
        color: #fff;
        padding: 10px 25px;
        width: auto;
        display: block;
        float: left;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500 !important;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 25px;
        margin-top: -10px;
    }

    .container {
        margin: 0 auto;
        max-width: 1080px;
    }

    .main_heading {
        font-size: 47px;
        padding: 0 !important;
        width: 80%;
        margin: 0 auto;
    }

    .banner_caption .para_text {
        margin-top: 12px;
        line-height: 32px;
        font-size: 16px;
    }

    .banner_caption a {
        margin: 0 auto;
        float: none;
        width: 219px;
        margin-top: 20px;
    }

    .title {
        font-size: 38px;
        letter-spacing: 2px;
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -100px;
    }

    .paragraph {
        font-size: 16px;
    }

    .form {
        height: 480px;
        margin-top: -80px;
    }

    .footer_form label {
        position: absolute;
        right: 5px;
        top: 7px;
    }

    .arrowbtn2 {
        width: 21px;
    }

    footer ul a {
        font-size: 15px;
    }

    .para_text {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-top: 43px;
        line-height: 33px;
    }

    .title {
        font-size: 36px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 54px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 50px;
        margin-bottom: 20px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 30px;
        letter-spacing: 1.5px;
    }

    .para_margin {
        margin: 24px 0px;
    }

    .btn3 {
        background: transparent !important;
    }


    .policy p {
        font-size: 14px;
    }

    .policy ul li {
        font-size: 14px;
    }

}



@media(max-width:1024px) {
    .about_top_contain h1 {
        color: #3c5a2a;
        font-size: 49px;
    }

    .about_top_pg {
        padding: 31px 0px;
    }

    .ad_img img {
        margin: 0 auto;
        width: 90%;
    }

    .inner_about {
        padding-top: 95px !important;
    }

    .about_middle_section::after {
        width: 280px;
        height: 100%;
    }

    .padding_left {
        padding-left: 0px;
    }

    .plus_minus {
        width: 18px;
    }

    .school_btn ul libutton {
        margin: 0;
        padding: 0;
    }

    .form_center {
        margin: 0 auto !important;
        width: 660px;
    }

    .main_btn {
        font-size: 18px;
        color: #3b5a12;
        font-weight: 700;
        letter-spacing: 0px;
    }

    .mobile {
        display: none;
    }

    .padding_right {
        padding-right: 0px;
    }

    .banner_caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        float: none;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    form .form_control {
        font-size: 15px;
    }

    .btn2 {
        background: #62065b;
        color: #fff;
        padding: 10px 25px;
        width: auto;
        display: block;
        float: left;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500 !important;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 25px;
        margin-top: -10px;
    }

    .container {
        margin: 0 auto;
        max-width: 840px;
    }

    .main_heading {
        font-size: 47px;
        padding: 0 !important;
        width: 100%;
        margin: 0 auto;
    }

    .banner_center {
        width: 100%;
        padding: 234px;
    }


    .main_banner {
        height: 90;
    }

    .about_supermclub {
        padding: 59px 0px;
    }



    .banner_caption .para_text {
        margin-top: 12px;
        line-height: 32px;
        font-size: 16px;
    }

    .banner_caption a {
        margin: 0 auto;
        float: none;
        width: 219px;
        margin-top: 20px;
    }

    .title {
        font-size: 38px;
        letter-spacing: 2px;
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -100px;
    }

    .paragraph {
        font-size: 16px;
    }

    .form {
        height: 428px;
        margin-top: -80px;
    }

    .subscribe {
        font-weight: 300 !important;
        font-size: 14px;
        line-height: 27px;
    }

    .footer_form label {
        position: absolute;
        right: 5px;
        top: 7px;
    }

    .arrowbtn2 {
        width: 21px;
    }

    footer ul a {
        font-size: 15px;
    }

    .para_text {
        font-size: 17px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-top: 34px;
        line-height: 31px;
    }

    .title {
        font-size: 36px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 54px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 50px;
        margin-bottom: 20px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 26px;
        letter-spacing: 1.5px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 37px 0px;
    }

    .para_margin {
        margin: 24px 0px;
    }

    .btn3 {
        background: transparent !important;
    }

    .privacy {
        font-size: 14px;
    }

    .policy p {
        font-size: 14px;
        text-align: justify;
    }

    .policy span {
        display: block;
        font-size: 15px;
    }

    .core_value {
        margin-top: -50px;
    }

    .image_left {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0%;
        z-index: 9999;
        top: auto;
        height: 100%;
        display: flex;
    }

    .image_right {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0%;
        z-index: 9999;
        top: auto;
        height: 100%;
        display: flex;
    }


}


@media(max-width:768px) {
    .header_collapse .btn2 img {
        margin-left: 5px;
        width: 22px;
        display: block;
    }

    .about_top_contain h1 {
        color: #3c5a2a;
        font-size: 37px;
        margin: 0 !important;
        width: 100%;
    }

    .ad_img {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        float: left;
        width: 100%;
        margin-bottom: 9px;
        text-align: left;
    }

    .ad_img img {
        width: 250px;
        float: left;
        margin: inherit;
    }

    .ad_cotain h4 {
        font-size: 18px;
    }

    .ad_cotain h5 {
        font-size: 18px;
    }

    .ad_cotain {
        text-align: left !important;
    }

    .advisor_box {
        float: left;
        width: 100%;
        padding: 40px 0;
    }

    .about_top_image img {
        height: 260px;
    }

    .header_collapse .btn2 {
        margin-top: 0 !important;
    }

    .form_img {
        float: left;
        width: 100%;
        overflow-x: hidden;
        position: relative;
        top: 70px;
    }

    .about_supermclub p {
        text-align: left !important;
    }

    .mobile_btn {
        position: relative;
        right: -80px;
        top: 0;
        margin-top: 0;
    }

    .navbar-light .navbar-toggler {
        color: rgb(58 58 58 / 0%);
        border-color: transparent;
        opacity: 1;
    }

    .header_collapse {
        background-color: rgb(98 6 91);
    }

    .header_collapse .navbar-light .navbar-toggler-icon {
        background-image: url(../images/home/close.png);
        background-size: 20px;
    }


    .header_collapse .mobile_btn {
        opacity: 0;
        transition: .2s ease;
    }

    .header_collapse .main_btn {
        background: #fff;
        color: #62065b !IMPORTANT;
        width: auto;
        display: flex;
        align-items: center;
    }

    .logo2 {
        display: none;
    }

    .header_collapse .logo1 {
        display: none;
    }

    .header_collapse .logo2 {
        display: block;
    }

    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .show>.nav-link {
        color: rgb(255 255 255);
    }

    .navbar-light .navbar-nav .nav-link {
        color: rgb(255 255 255);
        font-size: 18px;
        font-weight: 600;
        letter-spacing: .5px;
        padding: 10px 0px;
    }

    .navbar-light .navbar-brand {
        color: rgba(0, 0, 0, .9);
        margin-right: 0;
    }


    .banner_img img {
        width: 121px;
    }

    .image_right {
        display: block;
        position: absolute;
        right: 8%;
        z-index: 9999;
        top: auto;
        height: 100%;
        display: flex;
        bottom: -70%;
    }

    .image_left {
        display: block;
        position: absolute;
        left: 8%;
        z-index: 9999;
        top: auto;
        height: 100%;
        display: flex;
        bottom: -70%;
    }

    .form_box_main form {
        padding-right: 9px;
    }

    .inner_about {
        margin-top: 15px;
    }

    .about_middle_section::after {
        width: 100%;
        height: 237px;
        bottom: -66px;
        top: auto;
    }

    .about_bottom {
        float: left;
        width: 100%;
        margin-top: 112px !IMPORTANT;
    }

    .big_number {
        font-size: 25px;
        font-weight: 700;
    }

    .container_middle {
        margin: 0 auto;
        max-width: 550px;
    }

    .form_center {
        margin: 0 auto !important;
        width: 660px;
    }

    .caption_form {
        width: 100% !important;
        top: 56% !important;
    }

    .form_main_bg {
        height: 100vh !important;
    }

    .form_bg img {
        object-fit: cover;
        height: auto !important;
        object-fit: fill;
        margin-bottom: -17px;
    }

    .container_mini {
        margin: 0 auto;
        max-width: 550px;
    }

    .core_value {
        margin-top: -50px;
    }

    .base_footer p {
        color: #fff;
        padding-top: 11px;
        font-size: 15px;
        text-align: left;
    }

    .base_footer ul {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        flex-direction: column;
    }

    .base_footer ul li {
        margin-left: 0;
        margin-right: 0px;
    }

    .base_footer ul li a {
        margin: 8px 0;
    }

    .form_left {
        float: left;
        width: 100%;
        flex-direction: column;
        position: relative;
        top: 50%;
        left: auto;
        transform: translate(0%, -50%);
        width: 100%;
        text-align: left;
        margin-top: 195px;
    }


    .about_margin {
        display: block;
        float: left;
        width: 100%;
        margin-top: 8px !important;
    }

    .padding_left {
        padding-left: 0px;
    }

    .main_btn {
        font-size: 18px;
        color: #3b5a12;
        font-weight: 700;
        letter-spacing: 0px;
    }


    .padding_right {
        padding-right: 0px;
    }

    .banner_caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        float: none;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    form .form_control {
        font-size: 15px;
    }

    .btn2 {
        background: #62065b;
        color: #fff;
        padding: 10px 25px;
        width: auto;
        display: block;
        float: left;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500 !important;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 25px;
        margin-top: -10px;
    }

    .container {
        margin: 0 auto;
        max-width: 640px;
    }

    .main_heading {
        font-size: 37px;
        padding: 0 !important;
        width: 77%;
        margin: 0 auto;
    }

    .banner_center {
        width: 100%;
        padding: 0px 100px;
    }

    .main_banner {
        height: 90vh !important;
    }

    .about_supermclub {
        padding: 59px 0px;
    }



    .banner_caption .para_text {
        margin-top: 12px;
        line-height: 32px;
        font-size: 16px;
    }

    .banner_caption a {
        margin: 0 auto;
        float: none;
        width: 219px;
        margin-top: 20px;
    }

    .about_ul li {
        display: inline-block;
        width: 50%;
        float: left;
        text-align: left;
        font-size: 18px;
        margin: 9px 0px;
    }

    .title {
        font-size: 38px;
        letter-spacing: 2px;
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -30px !important;
    }

    .paragraph {
        font-size: 16px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 0;
    }

    .reverse {
        flex-direction: column-reverse;
    }

    .why_img {
        padding: 0 !important;
        width: 450px;
    }

    .purple .title {
        margin-bottom: 50px;
    }


    .why_caption {
        text-align: left;
        margin-top: 34px;
    }


    .why_caption .btn2 {
        margin: 0 auto;
        float: left;
        width: auto;
        text-align: center;
    }

    .footer_links {
        float: left;
        width: 100%;
        margin: 5px 0px;
    }

    .navbar-expand-lg .navbar-nav .purple {
        padding: 47px 0px;
        padding-bottom: 160px;
    }

    .footer_logo {
        float: left;
        width: 100%;
        margin-bottom: 10px;
    }

    .footer_colum_padding {
        padding-left: 0;
    }

    .form {
        height: auto !important;
        margin-top: -80px;
        background: url(../images/home/mobile_formbg.png) no-repeat !important;
        background-size: cover !important;
    }

    .subscribe {
        font-weight: 400 !important;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: .5px;
    }

    .mobile_show {
        display: block;
    }

    .footer_form label {
        position: absolute;
        right: 5px;
        top: 7px;
    }

    .arrowbtn2 {
        width: 21px;
    }

    footer ul a {
        font-size: 15px;
    }

    .para_text {
        font-size: 17px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-top: 34px;
        line-height: 31px;
    }


    .about_supermclub .main_btn {
        display: block;
        float: left;
        width: 100%;
    }

    .value_box img {
        float: none;
        width: 200px;
        margin: 0 auto;
    }

    .value_box_inner {
        float: none !important;
        width: 250px !important;
        text-align: left !important;
        margin: 0 auto !important;
    }

    .title {
        font-size: 29px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 44px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 50px;
        margin-bottom: 20px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 26px;
        letter-spacing: 1.5px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 37px 0px;
    }

    .para_margin {
        margin: 24px 0px;
    }

    .btn3 {
        background: transparent !important;
        margin: 0 auto;
        float: left;
        width: auto;
        margin-top: 19px;
        color: #fff !important;
        text-align: center;
    }

    footer ul a {
        margin: 18px 0;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .footer_form .form_control {
        font-weight: 300;
    }

    .footer_links li img {
        margin-right: 16px;
    }

    .about_ul {
        display: inline-block;
        float: none !important;
        width: 100%;
        padding-left: 0;
        margin: 0 auto !important;
        margin-bottom: 35px !important;
        max-width: 250px;
    }

    .mobile2 {
        display: block !important;
    }

    .navbar-nav {
        height: 80vh;
        align-items: center;
        justify-content: center;
    }


}


@media(max-width:600px) {

    .navbar-expand-lg .navbar-nav {
        align-items: center !important;
    }

    .about_top_caption {
        padding-left: 0;
    }

    .about_top_contain h1 {
        color: #3c5a2a;
        font-size: 26px;
        margin: 0 !important;
        width: 100%;
    }

    .about_top_image img {
        height: 200px;
    }

    .about_top_pg {
        padding: 18px 0px;
    }

    .mobile_btn {
        position: relative;
        right: -11px;
    }

    .container_middle {
        margin: 0 auto;
        max-width: 450px;
    }

    .forms_box {
        float: left;
        width: 100%;
        margin-top: 0;
    }

    .school_btn {
        position: relative;
        right: 0 !important;
        top: 11px;
        display: flex;
    }

    .form_bg img {
        object-fit: cover;
        object-fit: fill;
        margin-bottom: 30px;
        width: 95%;
    }

    .container_mini {
        margin: 0 auto;
        max-width: 450px;
    }

    .form_box_main {
        margin: 0 auto;
        height: 242px;
        margin-bottom: 50px !important;
    }

    .container {
        margin: 0 auto;
        max-width: 500px;
    }

    .form_left {
        float: left;
        width: 100%;
        flex-direction: column;
        position: relative;
        top: 0;
        left: auto;
        transform: translate(0%, 0);
        width: 100%;
        text-align: left !important;
        margin-top: 45px;
        margin-bottom: 50px;
    }

    .about_margin {
        display: block;
        float: left;
        width: 100%;
        margin-top: -8px !important;
    }

    .padding_left {
        padding-left: 0px;
    }

    .main_btn {
        font-size: 18px;
        color: #3b5a12;
        font-weight: 700;
        letter-spacing: 0px;
    }

    .mobile {
        display: none;
    }

    .padding_right {
        padding-right: 0px;
    }

    .banner_caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        float: none;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    form .form_control {
        font-size: 15px;
    }

    .btn2 {
        background: #62065b;
        color: #fff;
        padding: 10px 25px;
        width: auto;
        display: block;
        float: left;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500 !important;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 25px;
        margin-top: -10px;
    }

    .main_heading {
        font-size: 37px;
        padding: 0 !important;
        width: 77%;
        margin: 0 auto;
    }

    .banner_center {
        width: 100%;
        padding: 0px 100px;
    }

    .main_banner {
        height: 75vh;
    }

    .about_supermclub {
        padding: 59px 0px;
    }



    .banner_caption .para_text {
        margin-top: 12px;
        line-height: 32px;
        font-size: 16px;
    }

    .banner_caption a {
        margin: 0 auto;
        float: none;
        width: 219px;
        margin-top: 20px;
    }

    .about_ul li {
        display: inline-block;
        width: 100%;
        float: left;
        text-align: left;
        font-size: 18px;
        margin: 7px 0px;
        letter-spacing: .8px;
    }

    .title {
        font-size: 38px;
        letter-spacing: 2px;
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -30px !important;
    }

    .paragraph {
        font-size: 15px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 15px 0px;
    }

    .purple .title {
        margin-bottom: 0px;
    }

    .reverse {
        flex-direction: column-reverse;
    }

    .why_img {
        padding: 0px 70px;
    }

    .why_caption {
        text-align: left !important;
        margin-top: 0;
    }

    .why_caption .btn2 {
        margin: 0 auto;
        float: left;
        width: auto;
    }

    .footer_links {
        float: left;
        width: 100%;
        margin: 5px 0px;
    }

    .navbar-expand-lg .navbar-nav {
        align-items: flex-start;
        margin-top: 33px;
    }

    .purple {
        padding: 47px 0px;
        padding-bottom: 160px;
    }

    .footer_logo {
        float: left;
        width: 100%;
        margin-bottom: 10px;
    }

    .footer_colum_padding {
        padding-left: 0;
    }

    .form {
        height: auto !important;
        margin-top: -80px;
        background: url(../images/home/mobile_formbg.png) no-repeat !important;
        background-size: cover !important;
    }

    .subscribe {
        font-weight: 400 !important;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: .5px;
    }

    .mobile_show {
        display: block;
    }

    .footer_form label {
        position: absolute;
        right: 5px;
        top: 7px;
    }

    .arrowbtn2 {
        width: 21px;
    }

    footer ul a {
        font-size: 15px;
    }

    .para_text {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-top: 34px;
        line-height: 29px;
    }

    .about_supermclub .main_btn {
        display: block;
        float: left;
        width: 100%;
    }

    .value_box img {
        float: none;
        width: 200px;
        margin: 0 auto;
    }

    .value_box_inner {
        float: left;
        width: 100%;
        text-align: center;
    }

    .title {
        font-size: 29px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 44px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 0;
        margin-bottom: 14px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 26px;
        letter-spacing: 1.5px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 37px 0px;
    }

    .para_margin {
        margin: 24px 0px;
    }

    .btn3 {
        background: transparent !important;
        margin: 0 auto;
        float: left !important;
        width: auto !important;
        margin-top: 19px;
        color: #fff !important;
        text-align: center;
    }





}


@media(max-width:480px) {
    .about_top_contain h1 {
        color: #3c5a2a;
        font-size: 22px;
        margin: 0 !important;
        width: 100%;
    }

    .about_top_caption {
        padding-left: 10px;
    }

    .about_top_image img {
        height: 164px;
    }

    .ad_img img {
        width: 200px;
        float: left;
        margin: inherit;
    }

    .ad_cotain p {
        font-weight: 500;
        font-size: 15px;
    }

    .image_right {
        display: block;
        position: absolute;
        right: 2%;
        z-index: 9999;
        top: auto;
        height: 100%;
        display: flex;
        bottom: -60%;
    }

    .image_left {
        display: block;
        position: absolute;
        left: 2%;
        z-index: 9999;
        top: auto;
        height: 100%;
        display: flex;
        bottom: -60%;
    }


    .container_middle {
        margin: 0 auto;
        max-width: 390px;
    }

    .about_middle_section {
        margin-top: 42px;
    }

    .about_middle_section::after {
        width: 100%;
        height: 140px;
        bottom: -32px;
        top: auto;
    }

    .about_bottom {
        float: left;
        width: 100%;
        margin-top: 102px !IMPORTANT;
    }

    .about_left p {
        margin: 0;
        margin-bottom: 17px;
    }

    .form_bg img {
        object-fit: cover;
        height: 745px !important;
        object-fit: fill;
        margin-bottom: 30px;
        width: 400px;
    }

    .form_box_main {
        margin: 0 auto;
        height: 360px;
        margin-bottom: 50px !important;
        max-width: 314px;
    }

    .form_box_main form .main_btn {
        width: 88px !important;
        padding: 9px;
        font-size: 14px;
        margin-top: 25px;
    }

    .form_contain {
        position: absolute;
        z-index: 99;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 309px;
    }

    .form_box_main form .form_control {
        margin-bottom: 18px;
    }



    .base_footer p {
        color: #fff;
        padding-top: 11px;
        font-size: 13px;
        text-align: left;
    }

    .base_footer ul a {
        font-size: 13px;
    }

    .why_img {
        padding: 0;
        width: 100% !important;
    }

    .container_mini {
        margin: 0 auto;
        max-width: 390px;
    }

    .container {
        margin: 0 auto;
        max-width: 390px;
    }


    .about_margin {
        display: block;
        float: left;
        width: 100%;
        margin-top: -8px !important;
        text-align: center !important;
    }

    .padding_left {
        padding-left: 0px;
    }

    .main_btn {
        font-size: 15px;
        color: #3b5a12;
        font-weight: 700;
        letter-spacing: 0px;
    }

    .mobile {
        display: none;
    }

    .padding_right {
        padding-right: 0px;
    }

    .banner_caption {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        float: none;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    form .form_control {
        font-size: 15px;
    }

    .btn2 {
        background: #62065b;
        color: #fff;
        padding: 10px 25px;
        width: auto;
        display: block;
        float: left;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500 !important;
    }

    h3 {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 21px;
        margin-top: -15px;
    }

    .main_heading {
        font-size: 30px;
        padding: 0 !important;
        width: 100%;
        margin: 0 auto;
    }

    .banner_center {
        width: 100%;
        padding: 0px 75px;
    }

    .main_banner {
        height: 75vh;
    }

    .about_supermclub {
        padding: 59px 0px;
    }



    .banner_caption .para_text {
        margin-top: 12px;
        line-height: 32px;
        font-size: 16px;
    }

    .banner_caption a {
        margin: 0 auto;
        float: none;
        width: 165px;
        margin-top: 20px;
        padding: 10px;
    }

    .banner_img img {
        height: 221px;
        object-fit: contain;
    }

    .about_ul li {
        display: inline-block;
        width: 100%;
        float: left;
        text-align: left;
        font-size: 18px;
        margin: 7px 0px;
        letter-spacing: .8px;
    }

    .title {
        font-size: 38px;
        letter-spacing: 2px;
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -130px;
    }

    .paragraph {
        font-size: 16px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 0;
    }

    .reverse {
        flex-direction: column-reverse;
    }

    .why_img {
        padding: 0 !important;
        width: 450px;
    }

    .why_caption {
        text-align: center;
        margin-top: 34px;
    }


    .why_caption .btn2 {
        margin: 0 auto;
        float: left !important;
        width: auto !important;
    }

    .form_img {
        top: 36px;
    }

    .form_left {
        margin-top: 15px;
        margin-bottom: 50px;
    }

    .footer_links {
        float: left;
        width: 100%;
        margin: 5px 0px;
    }

    .navbar-expand-lg .navbar-nav {
        align-items: flex-start;
        margin-top: 33px;
    }

    .purple {
        padding: 47px 0px;
        padding-bottom: 160px;
    }

    .footer_logo {
        float: left;
        width: 100%;
        margin-bottom: 10px;
    }

    .footer_colum_padding {
        padding-left: 0;
    }

    .form {
        height: auto !important;
        margin-top: -80px;
        background: url(../images/home/mobile_formbg.png) no-repeat !important;
        background-size: cover !important;
    }



    .mobile_show {
        display: block;
    }

    .footer_form label {
        position: absolute;
        right: 5px;
        top: 7px;
    }

    .arrowbtn2 {
        width: 21px;
    }

    footer ul a {
        font-size: 15px;
    }

    .para_text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-top: 34px;
        line-height: 26px;
        margin-bottom: 26px;
    }

    .about_supermclub .main_btn {
        display: block;
        float: left;
        width: 100%;
    }

    .value_box img {
        float: none;
        width: 156px;
        margin: 0 auto;
    }

    .value_box_inner {
        float: left;
        width: 100%;
        text-align: center;
    }

    .title {
        font-size: 25px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 44px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 0;
        margin-bottom: 14px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 28px;
        letter-spacing: 1px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 31px 0px;
    }

    .footer_logo img {
        width: 160px;
    }

    .para_margin {
        margin: 18px 0px;
        margin-top: -11px;
    }

    .btn3 {
        background: transparent !important;
        margin: 0 auto;
        float: none;
        width: 204px;
        margin-top: 19px;
        color: #fff !important;
        text-align: center;
    }


    .inner_pages {
        float: left;
        width: 100%;
        padding: 40px 0px;
        padding-top: 80px;
    }

    header .main_btn {
        padding: 6px 13px !important;
        color: #fff !important;
        margin-left: 0;
        font-size: 12px;
    }

    .navbar-brand img {
        width: 210px !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    header {
        padding: 13px 0px;
    }

}



@media(max-width:414px) {
    .about_top_caption {
        padding-left: 10px;
    }

    .about_top_image img {
        height: 134px;
    }

    .ad_img img {
        width: 180px;
        float: left;
        margin: inherit;
    }

    .ad_cotain h4 {
        font-size: 17px;
    }

    .ad_cotain p {
        font-weight: 500;
        font-size: 15px;
    }

    .navbar-brand img {
        width: 196px !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .container_middle {
        margin: 0 auto;
        max-width: 337px;
    }

    .container_mini {
        margin: 0 auto;
        max-width: 350px;
    }

    .big_number {
        font-size: 20px;
        font-weight: 700;
    }

    .form_bg img {
        object-fit: cover;
        height: 650px !important;
        object-fit: fill;
        margin-bottom: 30px;
        width: 360px;
    }

    .form_box_main form .form_control {
        margin-bottom: 11px;
    }

    .container {
        margin: 0 auto;
        max-width: 350px;
    }


    .about_margin {
        display: block;
        float: left;
        width: 100%;
        margin-top: -8px !important;
    }

    .padding_left {
        padding-left: 0px;
    }

    .main_btn {
        font-size: 15px;
        color: #3b5a12;
        font-weight: 700;
        letter-spacing: 0px;
    }

    .mobile {
        display: none;
    }

    .padding_right {
        padding-right: 0px;
    }

    .banner_caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        float: none;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    form .form_control {
        font-size: 15px;
        padding: 11px 0px;
        margin-bottom: 17px;
    }

    .btn2 {
        background: #62065b;
        color: #fff;
        padding: 10px 25px;
        width: auto;
        display: block;
        float: left;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500 !important;
    }

    h3 {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 17px;
        margin-top: -16px;
    }

    .main_heading {
        font-size: 30px;
        padding: 0 !important;
        width: 100%;
        margin: 0 auto;
    }

    .banner_center {
        width: 100%;
        padding: 0px 60px;
    }

    .value_box_inner {
        float: none !important;
        width: 218px !important;
        text-align: left !important;
        margin: 0 auto !important;
    }

    .main_banner {
        height: 75vh;
    }

    .about_supermclub {
        padding: 59px 0px;
    }

    .banner_caption .para_text {
        margin-top: 9px;
        line-height: 26px;
        font-size: 15px;
    }

    .banner_caption a {
        margin: 0 auto;
        float: none;
        width: auto;
        margin-top: 0;
        display: inline-block;
        font-size: 14px;
        padding: 9px 13px;
    }

    .about_ul li {
        display: inline-block;
        width: 100%;
        float: left;
        text-align: left;
        font-size: 18px;
        margin: 7px 0px;
        letter-spacing: .8px;
    }

    .title {
        font-size: 38px;
        letter-spacing: 2px;
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -130px;
    }

    .paragraph {
        font-size: 15px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 0;
    }

    .reverse {
        flex-direction: column-reverse;
    }




    .why_caption {
        text-align: center;
        margin-top: 34px;
    }

    .why_caption .btn2 {
        margin: 0 auto;
        float: none;
        width: 282px;
        font-size: 14px;
    }

    .footer_links {
        float: left;
        width: 100%;
        margin: 5px 0px;
    }

    .navbar-expand-lg .navbar-nav {
        align-items: flex-start;
        margin-top: 33px;
    }

    .purple {
        padding: 47px 0px;
        padding-bottom: 106px;
        padding-top: 0;
    }

    .footer_logo {
        float: left;
        width: 100%;
        margin-bottom: 10px;
    }

    .footer_colum_padding {
        padding-left: 0;
    }

    .form {
        height: auto !important;
        margin-top: -80px;
        background: url(../images/home/mobile_formbg.png) no-repeat !important;
        background-size: cover !important;
    }

    .mobile_show {
        display: block;
    }

    .footer_form label {
        position: absolute;
        right: 5px;
        top: 7px;
    }

    .arrowbtn2 {
        width: 18px;
    }

    footer ul a {
        font-size: 15px;
    }

    .para_text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-top: 34px;
        line-height: 26px;
        margin-bottom: 26px;
    }

    .about_supermclub .main_btn {
        display: block;
        float: left;
        width: 100%;
    }

    .value_box img {
        float: none;
        width: 156px;
        margin: 0 auto;
    }

    .value_box_inner {
        float: left;
        width: 100%;
        text-align: center;
    }

    .title {
        font-size: 25px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 44px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 0;
        margin-bottom: 14px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 26px !important;
        letter-spacing: 1px !important;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 31px 0px;
    }

    .footer_logo img {
        width: 160px;
    }

    .para_margin {
        margin: 21px 0px;
        margin-top: 0px;
    }

    .btn3 {
        background: transparent !important;
        margin: 0 auto;
        float: none;
        width: 189px;
        margin-top: 19px;
        color: #fff !important;
        text-align: center;
        font-size: 14px;
    }


    .banner_img img {
        height: 147px;
        object-fit: contain;
    }



}

@media(max-width:400px) {
    .navbar-brand img {
        width: 175px !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .about_top_contain::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "";
        background: #acc789;
        height: 80%;
        float: left;
        width: 100%;
        z-index: 9;
    }

    .navbar-brand img {
        width: 149px !important;
        margin: 0 !important;
        padding: 0 !important;
    }


}

@media(max-width:375px) {
    .container_middle {
        margin: 0 auto;
        max-width: 340px;
    }

    .container_mini {
        margin: 0 auto;
        max-width: 340px;
    }

    .container {
        margin: 0 auto;
        max-width: 340px;
    }


    .about_margin {
        display: block;
        float: left;
        width: 100%;
        margin-top: -8px !important;
    }

    .padding_left {
        padding-left: 0px;
    }

    .main_btn {
        font-size: 15px;
        color: #3b5a12;
        font-weight: 700;
        letter-spacing: 0px;
    }

    .mobile {
        display: none;
    }

    .padding_right {
        padding-right: 0px;
    }

    .banner_caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        float: none;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    form .form_control {
        font-size: 15px;
        padding: 11px 0px;
        margin-bottom: 17px;
    }

    .btn2 {
        background: #62065b;
        color: #fff;
        padding: 10px 25px;
        width: auto;
        display: block;
        float: left;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 500 !important;
    }

    h3 {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        margin-bottom: 17px;
        margin-top: -16px;
    }

    .main_heading {
        font-size: 25px;
        padding: 0 !important;
        width: 100%;
        margin: 0 auto;
    }

    .banner_center {
        width: 100%;
        padding: 0px 75px;
    }

    .main_banner {
        height: 75vh;
    }

    .about_supermclub {
        padding: 59px 0px;
    }



    .banner_caption .para_text {
        margin-top: 12px;
        line-height: 32px;
        font-size: 16px;
    }

    .banner_caption a {
        margin: 0 auto;
        float: none;
        width: 219px;
        margin-top: 20px;
    }

    .about_ul li {
        display: inline-block;
        width: 100%;
        float: left;
        text-align: left;
        font-size: 18px;
        margin: 7px 0px;
        letter-spacing: .8px;
    }

    .title {
        font-size: 38px;
        letter-spacing: 2px;
    }

    header {
        padding: 7px 0px;
    }

    .core_value {
        margin-top: -130px;
    }

    .paragraph {
        font-size: 15px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 0;
    }

    .reverse {
        flex-direction: column-reverse;
    }

    .why_img {
        padding: 0px 70px;
    }


    .why_caption {
        text-align: center;
        margin-top: 34px;
    }

    .why_caption .btn2 {
        margin: 0 auto;
        float: none;
        width: 282px;
        font-size: 14px;
    }

    .footer_links {
        float: left;
        width: 100%;
        margin: 5px 0px;
    }

    .navbar-expand-lg .navbar-nav {
        align-items: flex-start;
        margin-top: 33px;
    }

    .purple {
        padding: 47px 0px;
        padding-bottom: 106px;
        padding-top: 0;
    }

    .footer_logo {
        float: left;
        width: 100%;
        margin-bottom: 10px;
    }

    .footer_colum_padding {
        padding-left: 0;
    }

    .form {
        height: auto !important;
        margin-top: -80px;
        background: url(../images/home/mobile_formbg.png) no-repeat !important;
        background-size: cover !important;
    }

    .mobile_show {
        display: block;
    }

    .footer_form label {
        position: absolute;
        right: 5px;
        top: 7px;
    }

    .arrowbtn2 {
        width: 18px;
    }

    footer ul a {
        font-size: 15px;
    }

    .para_text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .8px;
        margin-top: 34px;
        line-height: 26px;
        margin-bottom: 26px;
    }

    .about_supermclub .main_btn {
        display: block;
        float: left;
        width: 100%;
    }

    .value_box img {
        float: none;
        width: 156px;
        margin: 0 auto;
    }

    .value_box_inner {
        float: left;
        width: 100%;
        text-align: center;
    }

    .title {
        font-size: 25px;
        letter-spacing: 2px;
    }

    .about_ul li img {
        width: 44px;
        margin-right: 14px;
    }

    .about_ul {
        display: inline-block;
        float: left;
        width: 100%;
        padding-left: 0;
        margin-bottom: 14px;
    }

    h5 {
        font-size: 21px;
        letter-spacing: 1.5px;
    }

    .value_box {
        margin-bottom: 23px;
    }

    .arrowbtn {
        width: 25px;
        margin-left: 10px;
    }

    .subtitle {
        /* margin: 16px 0px; */
        font-size: 23px;
        letter-spacing: 1.5px;
    }

    .why_superme {
        float: left;
        width: 100%;
        padding: 31px 0px;
    }

    .footer_logo img {
        width: 160px;
    }

    .para_margin {
        margin: 21px 0px;
        margin-top: 0px;
    }

    .btn3 {
        background: transparent !important;
        margin: 0 auto;
        float: none;
        width: 189px;
        margin-top: 19px;
        color: #fff !important;
        text-align: center;
        font-size: 14px;
    }




}

@media (min-width: 769px) and (max-width: 991px) {
  .container {
    max-width: none;
  }
  .footer_colum_padding {
    padding: 0;
  }
}