@font-face {
    font-family: "montserrat";
    src: url("../fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

/* theme.css */
:root {
    --theme-color: rgb(68, 94, 170);
    --theme-color-bold: rgb(0, 0, 158);
    --theme-color-sec: rgb(225, 236, 254);
    --theme-font-family: 'montserrat'
}

.themeColor {
    color: var(--theme-color);
}

.themeColor_Bold {
    color: var(--theme-color-bold);
}

.themeColorSec_bkg {
    background-color: var(--theme-color-sec);
}

.themeFontFamily {
    font-family: var(--theme-font-family);
}

.unsetAll {
    all: unset !important;
}

.basic_btn {
    font-family: var(--theme-font-family) !important;
    background: var(--theme-color) !important;
    color: #fff !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
    margin: .5em;
    padding: 1em 2em;
    font-size: 1em;
}

.basic_btnInv {
    font-family: var(--theme-font-family) !important;
    background: white !important;
    color: var(--theme-color) !important;
    font-weight: 700 !important;
    border-radius: 10px !important;
    margin: .5em;
    padding: 1em 2em;
    font-size: 1em;
}

.sectionStyle {
    float: left;
    position: relative;
    width: 100%;
    padding: 100px 0px;
    background-position: top center;
    background-size: cover;
    text-align: center;
    /* margin-top: -50px; */
    /* background: #f8f9f6; */
}

.title {
    font-family: var(--theme-font-family);
    font-weight: 900;
    color: var(--theme-color);
    position: relative;
    text-align: center;
}

.title::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid var(--theme-color);
    width: 100%;
    height: 5px;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
    width: 200px;
    left: 0;
    right: 0;
    bottom: -13px;
}

.noAfter::after {
    content: none;
    /* This will remove the ::after styles */
}


/* Nav Bar */
.navbarButton {
    color: var(--theme-color) !important;
    font-family: var(--theme-font-family) !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    padding: 20px !important;
    text-transform: none !important;
    /* all: unset !important; */
}


/* Footer  */

.footer {
    float: left;
    width: 100%;
    background: var(--theme-color);
    padding: 50px 0px;
    padding-bottom: 0 !IMPORTANT;
}

.base_footer {
    float: left;
    width: 100%;
    margin-top: 40px;
    padding: 10px 0px;
    border-top: 2px solid var(--theme-color);
}

.base_footer p {
    color: #fff;
    padding-top: 11px;
}

.base_footer ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.base_footer ul li {
    margin-left: 20px;
}


/* Verify Page */

.verifyPage {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../images/website/banner.png") no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
}

.verifyPageMessage {
    text-align: center;
    align-content: center;
    padding-Block: 15vh;
    height: 100vh;
    width: 35%;
    color: rgb(0, 0, 158);
}

.verifyPageMessage h1,
.verifyPageMessage h2,
.verifyPageMessage p {
    color: rgb(0, 0, 158);
    font-family: "montserrat";
    font-weight: 500;
}

.verifyPageMessage h1 {
    font-weight: bolder;
    font-family: "montserrat";
}

.verifyPageMessage p {
    font-size: large;
}

.verifyPageMessage a {
    color: #fff;
    text-decoration: none;
}

/* Media Querys */

@media(max-width:1000px) {
    .dontShow {
        display: none
    }

    .verifyPageMessage {
        width: 75%;
    }
}