.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  gap: 1.5rem;
  overflow: scroll;
}
.defualtFileUploadIcon {
  display: none;
}

.cloudUploadIcon {
  width: 150px;
  height: auto;
}

.flexContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2rem;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  width: 100%;
}

.centeredDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2rem;
}

.dragZone {
  border: 5px dashed #415aa4;
  padding: 1.25rem 5rem;
}

.dragZone:hover {
  cursor: pointer;
  background-color: hsl(224, 53%, 59%);
}

.uploadedFiles {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 8rem;
  gap: 1rem;

  .fileName {
    margin-right: auto;
  }

  .progress {
    color: #415aa4;
  }

  .deleteFile {
    color: #415aa4;
  }
}

.backBtnContainer {
  display: flex;
  height: 100%;
  align-items: flex-end;
}
