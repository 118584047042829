/* Styles for CustomizedMenus component */
.btn_container {
  position: relative;
}
.dropdown_button {
  color: white;
  background-color: #445eaa;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown_button:hover {
  background-color: #344a7c;
}

.arrow_down {
  margin-left: 10px;
}

.dropdown_menu {
  position: absolute;
  z-index: 100;
  background-color: #445eaa;
  border-radius: 6px;
  margin-top: 10px;
  min-width: 180px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  list-style: none;
  padding: 0;
}

.dropdown_menu_item:not(:last-child) {
  border-bottom: 1px solid white;
}

.menu_item_button {
  position: relative;
  z-index: 100;
  color: white;
  background-color: #445eaa;
  border: none;
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.menu_item_button:hover {
  background-color: #344a7c;
}

@media (max-width: 950px) {
  .dropdown_button, .menu_item_button{
    font-size: 14px;
    padding: 4px 10px;
  }
}
