@import '../../../responsive';

.footer {
    float: left;
    width: 100%;
    background: var(--theme-color);
    padding: 50px 0px;
    padding-bottom: 0 !IMPORTANT;
}

.footer_head {
    @media (max-width: 1000px) {}
}

@media (min-width: 769px) and (max-width: 991px) {
  .footerLinkPadding{
    padding-left: 3rem;
    padding-top: 1rem;
  }
}