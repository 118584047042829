@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mobile_and_tablet {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}