.desktop_nav{
  display: flex;
  align-items: center;
  gap: 2vw;
}

.campaign_icon_container{
  position: absolute;
  top: -0.25vw;
  left: -8px;
}

.achievements_icon_container{
  position: absolute;
  top: 0;
  left: -0.75vw;
}

.learn_icon_container{
  position: absolute;
  top: -0.5vw;
  left: -0.5vw;
}

.missions_icon_container{
  position: absolute;
  top: -.25vw;
  left: -1.5vw;
}

.navbtn_icons{
  position: absolute;
  left: -10px;
}

.campaign_icon_img{
  width: clamp(28px, 2vw, 40px);
}

.achievements_icon_img{
  width: clamp(35px, 2.5vw, 50px);
}

.learn_icon_img{
  width: clamp(35px, 2.5vw, 50px);
}

.missions_icon_img{
  width: clamp(40px, 3.5vw, 70px);
}

.navbtn_bg{
  background-color: #445eaa;
  border-radius: 12px;
  width: clamp(145px, 10vw, 200px);
  height: clamp(30px, 2.5vw, 45px);
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  padding-right: 0;
}

.navbtn_mtext{
  font-family: "Montserrat";
  font-size: clamp(16px, 1vw, 24px );
  color: #fdeaff;
}

.profile_icon_container{
  font-family: "Montserrat";
}

.profile_icon{
  border: 2px solid #445eaa;
  border-radius: 50%;
  width: clamp(40px, 2.5vw, 50px);
  height: clamp(40px, 2.5vw, 50px);
  background-color: #445eaa;
  font-size: 28px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}


@media (max-width: 1200px) {
  .desktop_nav{
    gap: 1vw;
  }

  .userNavbarLogo{
    width: clamp(230px, 45vw, 260px) !important;
  }
  

  .navbtn_bg{
    width: clamp(130px, 11vw, 200px);
    height: clamp(30px, 2.25vw, 45px);
  }
  
  .navbtn_mtext{
    font-size: clamp(12px, 0.5vw, 18px );
  }
}