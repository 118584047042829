.adminFooter {
  background-color: #dce7f9; //hsl(var(--brand-200));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  gap: 1rem;

  h1 {
    color: hsl(var(--brand-600));
    font-size: 1.5rem;
    font-weight: bold;
  }

  p {
    color: hsl(var(--brand-400));
    font-size: 1rem;
  }

  img {
    margin-left: 10px;
    margin-right: 10px;
    height: 5vh;
    width: auto;
  }
}

.stickyFooter {
  position: absolute;
  bottom: 0;
}

.stickyFooter,
.scrollableFooter {
  // position: absolute;
  // bottom: 0;
  background-color: #CADCFF; //hsl(var(--brand-200));
  width: 100%;

  footer {
    padding: 0.5rem;
  }

  h1 {
    font-size: 1rem;
  }

  img {
    height: 3vh;
    padding: 0 5px;
  }

  p {
    padding: 0 5px;
  }

  @media (orientation: portrait) and (max-width: 575px) {

    footer {
      padding: 0.25rem;
      gap: 0.5rem;
    }

    h1 {
      font-size: 0.75rem;
    }

    img {
      height: 2.25vh;
    }

    p {
      display: none !important;
    }

    .and {
      display: block !important;
      padding: 0 5px;
    }
  }

  @media (orientation: landscape) and (max-height: 575px) {
    footer {
      padding: 0.20rem;
      gap: 0.20rem;
    }

    h1 {
      display: none;
    }

    img {
      height: 2.25vw;
    }

    p {
      font-size: 0.75rem;
      padding: 0 5px;
    }
  }

}