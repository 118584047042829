.carouselBlock {
  width: 30%;
  height: 96%;
  min-height: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scale: 1;
  overflow: hidden;
}

.ach_carasolItem {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ach_carasolItem_bkg {
  background-color: rgba(70, 93, 186, 0.562);
  width: 100%;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
}

.carouselInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.carouselInner::-webkit-scrollbar {
  display: none;
}

.carouselInner {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.box_text {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  height: 100%;
}

.title {
  color: white;
  font-size: 3vh !important;
  font-weight: 900;
  font-family: "Montserrat";
  margin: 2.5vw 0 1vw 0;
}

.text {
  color: white;
  font-size: clamp(12px, 0.75vw, 30px);
  font-weight: 900;
  font-family: "Montserrat";
  padding: 0 2%;
}

.leaderBoard {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  margin-top: 1%;
  overflow-y: scroll;
}

.ranking {
  display: flex;
  align-items: center;
  gap: 1vw;
  height: 100%;
}

.trophy {
  width: 2vw;
  overflow: hidden;
  margin-left: 20%;
}

.indexNum {
  font-size: clamp(12px, 1.75vw, 30px);
  font-family: "Montserrat";
  color: white;
  background-color: rgb(70, 93, 186);
  border-radius: 50%;
  margin-left: 3.5%;
  margin-right: 3.5%;
  padding-left: 3.5%;
  padding-right: 3.5%;
}

.rankerName {
  width: 100%;
  justify-content: center;
  overflow-x: hidden;
}

@media (orientation: portrait) {
  .carouselBlock {
    display: flex;
    width: 100%;
    height: 75%;
  }

  .ach_carasolItem {
    height: 97%;
  }

  .ach_carasolItem_bkg {
    padding: 0 2%;
  }

  .carouselInner {
    width: 100%;
    height: 99%;
    overflow-y: scroll;
  }

  .leaderBoard {
    margin-top: 5vw;
    gap: 5vw;
  }

  .trophy {
    width: 9vw;
  }

  .rankImgContainer {
    padding: 5%;
  }

  .box_text {
    padding-top: 15%;
    padding-right: 8%;
    width: 100%;
    word-wrap: break-word;
  }

  .title {
    margin: 0;
  }

  .text {
    font-size: clamp(16px, 2vh, 30px) !important;
  }

  .indexNum {
    font-size: clamp(18px, 6vw, 30px);
    font-family: "Montserrat";
    padding-left: 7%;
    padding-right: 7%;
  }
}

@media (orientation: portrait) and (min-width: 550px) {
  .carouselBlock {
    margin-top: 20%;
  }

  .ach_carasolItem {
    height: 80%;
  }

  .carouselInner {
    max-height: 98%;
  }

  .box_text {
    padding-right: 0;
    padding-left: 3%;
    margin-bottom: 0 !important;
    padding-top: 0;
  }

  .title {
    margin: 5vw 0 2vw 0;
    font-size: clamp(25px, 4.5vw, 50px) !important;
  }

  .trophy {
    width: 8vw;
  }

  .text {
    font-size: clamp(16px, 2vh, 50px) !important;
  }
}

@media (orientation: landscape) and (max-width: 940px) and (max-height: 600px) {
  .carouselBlock {
    height: 80%;
    width: 30%;
  }

  .carouselInner {
    overflow-x: hidden;
  }

  .trophy {
    width: 6vh;
  }

  .title {
    margin: 5px 0 0 0;
  }

  .text {
    font-size: clamp(10px, 1vh, 20px) !important;
  }

  .rankerName {
    font-size: clamp(8px, 1vh, 20px) !important;
  }

  .indexNum {
    font-size: clamp(16px, 2vw, 25px);
  }
}
