@tailwind base;
@tailwind components;
@tailwind utilities;

$S3-PUBLIC-IMAGES: "https://thesupermeclub-client-assets.s3.us-east-1.amazonaws.com/public/images";

:root {
  --brand-50: 221, 100%, 96%;
  --brand-100: 224, 54%, 85%;
  --brand-200: 224, 46%, 75%;
  --brand-300: 225, 44%, 65%;
  --brand-400: 224, 43%, 55%;
  --brand-500: 225, 43%, 45%;
  --brand-600: 224, 44%, 35%;
  --brand-700: 223, 45%, 25%;
  --brand-800: 224, 51%, 15%;
  --brand-900: 222, 79%, 6%;
  --brand: 225, 43%, 45%;

  body,
  html {
    margin: 0;
    padding: 0;
    font-family: 'montserrat';
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: hsl(var(--brand));
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: hsl(var(--brand-400));
  }
}
