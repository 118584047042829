html,
body {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* background: url(../assets/images/kotel_bg.jpg) no-repeat; */
  background-position: center;
  background-size: 100% 100%, cover;
}

.navbar {
  /* border: 2px solid red; */
  padding: 20px;
  min-height: 10%;
  text-align: center;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  /* height: 100%; */
  /* padding: 20px; */
  /* border: 2px solid rgb(0, 255, 51); */
  overflow: hidden;
}

.defaultMainDiv {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 90%;

  .charImg {
    height: auto;
    width: 75%;
  }

  .centerMainDiv {
    height: 100%;
    width: auto;
    aspect-ratio: 2364/1841;
  }

  @media (orientation: portrait) {
    .content {
      height: 0%;
    }

    .centerMainDiv {
      aspect-ratio: 1518/2383;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

.learnPageMainDiv {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 90%;

  .charImg {
    height: auto;
    width: 70%;
  }

  .centerMainDiv {
    height: 100%;
    width: auto;
    aspect-ratio: 2363/1518;
  }

  /* @media (max-aspect-ratio: 1.64) {
    .mainDiv{

    }
  } */

  @media (max-aspect-ratio: 1.85) {
    .charImg {
      width: 85%;
    }
  }

  @media (orientation: portrait) {
    .centerMainDiv {
      aspect-ratio: 1518/2383;
      width: 90%;
    }

    @media (min-width: 500px) {
      .centerMainDiv {
        width: auto;
      }
    }
  }

  @media (orientation: landscape) {
    @media (max-height: 500px) {
      .centerMainDiv {
        height: 90%;
        width: 80vw;
        aspect-ratio: unset;
      }
    }
  }

}

.centerWrapper {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
}

.footer {
  background-color: transparent;
  /* border: 2px solid rgb(105, 12, 218); */
  /* padding: 20px; */
  height: fit-content;
  text-align: center;
}


/* DEFAULT MAIN DIV ASPECT RATIO MEDIA QUERY */
@media (orientation: landscape) {
  @media (max-aspect-ratio: 1.42) {
    .defaultMainDiv {
      height: 75%;
    }
  }
}



/* LEARN PAGE ASPECT RATIO MEDIA QUERY */
@media (orientation: landscape) {
  @media (max-aspect-ratio: 1.53) {
    .learnPageMainDiv {
      height: 80%;
    }
  }

  @media (max-aspect-ratio: 1.45) {
    .learnPageMainDiv {
      height: 70%;
    }
  }

  @media (max-aspect-ratio: 1.3) {
    .defaultMainDiv {
      height: 70%;
    }
  }

  @media (max-aspect-ratio: 1.25) {
    .learnPageMainDiv {
      height: 60%;
    }
  }
}


/* PORTRAIT MEDIA QUERY */

@media (orientation: portrait) {

  .centerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 550px) {}

}