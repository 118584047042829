.missionsTable {
  background: white;
  border: 2px solid hsl(var(--brand-100));
  border-radius: 1rem;

  .headerContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }

  h2 {
    font-weight: bold;
    font-size: 1.5rem;
    color: hsl(var(--brand));
    padding: 1.5rem 1.5rem .75rem 1.5rem;
  }

  h5{
    font-weight: 500;
    font-size: 1.15rem;
    color: hsl(var(--brand));
    padding: 1.5rem 1.5rem 1.18rem 1.5rem;
  }

  .tooltip{
    padding: 1rem 1rem 0 1rem;
  }
}

.missionsTable tbody tr:hover {
  cursor: pointer;
  background-color: #dce9f5; /* Light blue color */
  transition: background-color 0.3s ease;
}

.tableWrapper {
  height: 24rem;
  overflow: auto;
  position: relative;
  margin: 1rem 1rem;

  table {
    width: 100%;

    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
      border-bottom: 0.5rem solid hsl(var(--brand));

      th {
        color: hsl(var(--brand-200));
        text-align: left;
      }      
    }

    tbody {
      td {
        border-bottom: 1px solid hsl(var(--brand));
        padding: 1rem 0.5rem;
      }

      .verifyButton {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }
  }
}

.editableRow{
  position: relative;
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f8d7da;
  color: #721c24;
  padding: 5px;
  border: 1px solid #f5c6cb;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 10;
}

.checkbox_input{
  padding-left: .5rem;
}

.selected{
  background-color: #dce9f5;
}

.blue_border{
  border: 2px solid #415aa4;
  outline: #415aa4;
  border-radius: 5px;
}

.blue_border:focus{
  outline: #415aa4;
}

.btn {
  color: white;
  background-color: #415aa4;
  padding: 5px 25px;
  font-size: 14px;
  border-radius: 5px;
}

.iconContainer{
  border: 2px solid #445eaa;
  border-radius: 50%; 
}

.addIcon{
  color: #445eaa;
}

@media (max-width: 950px) {
  .missionsTable {
    h2 {
      font-size: 1.25rem;
    }
  }

  th{
    font-size: 0.85rem;
    padding: 0 0.5rem;
  }
  tbody {
    td {
      font-size: 0.85rem;
    }
  }

  .btn{
    padding: 2.5px 12.5px;
  }
}
