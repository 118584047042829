html,
body {
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", sans-serif;
}

@font-face {
  font-family: "tintco_demoregular";
  src: url("../fonts/tintco_demo-webfont.woff2") format("woff2"),
    url("../fonts/tintco_demo-webfont.woff") format("woff"),
    url("../fonts/tintco_demo-webfont.ttf") format("truetype"),
    url("../fonts/tintco_demo-webfont.svg#tintco_demoregular") format("svg");
}

@font-face {
  font-family: "montserrat";
  src: url("../fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

/* theme.css */
:root {
  --theme-color: rgb(68, 94, 170);
  --theme-color-bold: rgb(0, 0, 158);
  --theme-color-sec: rgb(225, 236, 254);
  --theme-font-family: 'montserrat'
}

@media screen and (max-width: 1920px) and (min-width:767px) {
  .center_box {
    transform: scale(0.9);
  }

}


.survey_btn2 img {
  width: 60% !important;
  position: relative;
  transition: .5s ease;
}

.survey_btn2 {
  bottom: -160px !important;
  transition: .5s ease;
}

.survey_btn {
  transition: .5s ease;
}





.survey_btn2 .btn_overlay {
  position: absolute;
  top: 16px;
  font-weight: 700;
  color: #fff;
  text-shadow: 2px 2px #29542a;
  font-style: italic;
  text-align: center;
  left: 0;
  right: 0;
}

.survey_btn:hover {
  transform: translatey(-10px) !important;
}





.foam_inner_box7 {
  margin-top: 70px;
}

.input_section_level {
  float: left;
  width: 200px;
  margin-right: 10px;
}

.section_level_right {
  float: left;
}

.section_level_right ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.section_level_right ul li {
  display: inline-block;
}

.section_level_right ul li a {
  display: block;
  width: 100%;
  padding: 16px 12px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #600454;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 12px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  letter-spacing: 0.5px;
}

.total_amount {
  float: left;
  text-align: left;
  margin-top: -9px;
}

.white_section_box_mission2 {
  float: left;
  width: 100%;
  height: auto !important;
}

.total_amount h2 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #8a4923;
}

.tag_p {
  font-size: 12px;
  margin-top: 4px;
  text-align: center;
  color: #8d4e23;
  font-weight: 500;
}


.margin_top_raffler {
  margin-top: -21px;
}

.section_level_right ul li a:hover {
  background-color: #e3d2a7;
  transition: 0.5s ease;
}

.monile_header {
  display: none;
}

.login_btn {
  margin-top: 36px !important;
  float: left;
  width: 100%;
}


.line_logo {
  width: 430px !important;
  margin-top: 20px;
}

a:hover {
  transform: translatey(-10px) !important;
  transition: .5s ease;
}


.survey_btn a:hover {
  transform: translatey(0px) !important;
  transition: .5s ease;
}

.gray {
  filter: grayscale(1);
}




.btn_right_section:hover {
  transform: translatey(-10px) !important;
  transition: .5s ease;
}

.text-section_btn a :hover {
  transform: translatey(10px) !important;
  transition: .5s ease;
}

@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px)
  }

  60% {
    -webkit-transform: translateY(-20px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-20px);
  }
}


.bounce {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: bounce;
  animation-name: bounce;
}



.dashboard_survey .survey_btn {
  bottom: -40px;
}


.one_third_box_scroll {
  overflow: scroll;
  overflow-x: hidden;
}

.one_third_box_scroll::-webkit-scrollbar {
  width: 10px;
  margin-left: 5px !important;
}

/* Track */
.one_third_box_scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #A86427;
  border-radius: 10px;
}

/* Handle */
.one_third_box_scroll::-webkit-scrollbar-thumb {
  background: #A86427;
  border-radius: 10px;
}

/* Handle on hover */
.one_third_box_scroll::-webkit-scrollbar-thumb:hover {
  background: #A86427;
}


.coin::-webkit-scrollbar {
  width: 10px;
  margin-left: 5px !important;
}

/* Track */
.coin::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #A86427;
  border-radius: 10px;
}

/* Handle */
.coin::-webkit-scrollbar-thumb {
  background: #A86427;
  border-radius: 10px;
}

/* Handle on hover */
.coin::-webkit-scrollbar-thumb:hover {
  background: #A86427;
}







.left_girl_section2 {
  display: none;
}

.modal_task_completed h2 {
  font-weight: 700;
  color: #602904 !important;
  margin-top: 0px !important;
}

.ticket_box {
  float: left;
  width: 100%;
  margin-top: 15px;
  position: relative;
}

.ticket_box .owl-nav {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -9;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  height: 100%;
  z-index: 999999;
}

.ticket_box .owl-prev {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a86427 !IMPORTANT;
  border-radius: 35px;
  font-size: 35px !important;
  color: #FFF !important;
  text-align: center;
}

.ticket_box .owl-next {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a86427 !IMPORTANT;
  border-radius: 35px;
  font-size: 35px !important;
  color: #FFF !important;
  text-align: center;
}

.coin {
  float: none;
  width: 89%;
  padding: 3px;
  height: 494px;
  margin-top: -88px;
  overflow: initial;
  overflow-x: hidden;
}

.coin ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.white {
  background: #fff;
}

.red {
  background: #e42c1a;
}

.orange {
  background: #e46523;
}

.yellow {
  background: #ffea3d;
}

.green {
  background: #45b248;
}

.blue {
  background: #00b8f1;
}

.purpule {
  background: #43359b;
}

.brown {
  background: #7e4f25;
}

.coin .red ul li span {
  border-color: #f4a08b;
  color: #e42c1a;
}

.coin .orange ul li span {
  border-color: #f7ad5e;
  color: #e46523;
}

.coin .yellow ul li span {
  border-color: #fff6a2;
  color: #ffea3d;
}

.coin .green ul li span {
  border-color: #97c877;
  color: #45b248;
}

.coin .blue ul li span {
  border-color: #8ec9ef;
  color: #00b8f1;
}

.coin .purpule ul li span {
  border-color: #978bc1;
  color: #43359b;
}

.coin .brown ul li span {
  border-color: #c99e67;
  color: #e42c1a;
}



.coin_strip {
  float: left;
  width: 100%;
  padding: 13px 0px;
}

.coin ul li:first-child {
  padding: 0;
  border: none;
  border-radius: 0;
  background: transparent;
}

.coin ul li {
  font-weight: 700;
  color: #fff;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px dashed #878786;
  background: #fff;
  padding: 8px;
  margin: 0px 15px;
}

.coin ul li span {
  width: 100%;
  border-radius: 50%;
  border: 8px solid #ccc;
  text-shadow: 4px 4px #000;
  height: 100%;
  font-size: 57px;
  line-height: 70px;
  text-align: center;
}







.ticket_box .owl-nav .owl-prev span {
  position: relative !important;
  top: -3px !important;
  left: -2px !important;
}

.ticket_box .owl-nav .owl-next span {
  position: relative !important;
  top: -3px !important;
  right: -2px !important;
}

.ticket_box_inner {
  float: left;
  width: 100%;
  margin-top: 15px;
  position: relative;
}

.ticket_box_inner img {
  width: 40% !important;
  height: 200px;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #602904;
  margin-bottom: 10px;
}

.ticket_box_inner h4 {
  font-size: 18px;
  font-family: "tintco_demoregular";
  color: #602904;
  margin-bottom: 10px;
}

.ticket_thired {
  margin-top: 70px;
}


.ticket_box_inner p {
  font-size: 14px;
}

.survey_btn {
  width: 80%;
  position: absolute;
  bottom: -95px;
  text-align: center;
  left: 0;
  right: 0 !important;
  float: none;
  margin: 0 auto;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center_div_contain {
  z-index: 99998;
}

header {
  z-index: 10999999 !important;
}




.left_girl_section2:hover {
  transform: scale(1.1) !important;
  margin-top: -20px !important;
  cursor: pointer;
  transition: .5s ease;
}

.left_girl_section:hover {
  transform: scale(1.1) !important;
  margin-top: -20px !important;
  cursor: pointer;
  transition: .5s ease;
}

.right_boy_section:hover {
  transform: scale(1.1) !important;
  margin-top: -20px !important;
  cursor: pointer;
  transition: .5s ease;
}

.right_boy_section2:hover {
  transform: scale(1.1) !important;
  margin-top: -20px !important;
  cursor: pointer;
  transition: .5s ease;
}


#hey {
  width: auto;
  height: auto;
  cursor: pointer;
}

.mobile_flex {
  float: left;
  width: 100%;
  height: 129px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.brown_box2 {
  background-color: #d1b169;
  float: left;
  width: 100%;
  border-radius: 12px;
  height: 100%;
}

#hey:hover {
  transform: scale(1.1) !important;
  margin-top: -20px !important;
  cursor: pointer;
  transition: .5s ease;
}


@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0) scale(1.1);
    ;
  }

  40% {
    -webkit-transform: translateY(-30px) scale(1.1);
  }

  60% {
    -webkit-transform: translateY(-20px) scale(1.1);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0) scale(1.1);
  }

  40% {
    transform: translateY(-30px) scale(1.1);
  }

  60% {
    transform: translateY(-20px) scale(1.1);
  }
}


.bounce {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: bounce;
  animation-name: bounce;
}










#section1 {
  background: url(../images/website-background.png) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden;
  background-size: 100% 100%, cover;
}

header {
  float: left;
  width: 100%;
  background-color: rgba(197, 221, 239, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

.right_div {
  float: left;
  margin-top: -9px;
  margin-left: 15px;
  width: 198px;
}

.heading_foam span {
  color: #602904;
}



.right_div .custom-select-trigger {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0px 0 0px;
  font-size: 16px;
  font-weight: 400;
  color: #d1b169;
  line-height: 28px;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: left;
}

.right_div .custom-option {
  position: relative;
  display: block;
  padding: 0 22px;
  border-bottom: 1px solid #dbdbdb;
  font-size: 13px;
  font-weight: 400;

  line-height: 38px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  text-align: left;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.right_div .form-control {
  display: block;
  width: 100%;
  padding: 9px 18px !important;
}

.text_box_modal .box_white_inner {
  float: left;
  margin-bottom: 23px;
}

.mission2_modal {
  float: left;
  width: 100%;
}

.box_white_inner .form-group2 {
  width: 101px;
  margin-bottom: 0px !important;
  text-align: center;
  float: right;
}

.box_white_inner h3 .form-control {
  display: block;
  width: 100%;
  padding: 0px 18px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 2px solid var(--theme-color-bold) !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  letter-spacing: 0.5px;
  border: 0px;
}

.text-left {
  float: left;
  width: 88px;
}

.modal_image {
  float: left;
  width: 100%;
  position: relative;
}

.close_modal {
  position: absolute;
  right: -5px;
  top: 40px;
  cursor: pointer;
}

.tab_pan_section .tab-content {
  background: transparent;
  line-height: 25px;
  border: 0px solid #ddd;
  border-top: 0px solid #e74c3c;
  border-bottom: 0px solid #e74c3c;
  /* padding: 30px 0px; */
}

.tab_pan_section .nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  font-weight: 700;
  color: #602904 !important;
  padding: 0;
  border: 2px solid #602904;
  padding: 9px 20px;
  border-radius: 10px;
  background-color: #ffff;
}

.right_div .form-control {
  display: block;
  width: 100%;
  padding: 16px 18px;
  font-size: 14px !important;
}

.tab_pan_section .nav-tabs .active {
  background-color: #e3cd98;
}

.tab_pan_section .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

.box_white_inner h3 span {
  font-weight: 900;
  color: #a86427;
}

.box_section_price {
  float: left;
  width: 100%;
}

.priceheading1 {
  float: left;
  width: 100%;
  text-align: left;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  margin: 0 auto;
}

.text_box_modal h4 {
  font-size: 11px;
  font-weight: 700;
  color: #602904;
}

.bottom_section_popup p {
  font-size: 10px;
  color: #602904;
}

.bottom_section_popup p {
  font-size: 10px;
  color: #602904;
  border-top: 1px solid #d7b35a;
  margin-top: 7px;
  padding-top: 25px;
}

.modal2_div {
  top: 73px !important;
}

.close_modal2 {
  position: absolute;
  right: -7px;
  top: 34px;
  cursor: pointer;
}

.logout {
  display: flex;
}


.bottom_btn {
  position: absolute;
  z-index: 99999;
  bottom: 24px;
  /* top: 0; */
  width: 100%;
  float: left;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.modal-dialog {
  max-width: 451px;
  margin: 1.75rem auto;
}

.white_section_box_mission {
  float: left;
  width: 100%;
  height: 438px;

  overflow-y: scroll important;




}

.white_section_box_mission::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.white_section_box_mission::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.white_section_box_mission::-webkit-scrollbar-thumb {
  background: #602904;
  border-radius: 80px;
}

/* Handle on hover */
.white_section_box_mission::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.white_image {
  float: left;
  width: 68px;
  height: 68px;
  border-radius: 12px;
  border: 2px solid #602904;
  overflow: hidden;
  margin-right: 16px;
}

.text_box_modal .box_section_price {
  float: left;
  width: 100%;
  margin-bottom: 25px;
}

.text_box_modal {
  position: absolute;
  top: 126px;
  left: 55px;
  width: 75%;
}

.search_input .form-control {
  display: block;
  width: 100%;
  padding: 9px 45px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: #212529;
  background-color: #f5e6c1;
  background-clip: padding-box;
  border: 2px solid var(--theme-color-bold);
}

.search_input .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #602904 !important;
  opacity: 1;
  /* Firefox */
}

.search_input .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #602904 !important;
}

.search_input .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #602904 !important;
}

.box_white_inner2 {
  padding-top: 10px !important;
}

.border_top {
  border-top: 2px solid #d1b169;
}

.box_whte_inner {
  float: left;
  width: 100%;
  padding: 2px 0px;
}

.btn_right_section {
  float: right;
  padding: 9px 0px;
}

.white_section_box {
  background-color: #fff;
  border-radius: 15px;
  border: 2px solid #602904;
  float: left;
  width: 100%;
  padding: 4px 16px;
}

.box_white_inner h3 {
  font-size: 12px;
  margin-bottom: 0 !important;
}

.btn_mission {
  top: 7px !important;
}

.box_white_inner h3 {
  font-size: 13px;
  font-weight: 700;
  color: #602904;
  margin-bottom: 4px;
  float: left;
}

.box_white_inner h6 {
  font-size: 11px;
  color: #ed1c24;
  text-align: left;
  margin-bottom: 0;
}

.margin_misson {
  margin-top: 62px !important;
}

.white_section_box h4 {
  font-size: 18px;
  font-family: "tintco_demoregular";
  color: #602904;
  margin-bottom: 0;
}

.box_white_inner {
  float: left;
}

.pinkstrip2 {
  top: -50px !important;
}

.coin_modal .big_title {
  font-size: 30px;
}

.text_box_modal .box_section_price {
  float: left;
  width: 340px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn_section_top {
  float: left;
  width: 100%;
  position: relative;
}

.btn_icon_section {
  float: left;
  position: absolute;
  left: -27px;
  top: 3px;
}

.brown_box {
  background-color: #d1b169;
  border-radius: 12px;
  float: left;
  width: 100%;
  padding: 0px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
  position: relative;
}





.top_imge_div {
  float: left;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 13px;
}

.inner_box_achivment {
  float: left;
  width: 100%;
  border: 2px dashed #602904;
  border-radius: 15px;
  padding: 5px;
}

.inner_box_achivment {
  height: 320px;
}

.brown_box {
  height: 335px;
}



.margin_achivment {
  top: 13px !important;
}

.one_third_box {
  float: left;
  width: 100%;
}

.one_third_box2 {
  height: 512px;
}

.box_text {
  float: left;
  width: 100%;
  text-align: center;
}

.mobile_flex .achievement_heading {
  margin: 5px !important;
}

.achivement_top_div {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
  top: 24px;
}

.box_text h3 {
  font-size: 36px;
  font-weight: 900;
  margin: 0px;
  color: #602904;
}

.profile_bottom_section p {
  font-size: 18px;
  color: #602904;
  margin-left: 18px;
}

.foam_inner_box {
  width: 720px;
  margin: 0 auto;
}

.width_reduse {
  width: 78%;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.profile_bottom_section {
  float: left;
}

.profile_div_bottom {
  float: left;
  width: 100%;
  margin-top: 9px;
  margin-bottom: 7px;
}


.left_side {
  float: left !important;
}

.margin_top_extra {
  margin-top: 5px;
}

.top_other {
  top: -12px !important;
}

.profile_image_btn {
  width: 60px;
  height: 60px;
  border: 2px solid #602904;
  border-radius: 50px;
  overflow: hidden;
}

.top_btn_section ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 99999;
  padding: 23px 0px;
  position: relative;
  top: 3px;
}









.top_btn_section ul li {
  display: inline-block;
  margin-left: 36px;
}

.text-section_btn {
  background-color: #f7da92;
  border: 2px solid #602904;
  border-radius: 12px;
  width: 200px;
  height: 55px;
  line-height: 55px;
  padding-left: 35px;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: flex-start;
  /* justify-content: center; */
  padding: 2px 37px;
  padding-right: 0;
}

.text-section_btn h5 {
  margin-bottom: 3px;
  font-size: 13px;
  font-weight: 700;
  color: #602904;
}

.top_other2 {
  top: -3px !important;
}

.text-section_btn h5 span {
  font-size: 20px;
  font-weight: 700;
}

.text-section_btn h6 {
  font-size: 12px;
  font-weight: 600;
  color: #602904;
  margin-bottom: 0px;
}

.logo_section {
  float: left;
}

.top_btn_section {
  float: right;
}

.close {
  position: absolute;
  top: -16px;
  right: 72px;
}

.top_sucess {
  top: 57px !important;
}

.padding {
  padding: 17px 0px;
  margin-bottom: 0;
}

.margin_change {
  margin-top: 31px !important;
}

.search_img {
  position: absolute;
  z-index: 999;
  left: 14px;
  top: 11px;
}

.search_input .form-control {
  position: relative;
}

.search_input .form-group {
  position: relative;
  margin-bottom: 6px;
  margin-top: 12px;
  float: left;
  width: 100%;
}

.round_image_profile {
  width: 125px;
  height: 125px;
  border-radius: 80px;
  overflow: hidden;
  border: 1px solid #602904;
}

.top_extra_incres {
  top: -125px !important;
}

.container-xxl {
  max-width: 1820px;
  margin: 0 auto;
}

.center_box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
}

.pink_strip_top {
  /* background: url(../images/pink_strip.png) no-repeat;*/
  background-size: contain;
  position: absolute;
  z-index: 999;
  height: 143px;
  width: 577px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -48px;
  display: flex;
  align-items: center;
  /* justify-content: center;*/
  vertical-align: middle;
  flex-direction: column;
}

.sign_strip_top {
  /*    background: url("../images/sign_strip.png") no-repeat;*/
  background-size: contain;
  position: absolute;
  z-index: 999;
  height: 143px;
  width: 577px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -48px;
  display: flex;
  align-items: center;
  /* justify-content: center;*/
  vertical-align: middle;
  flex-direction: column;
}

.sign_upheight {
  height: 725px;
}

.center_div_main_relation {
  /*    background: url(../images/center_box.png) no-repeat;*/
  float: left;
  width: 100%;
  text-align: center;
  background-size: auto, contain;
  height: 100vh;
  background-position: center;
  position: relative;
  background-position: 50% 52%;
  width: 841px;
  height: 544px;
  margin-top: 12px;
}

.heading {
  font-size: 49px;
  font-family: "tintco_demoregular";
  color: #ffe473;
  letter-spacing: 4px;
  font-weight: 900;
  margin: 0px;
  text-stroke: 2px #5b0039;
  /* -webkit-text-stroke: 0.2px #5b0039; */
  position: relative;
  text-shadow: 1px 5px 0 #602904, 4px 2px 0 #602904, 4px 5px 0 #602904,
    1px 1px 0 #602904;
  position: absolute;
  top: 16px;
  bottom: 0;
}

.center_div_contain {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 100%;
  flex-direction: column;
  margin-top: 20px;
  position: absolute;
  top: 0;
}

.small_title {
  font-size: 29px;
  line-height: 34px;
  color: #602904;
  font-weight: 700;
}

.big_title {
  font-size: 40px;
  line-height: 34px;
  color: #602904;
  font-weight: 900;
}

.midium_title {
  font-size: 40px;
  line-height: 34px;
  color: #602904;
  font-weight: 700;
}

.btn_section {
  float: left;
  width: 100%;
  margin-top: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 20px;
}

.orange_btn {
  background-size: cover;
  width: 260px;
  height: 81px;
}

.green_btn {
  background-size: cover;
  width: 190px;
  height: 81px;
}

.buttons {
  margin: 0px 23px;
  font-size: 38px;
  font-weight: 700;
  font-style: italic;
  color: #fff;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.btn_section a .orange_btn {
  text-shadow: 1px 5px 0 #b94c02, 4px 2px 0 #b94c02, 4px 5px 0 #b94c02,
    1px 1px 0 #b94c02;
}

.btn_section a .green_btn {
  text-shadow: 1px 5px 0 #195e00, 4px 2px 0 #195e00, 4px 5px 0 #195e00,
    1px 1px 0 #195e00;
}

.left_girl_section {
  /* float: left; */
  /* position: absolute; */
  /* left: 5%; */
  /* top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    transition: .5s ease;
    z-index: 999; */
}

.left_girl_section img:hover {
  -webkit-filter: drop-shadow(2px 2px 0 #fff) drop-shadow(-2px -2px 0 #fff);
}

.right_boy_section img:hover {
  -webkit-filter: drop-shadow(0px 3px 0 #fff) drop-shadow(-3px -3px 0 #fff);
}


.left_girl_section img {
  width: 280px;
}

.left_girl_section2 {
  float: left;
  position: absolute;
  left: 0;
  top: 15%;
  display: flex;
  align-items: center;
  transition: .5s ease;
}

.left_girl_section2 img {
  width: 380px;
}

.border1 {
  border-top: 1px solid var(--theme-color-bold);
}

.right_boy_section {
  /* float: right;
    position: absolute;
    right: 2%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    transition: .5s ease; */
}

.left_girl_section2 img {
  width: 379px;
}

.right_boy_section img {
  width: 360px;
  right: 0;
}

.right_boy_section2 {
  position: absolute;
  top: 0%;
  right: 4%;
  transition: .5s ease;
}

.right_boy_section2 img {
  width: 400px;
}


.text_div {
  float: left;
}

.foam_section {
  width: 592px;
  margin: 0 auto;
  padding: 45px;
  padding-top: 0;
  padding-bottom: 0;
}

.back_btn {
  color: #9b5624;
  font-weight: 600;
  /* font-size: 14px; */
  /* margin-top: -6px;
    position: relative;
    top: -26px; */
}

.btn_div_upload {
  float: left;
}

.foam_inner_div {
  float: left;
  width: 100%;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.uploade_btn {
  float: left;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 1px;
}

.text_div .heading_foam {
  padding-right: 35px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.top_extra {
  top: 34px;
}

.btn_div_upload .btn {
  border: 2px solid #602904;
  color: #602904;
  background-color: #d1b169;
  padding: 15px 30px;
  border-radius: 12px;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.info {
  text-align: left;
  float: left;
  margin-top: 8px;
  color: #602904;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0 !IMPORTANT;
}

.info img {
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.margin_top_reduse {
  margin-top: 0;
  margin-bottom: 22px;
}

.heading_foam {
  font-size: 19px;
  font-weight: 700;
  color: #602904;
  text-align: left;
  line-height: 21px;
}

.custom-select-trigger:after {
  display: none;
}

.button_div {
  float: left;
  width: 100%;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--theme-color);
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--theme-color);
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--theme-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 12px 18px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid var(--theme-color-bold);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  letter-spacing: 0.5px;
}

.extra_margin_red {
  margin-top: -22px !important;
}

.heading_foam img {
  width: auto !important;
}

.search_input .form-group img {
  width: auto !important;
}

.btn_right_section img {
  width: auto !important;
}



.date {
  position: absolute !important;
  left: 0;
  right: 0;
  margin-top: -28px;
  z-index: 99999;
}

.sizeboxdashboard {
  height: 700px !important;
}

.price_box {
  height: 650px;
}


.btn_section_top .text-section_btn h5 {
  width: 100%;
  float: left;
}

.margin_achivment .tickets .owl-item img {
  width: auto;
  margin: 0 auto;
  text-align: center;
}

.price_store_margin {
  top: -44px;
}

.tabs_profile .nav-tabs {
  align-items: center !important;
  justify-content: center !important;
  display: grid;
  grid-template-columns: auto auto auto;
}

.tabs_profile .nav-tabs .nav-item {
  margin: 0px 8px;
}

.tabs_profile .nav-tabs .active {
  background-color: transparent !important;
}

.tabs_profile .nav-tabs .nav-link {
  border: none !important;
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
}

.tabs_profile .tab-content {
  background: transparent;
  line-height: 25px;
  border: 0px solid #ddd;
  border-top: 0px solid #e74c3c;
  border-bottom: 0px solid #e74c3c;
  /* padding: 30px 0px; */
  float: left;
  width: 100%;
  background: #f5e6c1;
  /* margin-top: 23px; */
  /* border-radius: 20px;
    border: 3px solid #a86427; */
}

.password input {
  margin-bottom: 9px;
}











@media screen and (max-width:2560px) and (min-width: 1921px) {

  .top_btn_section ul {
    transform: scale(1.3);
  }

  .center_div_main {
    transform: scale(1.2);
  }

  .left_girl_section {
    left: 9%;
    transform: scale(1.4);
  }

  .right_boy_section {
    right: 5%;
    transform: scale(1.4);
  }

  .white_section_box_mission2 {
    height: 416px !important;
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 5px;
  }

  .margin_misson {
    margin-top: 40px !important;
  }

  .modal-dialog {
    transform: scale(1.5) !important;
  }

  .price_store_margin {
    top: -44px;
  }



  .left_girl_section:hover {
    transform: scale(1.7) !important;
  }

  .right_boy_section:hover {
    transform: scale(1.7) !important;
  }

  #section1 {
    overflow: hidden !important;
  }




}

.inner_header {
  position: relative;
}

.about_inner {
  background: #faf9fa !important;
}

.redirect_modal .modal-dialog {
  max-width: 851px;
  margin: 1.75rem auto;
}

.main_center_modal {
  position: relative;
}

.center_contant {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  float: left;
  width: 100%;
  text-align: center;
}

.font1 {
  font-family: "tintco_demoregular";
}

.purplec {
  color: #63065c !important;
}

.home_btn {
  margin: 10px 0px;
  width: 286px;
}

.home_btnul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 35px;
}

.redirect_modal .btn-close {
  background: none;
  position: absolute;
  right: 0;
  opacity: 1;
}


.green_btnsign img {
  width: 150px !important;
}


@media (max-width: 1600px) {

  .password_box {
    margin-top: -25px !important;
  }

  .coin {
    margin-top: -33px;
    transform: scale(0.8);
  }

  .password_box .foam_section {
    width: 90% !important;
  }

  .text-section_btn {
    width: 200px;
  }

  .inner_box_achivment {
    height: 261px;
  }

  .brown_box {
    height: 249px;
  }



  .section_level_right ul li a {
    display: block;
    width: 100%;
    padding: 14px 4px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #602904;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 12px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    letter-spacing: 0.5px;
  }

  .margin_top_raffler {
    margin-top: -15px;
  }


  .heading_foam img {
    display: none;
  }

  .one_third_box2 {
    height: 457px;
  }

  .price_box {
    height: 550px;
  }

  .tab_pan_section .nav-tabs .nav-link {
    font-size: 13px;
    border: 2px solid #602904;
    padding: 9px 6px;
  }



  .search_input .form-group img {
    width: 19px !important;
  }

  .sizeboxdashboard {
    height: 630px !important;
  }

  .white_section_box_mission {
    float: left;
    width: 100%;
    height: 342px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

  }

  .pinkstrip2 {
    top: -41px !important;
  }

  .foam_inner_box2 {
    width: 605px !important;
    margin: 0 auto;
  }

  .white_section_box {
    padding: 4px 19px;
  }

  .size_box2 {
    width: 700px !important;
  }

  .margin-top-mission-confirm {
    margin-top: -11px;
  }

  .margin_misson {
    margin-top: -17px !important;
  }

  .btn_right_section {
    float: right;
    padding: 3px 0px;
  }

  .box_white_inner h3 {
    font-size: 12px;
  }

  .white_section_box h4 {
    font-size: 16px;
  }

  .text {
    width: auto !important;
  }

  .foam_inner_box {
    width: 603px;
    margin: 0 auto;
  }

  .btn_div_upload .btn {
    border: 2px solid #602904;
    color: #602904;
    background-color: #d1b169;
    padding: 10px 16px;
    border-radius: 12px;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }

  .top_other_div {
    top: -18px !important;
  }

  .uploade_btn {
    float: left;
    width: 100%;
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .extra_margin_red {
    margin-top: -12px !important;
  }

  .top_extra_2 {
    top: -20px !important;
  }

  .form-control {
    padding: 13px 18px;
    font-size: 15px;
  }

  .custom-select-trigger {
    font-size: 16px;
  }

  .sign_strip_top {
    top: -44px;
  }

  .margin_top_c {
    margin-top: 17px;
  }

  .info img {
    width: auto !important;
  }

  .top_extra_incres {
    top: -104px !important;
  }

  .pink_strip_top img {
    width: 475px !important;
  }

  .size_box {
    width: 704px !important;
  }

  .orange_btn {
    background-size: cover;
    width: 180px;
    height: 62px;
  }

  .margin_big {
    margin-top: 0px !important;
    margin-bottom: 33px !important;
  }

  .midium_title {
    font-size: 32px;
  }

  .margin_2_extra {
    margin-top: -29px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -21px !important;
  }


  .right_boy_section img {
    width: 309px;
  }

  .center_div_main_relation img {
    width: 569px;
  }

  .close {
    position: absolute;
    top: -16px;
    right: 126px;
    width: 50px !important;
  }

  .sign_strip_top img {
    width: 480px;
  }

  .round_image_profile {
    width: 101px;
    height: 101px;
  }

  .big_title {
    font-size: 32px;
  }

  .heading_foam {
    font-size: 16px;
  }

  .green_btn {
    background-size: cover;
    width: 150px !important;
    height: 62px;
  }

  .center_div_contain {
    margin-top: 18px;
  }

  .top_sucess {
    top: 0px !important;
  }

  .center_div_main_relation {
    margin-top: 55px;
  }

  .heading_foam {
    font-size: 17px;
    font-weight: 700;
    color: #602904;
    text-align: left;
    line-height: 21px;
  }

  .btn_section {
    position: relative;
    top: 0px;
  }

  .foam_section {
    width: 519px;
    margin: 0 auto;
    padding: 45px;
    padding-top: 0;
    padding-bottom: 0;
  }


  .box_text h3 {
    font-size: 27px;
  }

  .brown_box {
    padding: 6px 7px;
  }

  .brown_box .heading_foam {
    font-size: 15px;
  }

  .brown_box .green_btn {
    width: 132px !important;
    height: 62px;
  }

  .profile_bottom_section p {
    font-size: 14px;
    color: #602904;
    margin-left: 18px;
    margin-bottom: -20px !IMPORTANT;
  }

  .profile_image_btn {
    width: 56px;
    height: 56px;
    border: 2px solid #602904;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    top: -5px;
  }

  .mobile_flex .achievement_heading {
    margin: 1px !important;
  }

  .margin_bottom_level {
    margin-bottom: -10px !important;
  }

  .achivment_strip {
    top: -36px !important;
  }


  .text1 {
    width: 163 !important;
  }

  .margin_achivment .tickets .owl-item .text2 {
    width: 150 !important;
  }

  .text3 {
    width: 170 !important;
  }

  .margin_top_reduse {
    margin-top: 0;
    margin-bottom: 0px;
  }






  .star_img {
    position: relative;
    top: 0px;
    padding: 0px 21px;
  }




  .price_store_margin {
    margin-top: -17px !important;
  }

  .mobile_flex {
    height: 111px;
  }

  .achiv_imge {
    padding: 0px 35px;
  }

  .achive_btn {
    padding: 0px 15px;
  }

}

@media (max-width: 1440px) {
  .coin {
    margin-top: -57px;
    transform: scale(0.8);
  }

  .survey_btn2 {
    bottom: -10px !important;
  }



  .profile_div_bottom .profile_image_btn {
    top: -8px !important;
  }

  .margin_misson {
    margin-top: -10px !important;
  }

  .price_store_margin {
    margin-top: -20px !important;
  }



  .section_level_right ul li a {
    padding: 11px 4px;
    font-size: 15px;
  }

  .dashboard_survey .survey_btn {
    bottom: 0;
  }

  .inner_box_achivment {
    height: 240px;
    padding: 2px;
  }

  .mobile_flex .achievement_heading {
    margin: 1px !important;
    font-size: 14px;
  }

  .text4 {
    padding: 0px 30px;
  }

  .brown_box {
    height: 230px;
  }

  .ticket_box {
    float: left;
    width: 100%;
    margin-top: -10px;
    position: relative;
  }

  .survey_btn {
    width: 70%;
    position: absolute;
    bottom: 40px;
  }


  .line_logo {
    width: 360px !important;
    margin-top: 10px;
  }


  .right_boy_section {
    right: 0;
  }

  .tab_pan_section .nav-tabs .nav-link {
    padding: 9px 5px;
  }

  .left_girl_section2 img {
    width: 329px;
  }

  .left_girl_section {
    left: 5%;
  }






  .heading_foam img {
    display: none;
  }

  .search_input .form-group img {
    width: 19px !important;
  }


  .right_boy_section2 {
    position: absolute;
    top: 12%;
    right: -1%;
  }

  /*		.small_container .right_boy_section {
    right: 8%;
}*/
  /*	.small_container .left_girl_section {
    left: 6%;
}*/
  .foam_inner_box2 {
    width: 605px !important;
    margin: 0 auto;
  }

  .white_section_box {
    padding: 4px 19px;
  }

  .size_box2 {
    width: 700px !important;
  }

  .margin_misson {
    margin-top: 0px !important;
  }

  .margin_achivment {
    top: 22px !important;
  }

  .foam_inner_box {
    width: 545px;
    margin: 0 auto;
  }

  .box_text h3 {
    font-size: 20px;
  }

  .brown_box {
    padding: 2px 7px;
  }

  .text1 {
    width: 80px !important;
  }

  .text2 {
    width: 66px !important;
  }

  .text3 {
    width: 55px !important;
  }

  .brown_box .heading_foam {
    font-size: 13px;
    margin-bottom: 0px;
  }

  .brown_box .green_btn {
    width: 132px !important;
    height: 62px;
  }

  .profile_bottom_section p {
    font-size: 14px;
    color: #602904;
    margin-left: 18px;
  }

  .profile_image_btn {
    width: 56px;
    height: 56px;
    border: 2px solid #602904;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    top: -5px;
  }

  .box_text {
    margin-bottom: 13px !important;
  }

  .margin_bottom_level {
    margin-bottom: -10px !important;
  }

  .achivment_strip {
    top: -48px !important;
  }

  .profile_div_bottom {
    margin-top: 0;
  }

  .achivement_top_div {
    top: 10px;
  }

  .btn_div_upload .btn {
    border: 2px solid #602904;
    color: #602904;
    background-color: #d1b169;
    padding: 10px 16px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }

  .top_other_div {
    top: -8px !important;
  }

  .top_extra_2 {
    top: -11px !important;
  }

  .text_div .heading_foam {
    padding-right: 35px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .info {
    text-align: left;
    float: left;
    margin-top: 20px;
    color: #602904;
    font-size: 12px;
    font-weight: 500;
  }

  .form-control {
    padding: 10px 18px;
    font-size: 14px;
  }

  .custom-select-trigger {
    font-size: 14px;
  }

  .sign_strip_top {
    top: -39px;
  }

  .margin_top_c {
    margin-top: 17px;
  }

  .info img {
    width: auto !important;
  }

  .top_extra_incres {
    top: -104px !important;
  }

  .pink_strip_top img {
    width: 475px !important;
  }

  .size_box {
    width: 704px !important;
  }

  .foam_section {
    width: 465px;
    margin: 0 auto;
    padding: 45px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .pink_strip_top img {
    width: 435px !important;
  }

  .size_box {
    width: 634px !important;
  }

  .orange_btn {
    background-size: cover;
    width: 180px;
    height: 62px;
  }

  .margin_big {
    margin-top: 0px !important;
    margin-bottom: 33px !important;
  }

  .midium_title {
    font-size: 32px;
  }

  .margin_2_extra {
    margin-top: -54px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -21px !important;
  }

  .pink_strip_top {
    top: -33px;
  }

  .small_title {
    font-size: 22px;
  }

  .left_girl_section img {
    width: 260px;
  }

  .right_boy_section img {
    width: 260px;
  }

  .right_boy_section2 img {
    width: 340px;
  }

  .center_div_main_relation img {
    width: 510px;
  }

  .close {
    position: absolute;
    top: -5px;
    right: 161px;
    width: 41px !important;
  }

  .sign_strip_top img {
    width: 432px;
  }

  .round_image_profile {
    width: 92px;
    height: 92px;
  }

  .big_title {
    font-size: 27px;
  }

  .heading_foam {
    font-size: 16px;
  }

  .orange_btn {
    background-size: cover;
    width: 193px;
    height: 62px;
  }

  .green_btn {
    background-size: cover;
    width: 170px;
    height: 62px;
  }

  .center_div_contain {
    margin-top: -12px;
  }

  .margin_change {
    margin-top: 15px !important;
  }

  .top_sucess {
    top: 0px !important;
  }

  .top_extra_incres {
    top: -93px !important;
  }

  .center_div_main_relation {
    margin-top: 132px;
  }

  .heading_foam {
    font-size: 14px;
    font-weight: 700;
    color: #602904;
    text-align: left;
    line-height: 21px;
  }

  .btn_section {
    position: relative;
    top: 0px;
  }

  .padding {
    padding: 17px 0px;
    margin-bottom: 0;
    position: relative;
    top: 5px;
  }

  .text-section_btn h5 {
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 700;
    color: #602904;
  }

  .text-section_btn {
    width: 187px;
  }

  .profile_image_btn {
    top: 6px !important;
  }

  .mobileschool {
    margin-bottom: 10px;
  }

  .one_third_box {
    float: left;
    width: 100%;
    margin-top: 8px;
  }

  .tickets .text_img_achievement .text1 {
    width: 163 !important;
  }

  .tickets .text_img_achievement .text2 {
    width: 150 !important;
  }

  .tickets .text_img_achievement .text3 {
    width: 170 !important;
  }

  .survey_btn {
    width: 70%;
    position: absolute;
    bottom: 40px;
  }


}

@media (max-width: 1400px) {
  .tab_pan_section .nav-tabs .nav-link {
    padding: 9px 5px;
  }

  .coin {
    margin-top: -117px;
    transform: scale(0.8);
  }

  .foam_inner_box2 {
    width: 605px !important;
    margin: 0 auto;
  }

  .white_section_box {
    padding: 4px 19px;
  }

  .size_box2 {
    width: 700px !important;
  }

  .margin_misson {
    margin-top: 30px !important;
  }

  .btn_mission {
    top: 17px !important;
  }

  .profile_div_bottom {
    float: left;
    width: 100%;
    margin-top: 1px;
    margin-bottom: 0px;
  }

  .margin_achivment {
    top: 9px !important;
  }

  .foam_inner_box {
    width: 545px;
    margin: 0 auto;
  }

  .box_text h3 {
    font-size: 19px;
  }

  .brown_box {
    padding: 2px 7px;
  }

  .margin_top_reduse {
    margin-top: -12px;
  }

  .brown_box .custom-option {
    font-size: 12px;
  }

  .brown_box .custom-select-trigger {
    font-size: 11px;
  }

  .text1 {
    width: 80px !important;
  }

  .text2 {
    width: 57px !important;
  }

  .text3 {
    width: 48px !important;
  }

  .brown_box .heading_foam {
    font-size: 13px;
  }

  .brown_box .green_btn {
    width: 112px !important;
    height: 62px;
  }

  .profile_bottom_section p {
    font-size: 12px;
    color: #602904;
    margin-left: 18px;
  }

  .profile_image_btn {
    width: 49px;
    height: 49px;
    border: 2px solid #602904;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    top: -5px;
  }

  .box_text {
    margin-bottom: 13px !important;
  }

  .margin_bottom_level {
    margin-bottom: -10px !important;
  }

  .achivment_strip {
    top: -36px !important;
  }

  .btn_div_upload .btn {
    border: 2px solid #602904;
    color: #602904;
    background-color: #d1b169;
    padding: 10px 16px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }

  .top_other_div {
    top: -8px !important;
  }

  .top_extra_2 {
    top: -11px !important;
  }

  .text_div .heading_foam {
    padding-right: 35px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .info {
    text-align: left;
    float: left;
    margin-top: 20px;
    color: #602904;
    font-size: 12px;
    font-weight: 500;
  }

  .top_extra_2 {
    top: -11px !important;
  }

  .form-control {
    padding: 10px 18px;
    font-size: 14px;
  }

  .custom-select-trigger {
    font-size: 14px;
  }

  .sign_strip_top {
    top: -39px;
  }

  .margin_top_c {
    margin-top: 17px;
  }

  .info img {
    width: auto !important;
  }

  .top_extra_incres {
    top: -104px !important;
  }

  .pink_strip_top img {
    width: 475px !important;
  }

  .size_box {
    width: 704px !important;
  }

  .foam_section {
    width: 465px;
    margin: 0 auto;
    padding: 45px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding {
    padding: 18px 0px;
    margin-bottom: 0;
    position: relative;
    top: 4px;
  }

  .pink_strip_top img {
    width: 435px !important;
  }

  .size_box {
    width: 601px !important;
  }

  .orange_btn {
    background-size: cover;
    width: 196px;
    height: 62px;
  }

  .green_btn {
    background-size: cover;
    width: 170px !important;
    height: 62px;
  }

  .margin_big {
    margin-top: 0px !important;
    margin-bottom: 33px !important;
  }

  .midium_title {
    font-size: 28px;
  }

  .margin_2_extra {
    margin-top: -54px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -21px !important;
  }

  .pink_strip_top {
    top: -33px;
  }

  .small_title {
    font-size: 22px;
    margin-bottom: 0;
  }

  .left_girl_section img {
    width: 250px;
  }

  .right_boy_section img {
    width: 265px;
  }

  .center_div_main_relation img {
    width: 510px;
  }

  .close {
    position: absolute;
    top: -5px;
    right: 161px;
    width: 41px !important;
  }

  .sign_strip_top img {
    width: 432px;
  }

  .round_image_profile {
    width: 92px;
    height: 92px;
  }

  .big_title {
    font-size: 27px;
  }

  .heading_foam {
    font-size: 16px;
  }

  .green_btn {
    background-size: cover;
    width: 170px;
    height: 62px;
  }

  .center_div_contain {
    margin-top: -12px;
  }

  .margin_change {
    margin-top: 15px !important;
  }

  .top_sucess {
    top: 0px !important;
  }

  .top_extra_incres {
    top: -93px !important;
  }

  .center_div_main_relation {
    margin-top: 132px;
  }

  .heading_foam {
    font-size: 14px;
    font-weight: 700;
    color: #602904;
    text-align: left;
    line-height: 21px;
  }

  .btn_section {
    position: relative;
    top: 0px;
  }

  .dashboard_survey .survey_btn {
    bottom: 40px;
  }

  .brown_box .green_btn {
    height: auto !important;
  }

  .margin_top_c {
    margin-top: 11px !important;
  }



}

@media (max-width: 1366px) {
  .text-section_btn {
    width: 190px;
  }

  .coin {
    margin-top: -100px;
  }

  .mobile_flex {
    height: 104px;
  }

  .ticket_box_inner img {
    width: 44% !important;
    height: 169px;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid #602904;
    margin-bottom: 10px;
  }

  .inner_box_achivment {
    height: 220px;
  }

  .brown_box {
    height: 210px;
  }



  .left_girl_section2 {
    float: left;
    position: absolute;
    left: -1%;
    top: 12%;
    display: flex;
    align-items: center;
  }

  .left_girl_section2 img {
    width: 315px;
  }


  .foam_inner_box2 {
    width: 500px !important;
    margin: 0 auto;
  }

  .right_boy_section2 img {
    width: 300px;
  }

  .white_section_box {
    padding: 4px 19px;
  }

  .size_box2 {
    width: 700px !important;
  }

  .margin_misson {
    margin-top: 8px !important;
  }

  .one_third_box2 {
    height: 436px;
  }

  .btn_mission {
    top: 17px !important;
  }

  .profile_div_bottom {
    float: left;
    width: 100%;
    margin-top: 1px;
    margin-bottom: 0px;
  }

  .margin_achivment {
    top: 9px !important;
  }

  .foam_inner_box {
    width: 516px;
    margin: 0 auto;
  }

  .box_text h3 {
    font-size: 19px;
  }

  .brown_box {
    padding: 2px 7px;
  }

  .margin_top_reduse {
    margin-top: -12px;
  }

  .brown_box .custom-option {
    font-size: 12px;
  }

  .brown_box .custom-select-trigger {
    font-size: 11px;
  }

  .text1 {
    width: 80px !important;
  }

  .text2 {
    width: 57px !important;
  }

  .text3 {
    width: 48px !important;
  }

  .brown_box .heading_foam {
    font-size: 13px;
  }

  .brown_box .green_btn {
    width: 112px !important;
    height: 62px;
  }

  .profile_bottom_section p {
    font-size: 12px;
    color: #602904;
    margin-left: 18px;
  }

  .profile_image_btn {
    width: 49px;
    height: 49px;
    border: 2px solid #602904;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    top: -5px;
  }

  .box_text {
    margin-bottom: 13px !important;
  }

  .margin_bottom_level {
    margin-bottom: -10px !important;
  }

  .achivment_strip {
    top: -36px !important;
  }

  .btn_div_upload .btn {
    border: 2px solid #602904;
    color: #602904;
    background-color: #d1b169;
    padding: 10px 16px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }

  .top_other_div {
    top: -8px !important;
  }

  .top_extra_2 {
    top: -11px !important;
  }

  .text_div .heading_foam {
    padding-right: 35px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .info {
    text-align: left;
    float: left;
    margin-top: 20px;
    color: #602904;
    font-size: 12px;
    font-weight: 500;
  }

  .pink_strip_top img {
    width: 406px !important;
  }

  .size_box {
    width: 591px !important;
  }

  .orange_btn {
    background-size: cover;
    width: 184px;
    height: 62px;
  }

  .green_btn {
    background-size: cover;
    width: 162px !important;
    height: 62px;
  }

  .margin_big {
    margin-top: 0px !important;
    margin-bottom: 33px !important;
  }

  .midium_title {
    font-size: 28px;
  }

  .margin_2_extra {
    margin-top: -54px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -21px !important;
  }

  .pink_strip_top {
    top: -33px;
  }

  .small_title {
    font-size: 22px;
    margin-bottom: 0;
  }

  .margin_title {
    margin-bottom: 21px !important;
    margin-top: 12px !important;
  }

  .left_girl_section img {
    width: 220px;
  }

  .sizeboxdashboard {
    height: 580px !important;
  }

  .right_boy_section img {
    width: 340px !important;
  }

  .center_div_main_relation img {
    width: 510px;
  }

  .close {
    position: absolute;
    top: -5px;
    right: 161px;
    width: 41px !important;
  }

  .sign_strip_top img {
    width: 419px;
  }

  .round_image_profile {
    width: 92px;
    height: 92px;
  }

  .big_title {
    font-size: 27px;
  }

  .heading_foam {
    font-size: 16px;
  }

  .green_btn {
    background-size: cover;
    width: 170px;
    height: 62px;
  }

  .center_div_contain {
    margin-top: -12px;
  }

  .margin_change {
    margin-top: 30px !important;
  }

  .top_sucess {
    top: 0px !important;
  }

  .top_extra_incres {
    top: -93px !important;
  }

  .center_div_main_relation {
    margin-top: 143px;
  }

  .heading_foam {
    font-size: 14px;
    font-weight: 700;
    color: #602904;
    text-align: left;
    line-height: 21px;
  }

  .btn_section {
    position: relative;
    top: 0px;
  }

  .btn_section a .green_btn {
    text-shadow: 1px 5px 0 #195e00, 4px 2px 0 #195e00, 4px 5px 0 #195e00, 1px 1px 0 #195e00;
    margin-bottom: -6px;
  }

  .sizeboxdashboard {
    height: 570px !important;
  }

  .right_boy_section img {
    width: 282px !important;
  }
}

@media (max-width: 1350) {
  .foam_section {
    width: 300px;
    margin: 0 auto;
    padding: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 1280px) {
  .mobile_flex .achievement_heading {
    margin: 1px !important;
    font-size: 12px;
  }

  .mobile_flex .heading_foam {
    font-size: 13px !important;
  }

  .achiv_imge {
    padding: 0px 24px;
  }

  .achive_btn {
    padding: 0px 26px;
  }

  .text4 {
    padding: 0px 25px;
  }

  .achivment_strip {
    top: -45px !important;
  }

  .survey_btn2 {
    bottom: -18px !important;
  }

  .survey_btn2 img {
    width: 55% !important;
    position: relative;
  }

  .survey_btn2 .btn_overlay {
    top: 11px;
    font-size: 14px;
  }


  .logo_section img {
    width: 291px;
    margin-top: 10px;
  }

  .ticket_box_inner img {
    width: 39% !important;
    height: 157px;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid #602904;
    margin-bottom: 10px;
  }

  .top_btn_section ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    position: relative;
    z-index: 99999;
    padding: 10px 0px;
    position: relative;
    top: 7px;
  }

  .text-section_btn h5 {
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 700;
    color: #602904;
  }

  .top_btn_section ul li {
    display: inline-block;
    margin-left: 27px;
  }

  .survey_btn {
    width: 62%;
    position: absolute;
    bottom: 40px;
    text-align: center;
    left: 0;
    right: 0 !important;
    float: none;
    margin: 0 auto;
  }

  .inner_box_achivment {
    height: 225px;
  }

  .brown_box {
    height: 210px;
  }


  .left_girl_section img {
    width: 210px;
  }

  .right_boy_section img {
    width: 215px;
  }


  .font2 {
    font-size: 14px !important;
  }

  .profile_div_bottom {
    float: left;
    width: 100%;
    margin-top: -2px;
    margin-bottom: 4px;
  }

  .margin_achivment {
    top: -12px !important;
  }

  .foam_inner_box {
    width: 476px;
    margin: 0 auto;
  }

  .box_text h3 {
    font-size: 19px;
  }

  .brown_box {
    padding: 2px 7px;
  }

  .margin_top_reduse {
    margin-top: -12px;
  }

  .brown_box .custom-option {
    font-size: 12px;
  }

  .brown_box .custom-select-trigger {
    font-size: 11px;
  }

  .text1 {
    width: 80px !important;
  }

  .text2 {
    width: 57px !important;
  }

  .text3 {
    width: 48px !important;
  }

  .brown_box .form-control {
    padding: 7px 18px;
    font-size: 12px;
  }

  .brown_box .heading_foam {
    font-size: 11px;
  }

  .brown_box .green_btn {
    width: 112px !important;
    height: 62px;
  }

  .profile_bottom_section p {
    font-size: 12px;
    color: #602904;
    margin-left: 18px;
  }

  .profile_image_btn {
    width: 49px;
    height: 49px;
    border: 2px solid #602904;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    top: -5px;
  }

  .box_text {
    margin-bottom: 13px !important;
  }

  .margin_bottom_level {
    margin-bottom: -10px !important;
  }

  .achivment_strip {
    top: -36px !important;
  }

  .btn_div_upload .btn {
    border: 2px solid #602904;
    color: #602904;
    background-color: #d1b169;
    padding: 10px 16px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }

  .top_other_div {
    top: -8px !important;
  }

  .top_extra_2 {
    top: -11px !important;
  }

  .text_div .heading_foam {
    padding-right: 35px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .info {
    text-align: left;
    float: left;
    margin-top: 20px;
    color: #602904;
    font-size: 12px;
    font-weight: 500;
  }

  .pink_strip_top img {
    width: 406px !important;
  }

  .size_box {
    width: 591px !important;
  }

  .orange_btn {
    background-size: cover;
    width: 184px;
    height: 62px;
  }

  .green_btn {
    background-size: cover;
    width: 145px !important;
    height: 62px;
  }

  .margin_big {
    margin-top: 0px !important;
    margin-bottom: 33px !important;
  }

  .midium_title {
    font-size: 28px;
  }

  .margin_2_extra {
    margin-top: -54px !important;
  }

  .pink_strip_top img {
    width: 381px !important;
  }

  .size_box {
    width: 560px !important;
  }

  .orange_btn {
    background-size: cover;
    width: 175px;
    height: 62px;
  }

  .green_btn {
    background-size: cover;
    width: 145px !important;
    height: 62px;
  }

  .margin_big {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .midium_title {
    font-size: 28px;
  }

  .margin_2_extra {
    margin-top: -54px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -38px !important;
  }

  .pink_strip_top {
    top: -30px;
  }

  .small_title {
    font-size: 22px;
    margin-bottom: 0;
  }

  .margin_title {
    margin-bottom: 21px !important;
    margin-top: 12px !important;
  }

  .left_girl_section {
    left: 4%;
  }

  .left_girl_section img {
    width: 180px;
  }

  .left_girl_section2 img {
    width: 313px;
  }

  .left_girl_section2 {
    float: left;
    position: absolute;
    left: 0;
    top: 12%;
    display: flex;
    align-items: center;
  }

  .right_boy_section img {
    width: 200px;
  }

  .center_div_main_relation img {
    width: 510px;
  }

  .close {
    position: absolute;
    top: -5px;
    right: 161px;
    width: 41px !important;
  }

  .sign_strip_top img {
    width: 419px;
  }

  .round_image_profile {
    width: 92px;
    height: 92px;
  }

  .big_title {
    font-size: 27px;
  }

  .heading_foam {
    font-size: 16px;
  }

  .green_btn {
    background-size: cover;
    width: 180px;
    height: 62px;
  }

  .center_div_contain {
    margin-top: -12px;
  }

  .margin_change {
    margin-top: 31px !important;
  }

  .top_sucess {
    top: 0px !important;
  }

  .top_extra_incres {
    top: -93px !important;
  }

  .center_div_main_relation {
    margin-top: 129px;
  }

  .heading_foam {
    font-size: 14px;
    font-weight: 700;
    color: #602904;
    text-align: left;
    line-height: 21px;
  }

  .btn_section {
    position: relative;
    top: 0px;
  }

  .custom-select-trigger {
    font-size: 12px;
  }

  .form-control {
    padding: 10px 18px;
    font-size: 12px;
  }

  .right_boy_section img {
    width: 220px;
  }

  .left_girl_section img {
    width: 220px;
  }

  /*		.small_container .right_boy_section {
    right: 6%;
    top: 30%;
}
	.small_container .right_boy_section img {
    width: 259px;
}
		.small_container .right_boy_section2 img {
    width: 310px;
}
	.small_container  .left_girl_section img {
    width: 260px;
}*/
  .sizeboxdashboard {
    height: 515px !important;
  }

}

@media (max-width: 1200px) {
  .logo_section img {
    width: 259px;
    margin-top: 18px;
  }



  .line_logo {
    width: 300px !important;
    margin-top: 10px;
  }

  .inner_box_achivment {
    height: 208px;
  }

  .brown_box {
    height: 210px;
  }



  .mylogo {
    width: 220px !important;
  }

  .left_girl_section {
    left: 15px;
  }

  .right_boy_section img {
    width: 280px !important;
  }

  .mobileschool {
    margin-bottom: 2px;
  }

  .top_imge_div {
    float: left;
    width: 100%;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .green_btnsign {
    width: 118px !important;
  }

  .sizeboxdashboard {
    height: 570px !important;
  }

  .sign_up_margin {
    height: 525px;
  }

  .font2 {
    font-size: 18px !important;
  }

  .profile_div_bottom {
    float: left;
    width: 100%;
    margin-top: 1px;
    margin-bottom: 7px;
  }

  .brown_box .btn_section {
    top: 10px;
  }

  .mobile_flex {
    height: 94px;
  }


  .margin_achivment {
    top: 6px !important;
  }

  .foam_inner_box {
    width: 444px;
    margin: 0 auto;
  }

  .margin_achivment .foam_inner_box {
    width: 500px;
    margin-top: 23px;
  }

  .achive_btn {
    padding: 0px 11px;
  }

  .box_text h3 {
    font-size: 15px;
  }

  .brown_box {
    padding: 2px 7px;
  }

  .margin_top_reduse {
    margin-top: -12px;
  }

  .brown_box .custom-option {
    font-size: 12px;
  }

  .brown_box .custom-select-trigger {
    font-size: 11px;
  }

  .text1 {
    width: 80px !important;
  }

  .text2 {
    width: 57px !important;
  }

  .text3 {
    width: 48px !important;
  }

  .brown_box .form-control {
    padding: 7px 18px;
    font-size: 12px;
  }

  .brown_box .heading_foam {
    font-size: 11px;
  }

  .brown_box .green_btn {
    width: 97px !important;
    height: 62px;
  }

  .custom-options:before {
    display: none;
  }

  .profile_bottom_section p {
    font-size: 12px;
    color: #602904;
    margin-left: 18px;
  }

  .profile_image_btn {
    width: 49px;
    height: 49px;
    border: 2px solid #602904;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    top: -5px;
  }

  .box_text {
    margin-bottom: 13px !important;
  }

  .margin_bottom_level {
    margin-bottom: -10px !important;
  }

  .achivment_strip {
    top: -36px !important;
  }

  .uploade_btn {
    float: left;
    width: 100%;
    margin-top: 11px;
    margin-bottom: 9px;
  }

  .btn_div_upload .btn {
    border: 2px solid #602904;
    color: #602904;
    background-color: #d1b169;
    padding: 5px 4px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }

  .top_other_div {
    top: -8px !important;
  }

  .top_extra_2 {
    top: -11px !important;
  }

  .text_div .heading_foam {
    padding-right: 35px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .info {
    text-align: left;
    float: left;
    margin-top: 20px;
    color: #602904;
    font-size: 12px;
    font-weight: 500;
    margin-top: 0;
  }

  .form-control {
    padding: 8px 18px;
    font-size: 12px;
  }

  .foam_section {
    width: 410px;
    margin: 0 auto;
    padding: 45px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .pink_strip_top img {
    width: 381px !important;
  }

  .size_box {
    width: 500px !important;
  }

  .orange_btn {
    background-size: cover;
    width: 162px;
    height: 62px;
  }

  .green_btn {
    background-size: cover;
    width: 145px !important;
    height: 62px;
  }

  .margin_big {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }

  .midium_title {
    font-size: 23px;
  }

  .margin_2_extra {
    margin-top: -89px !important;
  }

  .margin_top_c {
    margin-top: -5px;
  }

  .btn_section_2 {
    position: relative;
    top: -38px !important;
  }

  .pink_strip_top {
    top: -30px;
  }

  .small_title {
    font-size: 22px;
    margin-bottom: 0;
  }

  .margin_title {
    margin-bottom: 21px !important;
    margin-top: 12px !important;
  }

  /*	.small_container .left_girl_section {
    left: 6%;
    top: 29%;
}*/
  .left_girl_section img {
    width: 180px;
  }

  .left_girl_section2 img {
    width: 290px;
  }

  .right_boy_section img {
    width: 180px;
  }

  .center_div_main_relation img {
    width: 430px;
  }

  .close {
    position: absolute;
    top: -5px;
    right: 203px;
    width: 35px !important;
  }

  .sign_strip_top img {
    width: 360px;
  }

  .round_image_profile {
    width: 80px;
    height: 80px;
  }

  .big_title {
    font-size: 21px;
  }

  .heading_foam {
    font-size: 16px;
  }

  .green_btn {
    background-size: cover;
    width: 140px;
    height: 62px;
  }

  .center_div_contain {
    margin-top: -49px;
  }

  .margin_change {
    margin-top: 6px !important;
  }

  .top_sucess {
    top: 0px !important;
  }

  .top_extra_incres {
    top: -78px !important;
  }

  .center_div_main_relation {
    margin-top: 119px;
  }

  .heading_foam {
    font-size: 13px;
    font-weight: 700;
    color: #602904;
    text-align: left;
    line-height: 17px;
  }

  .btn_section {
    position: relative;
    top: 0px;
  }

  .top_extra_2 {
    top: -4px !important;
  }

  /* .sign_strip_top {
    top: -33px;
  } */
  .achivment {
    width: 580px !important;
  }

  .small_container .right_boy_section img {
    width: 237px;
  }

  .small_container .left_girl_section img {
    width: 200px;
  }

  .coin ul li span {
    text-shadow: 4px 5px #000;
  }
}


@media (max-width: 1024px) {
  .right_boy_section2 {
    display: none;
  }

  .logo_section {
    float: left;
    width: 250px;
  }

  .redirect_modal .modal-dialog {
    max-width: 630px;
    margin: 1.75rem auto;
  }

  .center_contant {
    font-size: 31px;
  }

  .center_contant h5 {
    font-size: 17px;
    line-height: 30px;
  }

  .home_btn {
    margin: 10px 0px;
    width: 260px;
  }

  .home_btnul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 7px;
  }






  .left_girl_section img {
    display: none;
  }

  .right_boy_section img {
    display: none;
  }

  .btn_icon_section {
    float: left;
    position: absolute;
    left: -22px;
    top: 4px;
    width: 40px;
  }

  .achiveprofile {
    top: 0 !important;
    margin-top: -9px !important;
  }

  .achivem {
    margin-bottom: 2px !important;
  }

  .text-section_btn {
    width: 150px;
    padding: 8px 25px;
  }

  .text-section_btn h5 {
    font-size: 10px;

  }

  .text-section_btn h5 span {
    font-size: 12px;
    font-weight: 700;
  }

  .text-section_btn h6 {
    font-size: 10px;
    font-weight: 600;
    color: #602904;
    margin-bottom: 0px;
  }

  .top_btn_section ul li {
    display: inline-block;
    margin-left: 22px;
  }

  .green_btn {
    width: 125px !important;
  }


}

@media (max-width: 1400px) and (max-height: 1050px) {
  /* .left_girl img {
    display: none;
  } */
}


@media (max-width: 768px) {
  .text4 {
    padding: 0px 65px;
    margin-top: 9px !important;
  }

  .achiv_imge {
    padding: 0px 58px;
  }

  .sizeboxdashboard {
    height: 590px !important;
  }

  .input_section_level {
    width: 141px;
  }

  .back_btn {
    font-size: 12px;
  }

  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
  }

  .ticket_box .owl-prev {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #a86427 !IMPORTANT;
    border-radius: 35px;
    font-size: 24px !important;
    color: #FFF !important;
    text-align: center;
  }

  .ticket_box .owl-next {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #a86427 !IMPORTANT;
    border-radius: 35px;
    font-size: 24px !important;
    color: #FFF !important;
    text-align: center;
  }

  .ticket_box_inner h4 {
    font-size: 15px;
    font-family: "tintco_demoregular";
    color: #602904;
    margin-bottom: 0;
  }

  .ticket_box .owl-nav span {
    position: relative !important;
    top: -3px !important;
    left: 0px !important;
  }

  #section1 {
    background-position: right;
    background-size: auto;
  }

  .login_box_mobile {
    margin-top: 200px !important;
  }


  .priceinput {
    margin-bottom: 0px !important;
  }

  .section_level_right ul li a {
    padding: 8px 4px;
    font-size: 12px;
  }

  .total_amount h2 {
    font-size: 14px;
  }

  .rafrralbtn {
    width: 114px !important;
  }

  .achivemargin {
    margin-top: 3px !important;
  }

  .one_third_box2 {
    height: 482px;
  }

  .margin_misson {
    margin-top: 31px !important;
  }

  .bottom_section_popup {
    padding-top: 4px;
    color: red;
    font-size: 13px;
    letter-spacing: 1px;
  }

  .date {
    position: absolute !important;
    left: 0;
    right: 0;
    margin-top: -28px;
    z-index: 99999;
  }

  .foam_inner_box {
    width: 444px;
    margin: 0 auto;
  }

  .right_boy_section,
  .left_girl_section {
    display: none;
  }

  .logo_section img {
    width: 203px;
  }

  .text-section_btn {
    width: 137px;
  }

  .text-section_btn h5 {
    font-size: 11px;
  }

  .text-section_btn h6 {
    font-size: 10px;

  }

  .text-section_btn h5 span {
    font-size: 14px;
    font-weight: 700;
  }

  .text-section_btn {

    padding: 0px 24px;
    height: 42px;
    padding-right: 0;
  }

  .btn_icon_section {
    float: left;
    position: absolute;
    left: -27px;
    top: -2px;
  }

  .profile_image_btn {
    top: -1px !important;
  }

  .btn_icon_section img {
    width: 45px;
  }

  .logo_section {
    float: left;
    padding: 17px 0px;
  }

  .pinkstrip2 {
    top: -33px !important;
  }

  .width_reduse {
    width: 78%;
    text-align: left;
  }

  .box_white_inner h6 {

    float: left;
    width: 100%;
  }

  .heading_foam {
    font-size: 14px !important;
  }

  .text_box_modal .box_white_inner {
    float: left;
    margin-bottom: 2px;
    width: auto;
    margin-top: 5px;
  }

  .text-section_btn h6 a {
    color: #602904;
  }

  .sign_box {
    height: 560px;
  }

  .sign_in_div {
    margin-top: 44px;
  }

  .form-control {
    padding: 8px 18px;
    font-size: 12px;
    margin-bottom: 13px;
  }

  .center_div_main_relation {
    margin-top: 87px;
  }

  .width_modal_responsive {
    width: 65% !important;
  }

  .signupmarginmy {
    top: 88px !important;
  }

  .foam_section .mb-4 {
    margin-bottom: 0px !important;
  }

  .alert-heading {
    color: inherit;
    padding: 0;
    margin: 0;
    width: 288px;
    font-size: 11px;
  }

  .signup2box {
    height: 571px !important;
  }

  .alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    padding: 7px;
  }

  .size_box2 {
    width: 700px !important;
    height: 550px;
  }

  .box_white_inner {
    float: left;
    width: 80%;
    text-align: left;
  }

  .achievement_heading {
    font-size: 11px !important;
  }

  #section_mission {
    background-position: right;
    background-size: auto;
    overflow: scroll;
    overflow-x: hidden;
  }

}





@media (max-width: 600px) {

  .coin {
    margin-top: -127px;
    transform: scale(0.7);
    height: 587px;
  }

  .tabs_profile .nav-tabs {
    align-items: center !important;
    justify-content: center !important;
    display: grid;
    grid-template-columns: auto;
  }

  .tabs_profile .nav-tabs img {
    width: 50%;
    margin-bottom: 6px;
  }

  .password_brown_box {
    height: 642px !important;
    position: relative;
    top: -65px;
  }

  .foam_inner_box7 {
    margin-top: 189px !important;
  }

  .logo_section {
    float: left;
    padding: 0;
  }

  .line_logo {
    width: 280px !important;
    margin-top: 10px;
  }

  .survey_btn2 .btn_overlay {
    position: absolute;
    top: 7px;
    font-weight: 700;
    color: #fff;
    text-shadow: 2px 2px #29542a;
    font-style: italic;
    font-size: 11px;
  }


  .top_btn_section {
    float: left;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .size_box2 {
    width: 580px !important;
    height: 500px;
  }

  .foam_inner_box2 {
    width: 500px !important;
    margin: 0 auto;
  }


  .btn_right_section {
    float: right;
    padding: 0;
  }

  .box_white_inner {
    float: left;
    width: 80%;
  }

  .top_btn_section ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    position: relative;
    z-index: 99999;
    padding: 0;
    position: relative;
    top: 3px;
    padding-bottom: 10px;
  }

  .tab_pan_section .nav-tabs .nav-link {
    padding: 6px 2px;
  }

  .tab_pan_section .nav-tabs .nav-link {
    padding: 6px 4px;
    margin: 0px 2px;
    font-size: 11px;
    border-radius: 5px;
  }

  .box_white_inner {
    float: left;
    width: 74%;
  }

  .margin_misson {
    margin-top: 32px !important;
  }

  .right_div .custom-select-trigger {
    font-size: 11px;
  }

  .right_div {
    font-size: 11px !important;
  }

  .popup_img {
    height: 412px;
  }

  .right_div .custom-option {
    font-size: 11px;
    font-weight: 400;
    line-height: 24px;
  }

  .pinkstrip2 {
    top: -37px !important;
  }

  .price_box {
    height: 550px !important;
  }

  .price_margin {
    margin-top: 15px !important;
  }

  .custom-option {
    font-size: 11px !important;
  }

  .setmodal2 {
    height: 600px;
  }

  .price_box7 {
    height: 774px !important;
  }

  .center_div_main_relation2 {
    margin-top: -110px;
  }

  .navbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }



}


@media (max-width: 480px) {
  .login_btn {
    margin-top: 22px !important;
    float: left;
    width: 100%;
  }

  .survey_mobile {
    bottom: -50px !important;
  }

  .signup_continue {
    position: relative;
    top: 18px;
  }

  /* .back_btn {
    top: 16px ;
} */
  .center_contant {
    padding: 0px 60px;
  }

  .center_contant h1 {
    font-size: 22px !important;
  }

  .center_contant h5 {
    font-size: 15px;
    line-height: 26px;
    margin-top: -13px;
  }

  .home_btn {
    margin: 0px 0px;
    width: 260px;
  }

  .rotate {
    height: 353px;
  }

  .coin {
    transform: scale(1);
    margin-top: 106px;
    transform: scale(01);
    width: 387px;
  }

  .coin ul li {
    font-weight: 700;
    color: #fff;
    width: 60px;
    height: 60px;
    padding: 2px;
    margin: 0px 5px;
  }

  .coin ul li span {
    width: 100%;
    border-radius: 50%;
    border: 3px solid #ccc;
    text-shadow: 2px 2px #000;
    height: 100%;
    font-size: 34px;
    line-height: 36px;
    text-align: center;
  }

  .brown_box2 {
    background-color: #d1b169;
    float: left;
    width: 100%;
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .margin_achivment .foam_inner_box {
    width: 360px;
    margin-top: 23px;
  }

  .achiv_imge {
    padding: 0px 19px;
  }

  .achivement_top_div .profile_div_bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .achivement_top_div .profile_image_btn {
    width: 60px !important;
  }

  .achivement_top_div .profile_div_bottom .profile_image_btn {
    top: 7px !important;
  }

  .achive_btn {
    padding: 0px 0px;
  }

  .survey_btn {
    width: 35%;
    position: absolute;
    bottom: -70px;
    text-align: center;
    left: 0;
    right: 0 !important;
    float: none;
    margin: 0 auto;
    flex-direction: column;
  }

  .center_div_main_relation .password_box {
    margin-top: -25px !important;
  }


  .survey_btn img {
    width: 223px;
  }

  .survey_btn2 {
    bottom: -119px !important;
  }

  .ticket_box_inner img {
    width: 50% !important;
    height: 157px;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid #602904;
    margin-bottom: 10px;
  }

  .survey_btn a {
    margin-bottom: 8px;
  }

  .price_store_survey .survey_btn {
    bottom: -32% !important;
    width: 60%;
  }


  .center_div_main_relation2 {
    margin-top: -120px;
  }

  .brown_box {
    flex-direction: row !important;
    height: auto !important;
  }

  .line_logo {
    width: 230px !important;
    margin-top: 10px;
  }

  .foam_inner_box7 {
    margin-top: 299px !important;
  }

  .size_box {
    width: 440px !important;
  }

  .success .center_div_main_relation {
    margin-top: 209px;
  }

  .success .big_title {
    font-size: 16px;
    margin: 0px !important;
  }

  .success .btn_section a .green_btn {
    text-shadow: 1px 5px 0 #195e00, 4px 2px 0 #195e00, 4px 5px 0 #195e00, 1px 1px 0 #195e00;
    margin-bottom: 0px;
    position: relative;
    top: -10px;
  }

  .success .top_extra_incres {
    top: -50px !important;
  }

  .box_white_inner h3 {
    font-size: 10px;
  }

  .mobileschool {
    width: 54%;
    text-align: left !important;
    margin-bottom: 3px;
  }

  .achivment_strip {
    top: -25px !important;
  }

  .input_section_level {
    width: 110px;
  }

  .priceinput {
    margin-bottom: 0px !important;
    padding: 5px !important;
    border-radius: 7px;
    font-size: 11px !important;
  }

  .sizeboxdashboard2 {
    height: 600px !important;
  }

  .tag_p {
    font-size: 11px;
    margin-top: 4px;
    text-align: center;
    color: #8d4e23;
    font-weight: 500;
  }

  .profile_bottom_section p {
    margin-left: 24px;
  }

  .achievement_heading {
    float: left;
  }

  .top_imge_div {
    float: left;
    width: auto;
  }

  .box_text {
    margin-bottom: 13px !important;
    float: left;
    width: auto;
    margin-left: 15px;
  }

  .achievementbtn {
    float: right;
    width: auto;
  }

  .brown_box {
    padding: 12px 7px;
  }

  .box_text h3 {
    font-size: 15px;
    margin-right: 10px;
  }

  .inner_box_achivment {
    float: left;
    width: 90%;
    border: 2px dashed #602904;
    border-radius: 15px;
    padding: 2px;
    margin-left: 23px;
    margin-bottom: 13px;
  }

  .margin_achivment {
    top: 97px !important;
  }

  .brown_box .green_btn {
    width: 69px !important;
    height: 62px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .brown_box .heading_foam {
    font-size: 11px !important;
  }



  .font2 {
    font-size: 14px !important;
  }

















  .section_level_right ul li a {
    padding: 5px 4px;
    font-size: 11px;
    border-radius: 6px;
  }

  .profile_div_bottom .profile_image_btn {
    left: 20px;
  }

  .total_amount h2 {
    font-size: 12px;
  }

  .selectsetmodalmy .text-left {
    width: 100% !important;
  }

  .selectsetmodalmy .right_div {
    float: left;
    margin-top: 6px;
    margin-left: 0;
    width: 100% !important;
  }

  .modal2_divselect {
    top: 104px !important;
  }

  .selectsetmodalmy .box_white_inner {
    width: 100% !important;
  }

  .modal2_divselect {
    left: 45px !important;
  }

  .modal3_divselect {
    top: 61px !important;
  }

  .setmodal2 {
    height: 499px !important;
  }

  .size_task {
    height: 425px;
  }

  .center_div_main_relation .price_margin {
    margin-top: -40px !important;
  }

  .search_input .form-control {
    font-size: 12px;
  }

  .white_image {
    margin-right: 6px;
  }

  .priceinn {
    text-align: left !important;
    margin-bottom: 6px !important;
  }

  .price_box_inn {
    float: left;
    width: 35% !important;
  }

  .signupmarginmy {
    top: 100px !important;
  }

  .btn_right_section_price {
    float: right !important;
    width: 25% !important;
    margin-top: 46px;
  }

  .price_box {
    height: 470px !important;
  }

  .tab_pan_section .nav-tabs .nav-link {
    padding: 6px 6px;
    margin: 0px 2px;
    font-size: 9px;
    border-radius: 5px;
  }

  .top_btn_section ul {
    display: flex;
  }

  .top_btn_section ul li {
    display: inline-block;
    margin-left: 17px;
  }

  .profile_image_btn {
    width: 28px;
    height: 28px;
  }

  .btn_icon_section img {
    width: 28px;
  }

  .text-section_btn {
    padding: 0px 10px;
    height: 40px;
  }

  .btn_icon_section {
    float: left;
    position: absolute;
    left: -17px;
    top: 6px;
  }

  .margin_misson {
    margin-top: 27px !important;
  }



  .size_box2 {
    width: 450px !important;
    height: 550px;
  }

  .foam_inner_box2 {
    width: 400px !important;
    margin: 0 auto;
  }

  .btn_right_section {
    float: right;
    padding: 0;
  }

  .box_white_inner {
    float: left;
    width: 80%;
  }

  .top_btn_section ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    position: relative;
    z-index: 99999;
    padding: 0;
    position: relative;
    top: 3px;
    padding-bottom: 10px;
  }

  .logo_section img {
    width: 218px !important;
  }

  .mylogo {
    width: 218px !important;
  }

  .big_title {
    font-size: 19px;
  }

  .small_title {
    font-size: 19px;
    margin-bottom: 0;
  }

  .midium_title {
    font-size: 20px;
  }

  .margin_big {
    margin-bottom: 7px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -59px !important;
  }

  .center_div_main_relation {
    margin-top: 100px;
  }

  .sign_up_margin {
    margin-top: 70px !important;
  }

  .box_white_inner {
    float: left;
    width: 68%;
  }

  .center_div_main_relation .setmission {
    margin-top: -63px !important;
  }

  .setbtn img {
    width: 75px !important;
  }


  .login-form-submit .green_btn {
    width: 85px !important;
  }


  .brown_box .heading_foam {
    font-size: 12px !important;
  }

  .price_box7 {
    height: 776px !important;
  }

  .inner_box_achivment {
    height: auto !important;
  }

  .survey_btn {
    width: 100% !important;
  }

  .survey_btn img {
    width: 230px !important;
  }

  .brown_box {
    flex-direction: column !important;
  }

  .margin_2_extra {
    margin-top: 0 !important;
  }

  .calendly-inline-widget {
    position: relative;
    min-width: 266px !important;
    height: 630px;
  }



}


@media (max-width: 414px) {
  .font2 {
    font-size: 15px !important;
  }

  .coin {
    transform: scale(.9) !important;
    margin-top: 106px;
    transform: scale(01);
    width: 387px;
  }

  .center_contant h5 {
    font-size: 13px;
    line-height: 22px;
    margin-top: -8px;
  }

  .achivement_top_div .profile_image_btn {
    width: 49px !important;
  }

  .password_brown_box {
    height: 533px !important;
    position: relative;
    top: 0;
  }

  .margin_achivment .foam_inner_box {
    width: 360px;
    margin-top: 23px;
    margin-right: 7px !important;
  }

  .achivement_top_div .profile_div_bottom {
    margin: 0 auto;
  }

  .password_box .foam_section {
    width: 90% !important;
    padding: 0 !important;
  }

  .tabs_profile .nav-tabs img {
    width: 59%;
    margin-bottom: 6px;
  }


  .submit_btn {
    position: relative;
    top: 8px;
  }



  .survey_btn {
    width: 54%;
    position: absolute;
    bottom: -33px;
    text-align: center;
    left: 0;
    right: 0 !important;
    float: none;
    margin: 0 auto;
    flex-direction: column;
  }

  .survey_btn2 {
    bottom: -82px !important;
  }


  .price_box7 {
    height: 809px !important;
  }

  .size_box {
    width: 390px !important;
  }

  .login_box_mobile {
    margin-top: 250px !important;
  }


  .section_level_right ul li a {
    padding: 5px 10px;
    font-size: 11px;
    border-radius: 6px;
  }

  .total_amount {
    margin-top: 10px;
  }

  .rafrralbtn {
    width: 100px !important;
    float: right !important;
    margin-top: 4px;
  }

  .input_section_level {
    width: 100%;
  }

  .sizeboxdashboard {
    height: 626px !important;
  }

  .inner_box_achivment {
    width: 80%;
    margin-left: 46px;
  }

  .profile_bottom_section {
    float: left;
    width: 75%;
  }

  .profile_div_bottom {
    margin-bottom: 0px;
    margin-left: 29px;
  }

  .margin_achivment {
    top: 132px !important;
  }

  .brown_box .heading_foam {
    font-size: 11px !important;
  }


  .modal2_divselect {
    top: 74px !important;
  }

  .one_third_box2 {
    height: 508px;
  }

  .text_box_modal .box_white_inner h6 {
    float: left;
    width: 100%;
    text-align: left !important;
  }

  .right_div .form-control {
    padding: 6px 6px !important;
  }

  .text_box_modal .box_section_price {
    float: left;
    width: 100%;
    margin-bottom: 11px;
  }

  .center_div_main_relation .successmargin {
    top: -50px !important;
  }

  .center_div_main_relation .setmission {
    margin-top: -54px !important;
  }

  .right_div {
    float: left;
    margin-top: -9px;
    margin-left: 15px;
    width: 159px;
  }

  .center_div_main_relation .pinkstrip2price {
    top: -29px !important;
  }

  .text-left {
    float: left;
    width: 91px;
  }

  .right_div .custom-option {
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
  }

  .custom-select.opened .custom-options {
    border-radius: 6px;
  }

  .setmodal {
    height: 430px;
  }

  .setbtn img {
    width: 75px !important;
  }

  .box_white_inner {
    float: left;
    width: 100%;
    text-align: center !important;
  }

  .box_white_inner h6 {
    float: left;
    width: 100%;
    text-align: center !important;
  }

  .tab_pan_section .tab-content {
    margin-top: -18px !important;
  }

  .tab_pan_section .nav-tabs .nav-link {
    padding: 6px 6px;
    margin: 0px 2px;
    font-size: 9px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
  }

  .top_btn_section ul {
    display: inline-block;
  }

  .top_btn_section ul li {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  .login_box_margin {
    margin-top: 210px !important;
  }

  .sign_up_margin {
    margin-top: 11px !important;
  }

  .box_white_inner h3 {
    font-size: 11px;
  }

  .box_white_inner h3 {
    font-size: 11px;
  }

  .size_box2 {
    width: 380px !important;
  }

  .form-control {
    /* padding: 3px 18px; */
    /* font-size: 11px; */
    margin-bottom: 13px;
    border-radius: 8px;
  }

  .foam_inner_box2 {
    width: 320px !important;
    margin: 0 auto;
  }

  .btn_right_section {
    float: left;
    width: 100%;
  }

  .box_white_inner {
    float: left;
    width: 100%;
  }

  .white_section_box {
    padding: 4px 19px;
    margin-bottom: 6px !important;
  }

  .margin_misson {
    margin-top: 41px !important;
  }

  .info img {
    width: 16px !important;
    margin-right: 6px;
  }

  .center_div_main_relation img {
    width: 380px;
  }

  /* .sign_strip_top {
    top: -22px;
    width: 224px;
} */
  .sign_box {
    height: 518px;
  }

  .foam_section {
    padding: 0px 75px;
  }

  .center_div_contain {
    margin-top: -75px;
  }

  .logo_section img {
    width: 218px !important;
  }

  .mylogo {
    width: 218px !important;
  }

  .sign_in_div {
    margin-top: 23px !important;
  }

  .big_title {
    font-size: 19px;
  }

  .small_title {
    font-size: 19px;
    margin-bottom: 0;
  }

  .midium_title {
    font-size: 20px;
  }

  .margin_big {
    margin-bottom: 7px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -59px !important;
  }

  .pink_strip_top img {
    width: 299px !important;
  }

  .pink_strip_top {
    top: -24px;
  }

  .margin_2_extra {
    margin-top: -110px !important;
  }

  .orange_btn {
    width: 133px;
  }

  .green_btn {
    width: 133px !important;
  }

  .center_div_main_relation {
    margin-top: 32px;
  }

  .green_btnsign {
    width: 111px !important;
    height: auto !important;
  }


  .btn_div_upload .btn {
    font-size: 11px;
  }

  .box_white_inner .priceinn {
    text-align: left !important;
  }

  .width_reduse h3 {
    font-size: 9.5px !important;
  }

  .white_section_box h4 {
    font-size: 13px;
  }

  .foam_inner_box7 {
    margin-top: 321px !important;
  }

  .navbar-brand img {
    width: 230px !important;
  }


}

@media (max-width: 375px) {
  .size_box {
    width: 350px !important;
  }

  .margin_achivment .foam_inner_box {
    width: 360px;
    margin-top: 23px;
    margin-right: 0px !important;
  }

  .line_logo {
    width: 200px !important;
    margin-top: 10px;
  }

  .success_img {
    height: 370px;
  }

  .success .center_div_contain {
    margin-top: -57px;
  }

  .success .btn_section a .green_btn {
    position: relative;
    top: 5px;
  }

  .alert-heading {
    color: inherit;
    padding: 0;
    margin: 0;
    width: 231px;
    font-size: 11px;
  }

  .brown_box {
    padding: 7px 7px;
  }

  .inner_box_achivment {
    width: 66%;
    margin-left: 74px;
  }

  .brown_box .heading_foam {
    font-size: 10px !important;
  }

  .achivment_strip {
    top: -6px !important;
  }

  .text_img_achievement {
    float: left;
    width: 100% !important;
  }

  .signupmarginmy {
    top: 125px !important;
  }

  .section_level_right ul li a {
    padding: 5px 8px;
    font-size: 11px;
    border-radius: 6px;
  }

  .total_amount h2 {
    font-size: 11px;
  }

  .balance_info_text p {
    padding-top: 0 !important;
    color: red;
    font-size: 12px !important;
    margin: 0px !important;
  }


  .center_div_contain {
    margin-top: -110px;
  }

  .center_div_main_relation .pinkstrip2price {
    top: -20px !important;
  }

  .size_box2 {
    width: 350px !important;
  }

  .bottom_section_popup p {
    padding-top: 0;
  }

  .right_div {
    width: 142px;
  }

  .pinkstrip2 {
    top: -18px !important;
  }

  .box_white_inner h3 {
    font-size: 11px;
  }

  .box_white_inner h6 {
    font-size: 10px;
    color: #ed1c24;
    text-align: left;
    margin-bottom: 0;
  }

  .center_div_main_relation img {
    width: 340px;
  }

  .foam_inner_box2 {
    width: 300px !important;
    margin: 0 auto;
  }

  .sign_strip_top {
    top: -22px;
    width: 224px;
  }

  .foam_section {
    padding: 0px 75px;
  }

  .center_div_contain {
    margin-top: -90px;
  }

  .logo_section img {
    width: 217px !important;
  }

  .mylogo {
    width: 200px !important;
  }

  .big_title {
    font-size: 19px;
  }

  .small_title {
    font-size: 17px;
    margin-bottom: 0;
  }

  .midium_title {
    font-size: 18px;
  }

  .margin_big {
    margin-bottom: 0px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -74px !important;
  }

  .pink_strip_top img {
    width: 198px !important;
  }

  .pink_strip_top {
    top: -15px;
  }

  .margin_2_extra {
    margin-top: -110px !important;
  }

  .orange_btn {
    width: 100px;
  }

  .green_btn {
    width: 120px !important;
  }

  .center_div_main_relation {
    margin-top: 25px;
  }

  .buttons {
    margin: 0px 9px;
  }

  .coin {
    transform: scale(.8) !important;
  }

}



@media (max-width: 360px) {
  .size_box {
    width: 340px !important;
  }

  .coin {
    transform: scale(.7) !important;
  }

  .line_logo {
    width: 180px !important;
    margin-top: 0px;
  }

  .success .center_div_contain {
    margin-top: -57px;
  }

  .success .btn_section a .green_btn {
    position: relative;
    top: 5px;
  }


  .margin_achivment {
    top: 157px !important;
  }

  .center_div_main_relation img {
    width: 340px;
  }

  /* .sign_strip_top {
    top: -22px;
    width: 224px;
} */
  .foam_section {
    padding: 0px 75px;
  }

  .center_div_contain {
    margin-top: -90px;
  }

  .logo_section img {
    width: 200px !important;
  }

  .mylogo {
    width: 200px !important;
  }

  .big_title {
    font-size: 19px;
  }

  .small_title {
    font-size: 17px;
    margin-bottom: 0;
  }

  .midium_title {
    font-size: 18px;
  }

  .margin_big {
    margin-bottom: 0px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -74px !important;
  }

  .pink_strip_top img {
    width: 198px !important;
  }

  .pink_strip_top {
    top: -15px;
  }

  .margin_2_extra {
    margin-top: -110px !important;
  }

  .orange_btn {
    width: 100px;
  }

  .green_btn {
    width: 100px !important;
  }

  .center_div_main_relation {
    margin-top: 60px;
  }

  .buttons {
    margin: 0px 9px;
  }

}


@media (max-width: 320px) {
  .size_box {
    width: 290px !important;
  }

  .inner_box_achivment {
    width: 58%;
    margin-left: 91px;
  }

  .box_text h3 {
    font-size: 12px;
    margin-right: 5px;
  }

  .brown_box .heading_foam {
    font-size: 10px !important;
    line-height: initial;
  }

  .profile_bottom_section {
    float: left;
    width: 50%;
  }

  .profile_div_bottom {
    margin-bottom: 0px;
    margin-left: 80px;
  }

  .sizebox5 {
    height: 388px !important;
  }

  .price_box_inn {
    float: left;
    width: 42% !important;
  }

  .btn_right_section_price {
    margin-top: 0;
  }

  .text-left {
    float: left;
    width: 100%;
  }

  .right_div {
    width: 100%;
    margin: 0;
    margin-top: 4px;
  }

  .modal2_div {
    top: 63px !important;
  }

  .text_box_modal {
    position: absolute;
    top: 126px;
    left: 31px;
    width: 75%;
  }

  .setmodal {
    height: 490px;
  }

  .size_box2 {
    width: 300px !important;
  }

  .foam_inner_box2 {
    width: 257px !important;
    margin: 0 auto;
  }

  .margin_misson {
    margin-top: 40px !important;
  }

  .logo_section img {
    width: 200px !important;
  }

  .center_div_main_relation img {
    width: 290px;
  }

  .info {
    font-size: 10px;
  }

  /* .sign_strip_top {
    top: -22px;
    width: 224px;
} */
  .login_inner_divm {
    margin-top: -119px !important;
  }

  .foam_section {
    padding: 0px 100px;
  }

  .center_div_contain {
    margin-top: -90px;
  }

  .foam_inner_div .mb-4 {
    margin-bottom: .8rem !important;
  }

  .mylogo {
    width: 200px !important;
  }

  .big_title {
    font-size: 16px;
  }

  .small_title {
    font-size: 15px;
    margin-bottom: 0;
  }

  .midium_title {
    font-size: 16px;
  }

  .margin_big {
    margin-bottom: 0px !important;
  }

  .btn_section_2 {
    position: relative;
    top: -84px !important;
  }

  .pink_strip_top img {
    width: 198px !important;
  }

  .pink_strip_top {
    top: -17px;
  }

  .margin_2_extra {
    margin-top: -126px !important;
  }

  .orange_btn {
    width: 100px;
  }

  .green_btn {
    width: 100px !important;
  }

  .center_div_main_relation {
    margin-top: 14px;
  }

  .one_third_box2 {
    height: 524px;
  }

  .buttons {
    margin: 0px 9px;
  }

}




@media(max-width: 2500px) and (min-width: 767px) {

  .desktop_div {
    display: block;
  }

  .monile_header {
    display: none;
  }

}

@media(max-width: 768px) and (min-width: 250px) {

  .desktop_div {
    display: none;
  }

  .monile_header {
    display: block;
  }

  header {
    float: left;
    width: 100%;
    background-color: rgba(197, 221, 239, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
  }

  .navbar-brand img {
    width: 270px;
    margin: 0 !important;
    padding: 0 !important;
  }

  .btn_icon_section {
    float: left;
    position: absolute;
    left: -8px;
    top: -2px;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-left: 10px;
  }

  .text-section_btn {
    padding: 0px 24px;
    height: 42px;
    padding-left: 59px;
  }

  .text-section_btn {
    width: 206px;
  }

  .navbar-nav .nav-item {
    margin-bottom: 15px;
  }

  .btn_icon_section img {
    width: 45px;
  }

  .profile_image_btn {
    width: 49px;
    height: 49px;
    border: 2px solid #602904;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    top: -5px;
  }

}


.ticket_box_inner img {
  object-fit: contain;
  background: #fff;
}


.modal-backdrop {
  z-index: 99999999 !important;
}

.modal {
  z-index: 999999999;
}