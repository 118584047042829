.questionsContainer {
  /* border-radius: 10px; */
  height: 100%;
  width: 100%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  overflow: auto;
}

.questionTitle {
  background-color: #445eaa;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 100%;
  padding: 2% 2%;
  min-height: 15%;
  max-height: fit-content;
  color: white;
  font-size: clamp(16px, 2.5vh, 24px);
  font-family: "Montserrat";
  font-weight: bolder;
}

.questionChoice {
  margin-top: 2%;
  background-color: #445eaa;
  align-content: center;
  flex: 2;
  padding-block: 3%;
  padding-inline: 2%;
  width: 100%;
  justify-content: space-evenly;
}

.customTypography {
  color: white;
  font-size: clamp(14px, 2vh, 22px) !important;
  font-family: "Montserrat" !important;
  text-align: left;
}

.submitContainer {
  display: flex;
  justify-content: center;
  background-color: #445eaa;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  width: 100%;
  padding-bottom: 2%;
}

.submitBtn {
  width: 150px;
}

@media (orientation: portrait) and (max-width: 500px) {

  .questionsContainer {
    margin-bottom: 9%;
    padding-block: 0;
    padding-inline: 0;
  }

  .questionTitle {
    font-size: clamp(16px, 4.5vw, 24px);
    padding: 5%;
    min-height: max-content;
  }

  .customTypography {
    padding-bottom: 1.5vh;
    font-size: 14px !important;
    font-family: "Montserrat" !important;
  }

  .submitBtn {
    width: 100px;
    margin-bottom: 3%;
  }
}

@media (orientation: portrait) and (min-width: 501px) {
  .questionTitle {
    font-size: 30px;
    padding: 5%;
    min-height: max-content;
  }

  .customTypography {
    font-size: clamp(18px, 3.5vw, 25px) !important;
  }
}

@media (orientation: landscape) {

  @media (max-width: 1024px) and (max-height: 601px) {
    .questionsContainer::-webkit-scrollbar {
      width: 20px;
    }

    .questionsContainer::-webkit-scrollbar-thumb {
      background: #445eaa;
      border: 4px solid rgba(0, 0, 0, 0);
      /* Transparent border */
      background-clip: padding-box;
      /* Clip background within padding area */
      border-radius: 9999px;
      /* Rounded corners */
    }

    .questionTitle {
      font-size: clamp(14px, 1.75vh, 20px);
      min-height: max-content;
      padding: 5%;
    }

    .customTypography {
      padding-bottom: 1.5vh;
      font-size: clamp(12px, 1.5vh, 20px) !important;
      font-family: "Montserrat" !important;
    }

    .submitBtn {
      width: 100px;
      margin-bottom: 3%;
    }
  }

  @media (max-height: 550px) {
    .questionTitle {
      font-size: 14px;
    }

    .customTypography {
      font-size: 12px;
    }

  }

}