@import '../../../responsive';
@import "../../../index.scss";

.questPage {
  background: url("#{$S3-PUBLIC-IMAGES}/website-background.png");
  background-size: cover;
  width: calc(var(--vw, 1vw) * 100);
  height: calc(var(--vh, 1vh) * 90);
}

.glassBg {
  background: hsl(var(--brand-500), 70%);
  border-radius: 50px;
  border: 3px solid white;
}

.boardWrapper {
  max-width: 1280px;
  margin: auto;
  margin-top: calc(var(--vh, 1vh) * 10);
  max-height: calc(var(--vh, 1vh) * 90);
  height: 100%;
  display: flex;
  padding: 1em;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @include mobile {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    padding: 1em;
    max-height: calc(100vh - 2em);
    max-width: calc(100vw - 2em);
    margin: auto;
    margin-top: calc(var(--vh, 1vh) * 10);
    align-items: stretch;
    justify-content: stretch;
  }
}

.questBoard {
  position: relative;
  flex: 1;
  height: calc(100% - 2em);
  padding: 1em;
}

.treasureImage {
  width: auto;
  height: 25%;
  max-height: 200px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.sideBoard {
  position: relative;
  width: 250px;
  height: calc(100% - 2em);
  font-size: 1em;

  @include desktop {
    width: 300px;
  }

  @include mobile {
    position: relative;
    width: 100%;
    flex: 1;
    height: auto;
    font-size: .75em;
  }
}

.sideBoardContent {
  padding-top: 2em;
  padding-left: 1em;
  color: white;
  height: 100%;
}

.sideBoardStats {
  height: 60%;
  overflow: auto;
  font-size: .9em;
}

.sideBoardStatsGroup {
  display: flex;
  justify-content: space-between;
  padding-inline: 12px;
  padding-block: 6px;
  align-items: center;

  p {
    line-height: 1.2;
  }
}

.tilesWrapper {
  display: grid;
  z-index: 0;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
}

.tile {
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 120px;
  transition: all;
  perspective: 1000px;
  z-index: 0;
  border: 3px solid transparent;

  &:hover {
    opacity: 1;
    z-index: 100;

    .tileModal {
      opacity: 1;
      pointer-events: all;

      @include mobile {
        display: none;
      }
    }
  }
}

.tileInner {
  z-index: 0;
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;

  @include mobile {
    position: static;
  }
}

.mobileTileModal {

  @include mobile {
    .tileModal {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      opacity: 1;
      pointer-events: all;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .tileModalClose {
        display: block;
        position: absolute;
        // margin-top: -35%;
        top: 1.75em;
        right: 0;
        color: white;
        font-size: 3rem;
        width: 1em;
        height: 1em;
        z-index: 10000;
      }

      .tileModalBackdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);
      }

      .tileModalContent {
        z-index: 1000;
      }
    }
  }
}

.tileModal {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 20%;
  z-index: 1000;
  transition: 0.5s all;

  @include mobile {
    display: none;
  }

  .tileModalClose {
    display: none;
  }

  .tileModalContent {
    position: relative;
    width: 300px;
    height: 180px;
  }
}

.collectedImage,
.lockedImage,
.unlockedImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  backface-visibility: hidden;
}

.lockedImage {}


.unlockedImage {
  transform: rotateY(180deg);
}

.collectedImage {
  backface-visibility: hidden;
}

.selectedTile {
  border: 3px solid hsl(var(--brand-200));
  border-radius: 1rem;

  .tileInner {
    transform: rotateY(180deg);
  }

  &:not(.completedTile) {
    .tileInner>img {
      filter: grayscale(100%);
    }
  }

}

.completedTile {
  &:hover {
    transition: 0.5s filter;
    filter: drop-shadow(0 0 10px #fff);
  }

  transition: 0.5s filter;
  filter: drop-shadow(0 0 50px #fff);

}

.collectedTile {
  .tileInner {
    animation: rotateUnlocked 1s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }
}

@keyframes rotateUnlocked {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(720deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes shootTop {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100vh);
  }
}

.whiteScrollBar::-webkit-scrollbar-thumb {
  background: #fff;
}

.whiteScrollBar::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

@media (orientation: landscape) and (max-height: 600px) {
  .questPage {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
  .boardWrapper{
    margin-top: 64px;
    padding-top: 0;
  }
  
}