@import "../../../responsive.scss";

.contentWrapper {
  max-height: 70%;
  margin-top: 5%;
}

.centerContentContainer{
  height: 100%;
}

.form_content {
  height: 80%;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (max-width: 480px) {
  .form_content{
    height: 75%;
  }
}

@media (max-width: 376px) {
  .form_content{
    height: 60%;
  }
}

@media (orientation: landscape) and (max-height: 650px) {
  .contentWrapper{
    margin-top: calc(var(--vh, 1vh) * 20);
  }
  .centerContentContainer {
    height: calc(var(--vh, 1vh) * 70);
  }

  .form_content{
    height: 60%;
  }
}

