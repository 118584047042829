input::placeholder {
  color: #8193d4 !important;
}

input {
  color: #445eaa;
}

.textColor {
  color: #445eaa !important;
}

.w100 {
  width: 100%;
}

.completed {
  justify-content: center;

  .mainText {
    font-size: 2.5rem;
  }
}

.mainText {
  color: #415aa4;
  font-weight: 700;
  font-size: 2rem;
}

.subText {
  color: #415aa4;
  font-weight: 700;
  font-size: 1rem;
}

.data {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.selectInputContainer {
  width: 100%;

  select {
    border-radius: 12px;
    border: 2px solid var(--theme-color-bold);
    padding: 0.7rem;
    color: #445eaa;
    font-weight: 600;
  }
}

.inputField {
  width: 100%;

  input,
  input:focus {
    color: #445eaa;
    font-weight: 600;
  }
}

.taskInputField {
  display: flex;
  align-items: flex-end;

  .taskUnderscore {
    white-space: pre;
    font-weight: 900;
    color: #445eaa;
  }

  input,
  input:focus {
    color: #445eaa;
    font-weight: 600;
  }
}

.chooseImgContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selectImgText {
  padding-bottom: 2rem;
}

.noImgSelectedText {
  font-size: 1.5rem;
  color: #415aa4;
  font-weight: 700;
}

.selectedImgWrapper {
  height: 50%;
  display: flex;
  align-items: center;
}

.selectedImg {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
}
.closeIcon {
  position: absolute;
  left: 80%;
  z-index: 3;
}

.selectedImgsContainer,
.imgBank {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  padding-bottom: 2rem;
  min-width: 25%;
  max-width: 100%;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}

.imgTag {
  max-height: 100px;
}

.imgNameInput {
  padding-top: 1rem;
  input {
    padding: 6px;
  }
}

.selectedHighlight {
  box-shadow: 0 0 10px 4px darkslateblue, 0 0 20px darkslateblue;
}

.imageUploadContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.imageNameInputContainer {
  input {
    padding: 6px 12px !important;
    border-radius: 5px;
  }
}

.icon {
  color: #445eaa !important;
}

.fileUrl:hover {
  background-color: #e9e9e9; /* Slight background change on hover */
}

.spacer {
  color: transparent !important;
  z-index: -1;
}

.inputField_taskCompleted {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.btn {
  background-color: #445eaa;
  color: white;
  font-weight: 600;
}

.continueBtn {
  padding: 0.5rem 1.15rem;
  font-size: 1.15rem;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.deleteBtnContainer {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.display {
  display: flex;
}

.hidden {
  display: none;
}

.err {
  select {
    border-color: red;
  }

  .fileUrl {
    border: 2px solid red;
  }
  input {
    border-color: red;
  }
}

.infoTableContainer > * {
  background-color: #415aa400 !important;

  div {
    background-color: #415aa400 !important;
    color: #445eaa;
    font-weight: 700;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  color: #445eaa;
  border-bottom: 2px solid #445eaa;
  font-size: 1rem;
  font-weight: 700;
}

@media (max-width: 1150px) {
  .selectedImgsContainer,
  .imgBank {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media (max-width: 520px) {
  .selectedImgsContainer,
  .imgBank {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  .selectedImgsContainer {
    gap: 1rem;
  }

  .imgTag {
    height: 75px;
  }
  .closeIcon {
    left: 70%;
    top: 10%;
  }
}

@media (orientation: portrait) {
  @media (max-width: 950px) {
    .mainContainer {
      width: 90vw;
      padding: 2.5rem 2.5rem 3.2rem 2.5rem;
    }

    .mainText {
      font-size: 1.5rem;
    }

    .subText {
      font-size: 0.75rem;
    }

    .selectInput {
      padding: 0.5rem !important;
    }

    .btn {
      font-size: 0.95rem;
    }

    .imgUploadLabel {
      padding: 0.2rem 0.5rem;
    }

    .imgContainer {
      padding: 0.5rem;
    }
  }
}

@media (orientation: landscape) {
  @media (max-height: 650px) {
    .mainText {
      font-size: 1.7rem;
      padding-bottom: 0.75rem;
    }

    .subText {
      font-size: 0.9rem;
      padding-bottom: 0.75rem;
    }

    .selectInput {
      padding: 0.35rem !important;
    }

    .imgTag {
      height: 75px;
    }

    .btn {
      font-size: 0.95rem;
    }
  }
}
