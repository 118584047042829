.scrollbar::-webkit-scrollbar {
  width: 10px;
  margin-left: 5px !important;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--theme-color-bold);
  border-radius: 10px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: var(--theme-color-bold);
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--theme-color-bold);
}