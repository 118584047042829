.info_table_container {
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 15px;
}

.table_title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1.5rem;
  margin: 10px 0 10px 15px;
  color: #415aa4;
  font-weight: 700;
}

.printBtnContainer {
  margin-right: 15px;

  .printBtn {
    color: #415aa4;
    font-weight: 700;
    font-size: 1.25rem;
  }

  .printBtn:hover {
    color: #dce9f5;
  }
}

.info_table {
  width: 100%;
  /* margin: 0 auto; */
  background-color: #f4f4f4;
  border-radius: 0 0 15px 15px;
  overflow: visible;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: inset 0 -2px 0 0 #415aa4;
  border-top: 3px solid #415aa4;
}

.divider {
  border-right: 3px solid #415aa4;
}

.row {
  display: flex;
  border-bottom: 2px solid #415aa4;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
}

.row:hover {
  background-color: #dce9f5 !important; /* Light blue color */
  transition: background-color 0.3s ease;
}

.columns:first-child > .row:last-child {
  border-bottom: none;
}

.headerContainer {
  color: #415aa4;
  font-weight: 700;
  padding: 10px 0 10px 15px;
  height: 100%;
  max-width: 40%;
}

.header {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 10px;
}

.dataContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: normal;
}

.data {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
}

.spacer {
  width: 60px;
}

.edit {
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #415aa4;
  font-weight: 500;
  transition: color 0.3s;
  word-wrap: none;
  word-break: keep-all;
}

.edit:hover {
  cursor: pointer;
  color: #001f4d;
}

.inputField {
  scale: 1;
  z-index: 11;
}

.blue_border {
  border: 2px solid #415aa4;
  outline: #415aa4;
  border-radius: 5px;
}

.blue_border:focus {
  outline: #415aa4;
}

.errorContainer {
  position: absolute;
  z-index: 10;
  transform: translateY(2%);
}

.error {
  margin-top: 4rem;
  background-color: #f8d7da;
  color: #721c24;
  padding: 5px;
  border: 1px solid #f5c6cb;
  border-radius: 3px;
  white-space: nowrap;
}

@media print {
  .printBtnContainer {
    display: none;
  }
}

@media (max-width: 950px) {
  .table_title {
    font-size: 1.25rem;
  }

  .row {
    font-size: 0.85rem;
  }
}

@media (max-width: 585px) {
  .info_table {
    grid-template-columns: 1fr;
  }
}
