.wrapper{
  scale: 1
}

@media (min-aspect-ratio: 1.7) {
  .wrapper{
    scale: .75
  }
}

@media (max-aspect-ratio: 0.75) and (min-aspect-ratio: 0.475) and (max-width: 600px) {
  .wrapper{
    scale: .75
  }
}