.createProcessSelectionTexts {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 2rem;
}

.createProcessSelectionBtns {
  display: flex;
  gap: 2rem;
  padding-bottom: 4rem;
}
