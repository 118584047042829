@import '../../../responsive';

/* theme.css */
:root {
  --theme-color: rgb(68, 94, 170);
  --theme-color-bold: rgb(0, 0, 158);
  --theme-color-sec: rgb(225, 236, 254);
  --theme-font-family: 'montserrat'
}

.themeColor {
  color: var(--theme-color);
}

.themeColorSec_bkg {
  background-color: var(--theme-color-sec);
}

.themeFontFamily {
  font-family: var(--theme-font-family);
}

.mainsection {
  /* background: url(../../assets/images/userSite/gameboard.png) no-repeat; */
  background-position: center;
  background-size: 100% 100%, cover;
  background-repeat: no-repeat;
  margin-top: calc(var(--vh, 1vh) * 10);
  height: calc(var(--vh, 1vh) * 90);
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.missions_container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.imageRow {
  position: fixed;
  margin-left: 2%;
  padding-block: .5%;
  padding-inline: 1%;
  width: fit-content;
  max-width: 45%;
  bottom: 0;
  background-color: var(--theme-color);
  border-top-left-radius: clamp(10px, 3dvh, 3dvh);
  border-top-right-radius: clamp(10px, 3dvh, 3dvh);
  z-index: 1000;

  p {
    font-size: 1em;
  }
}

.imageRowScroll {
  display: grid;
  grid-auto-flow: column;
  gap: 2em;
}

.imageRowImg {
  cursor: pointer;
  height: 50%; /* Sets height */
  width: 100%; /* Image takes full width */
  object-fit: contain; /* Ensures the entire image fits within, preserving aspect ratio */
}

.chmission_img_container{
  height: 100%;
  width: max-content;
  max-width: 70px;
  text-align: center;
}

.chmission_img {
  transition: all 0.3s ease;
}

.chmission_img:hover {
  transform: translateY(-10px);
}

.comission_img {
  position: absolute;
  width: 4%;
  height: 7%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.comission_img:hover {
  transform: scale(1.1);
}


// Modal 
.mission_modal_dialog {
  width: 50dvw;
  height: 50dvh;

  @media (max-height: 650px) {
    width: 65dvw;
    height: 70dvh;
  }
}

.close_btn {
  width: min(5vw, 5vh);
  height: min(5vw, 5vh);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #445eaa;
  color: white;
  font-size: min(5vw, 5vh);
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.modal_content {
  background-image: url(../../../assets/images/CenterBox_lg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0%;
  padding: 6%;
  height: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Montserrat";
  // border: 1px solid red;

}

.modal_details {
  background-color: #445eaa;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 2% 2%;
  // flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  // max-height: 35%;
}

.modalCat {
  font-size: 5dvh;
  font-weight: 800;

  // @media (max-height: 650px) {
  //   font-size: 1.25em;
  // }
}

.modalTask {
  font-size: 2.5dvh;
  font-weight: 600;
  padding-top: 6px;

  // @media (max-height: 650px) {
  //   font-size: 2dvh;
  // }
}

// Completed Modal
.model_result {
  flex: 2;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #445eaa;
  max-height: 63%;
  font-size: 5dvh;
  font-weight: 800;

  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;


  img {
    width: auto;
    height: 15dvh;
  }

  // @media (max-height: 650px) {
  //   font-size: 1.25em;
  // }
}

// Choose Modal
.modal_choose_task_image {
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-content: center;
  padding-block: 1%;
  height: 100%;
  width: 100%;
  overflow: auto;
  place-items: center;

  img {
    height: 75%;
    // max-height: 100px;
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
  }

}

.modal_choose_task_message {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-block: 1%;
  height: 100%;
  width: 100%;

  img {
    height: 35%;
    // max-height: 65px;
    padding: 8px;
    cursor: pointer;
  }

  .textField {
    border-radius: 5px;
    width: 75%;
  }
}

.soloTaskButtons {
  font-size: 75%;
  font-weight: bold;
  border-radius: 20px;
  width: 8em;
  height: 3.5em;
  margin: 10px;
}

.soloTaskDeselected {
  background-color: white;
  color: var(--theme-color-bold);
}

.soloTaskSelected {
  background-color: yellow;
  color: var(--theme-color);
}

.character {
  position: absolute;
  width: 12%;
  height: 35%;
  z-index: 100;
  transition: all 0.5s ease;
}

@media (orientation: landscape) and (max-width: 1024px) and (max-height: 601px) {

  .missions_container {
    width: 100%;
    margin-top: 64px;
    height: 100%;
  }

  .mainsection {
    background-position: center center;
    background-size: 100% 100%, cover;
    margin-top: 0;
    height: calc(100vh - 64px);
  }

  .imageRow {
    p {
      font-size: .55em;
    }
  }

  .chmission_img_container{
    max-width: 55px;
  }
}


@media (orientation: portrait) {

  .missions_container {
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc(var(--vh, 1vh) * 100);
    height: calc(var(--vw, 1vw) * 100);
    transform: translate(-50%, -50%) rotate(-90deg);
    transform-origin: center center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mainsection {
    margin: 0;
    margin-right: calc(var(--vh, 1vh) * 10);
    display: flex;
    // justify-content: center;
    align-items: center;
    height: calc(var(--vw, 1vw) * 100);
    width: calc(var(--vh, 1vh) * 90);
  }

  .imageRow {
    margin-left: 5%;
    max-width: 40%;

    p {
      font-size: small;
    }
  }

  .imageRowImg {
    cursor: pointer;
    height: 12vw;
  }


  .mission_modal_dialog {
    width: 65dvh;
    height: 80dvw;
    padding-inline: 5%;
    transform: rotate(-90deg);
  }

  .modalCat {
    font-size: 5dvw;
  }

  .modalTask {
    font-size: 3dvw;
  }

  .model_result {
    font-size: 5dvw;

    img {
      width: auto;
      height: 25dvw;
    }
  }

  // Choose Modal
  .modal_choose_task_image {

    img {
      height: 15dvw;
      width: auto;
      padding: 5px;
    }

  }

  .modal_choose_task_message {
    img {
      height: 15dvw;
      max-height: 30%;
      width: auto;
      padding: 5px;
    }
  }

  .soloTaskButtons {
    width: 80%;
    min-height: 15dvw;
    margin: 10px;
  }

  @media (max-width: 500px) {
    .modal_choose_task_image {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }

}

@media (min-width: 960px) and (max-width: 1045px) {
  .imageRowScroll {
    display: flex;
    gap: 2em;
    overflow-x: auto;
  }

  .imageRowScroll > * {
    flex: 0 0 auto;
  }
}


.whiteScrollBar::-webkit-scrollbar-thumb {
  background: #fff;
}

.whiteScrollBar::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}