.fullPageScroll {
  height: 100dvh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  background-color: #445eaa;
}

.pageSection {
  position: relative;
  height: 100dvh;
  scroll-snap-align: start;
  scroll-behavior: smooth;
}

.scrollablePageSection {
  position: relative;
  min-height: 100dvh;
  scroll-snap-align: start;
  scroll-behavior: smooth;
}

.headerContainer{
  min-height: 64px;
  position: relative;
  z-index: 1000;
}

.dynamicContainer {
  display: flex;
  flex-direction: column;

  .dynamicContainerContent {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}

.arrowContainer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.arrow {
  font-size: 2.5rem !important;
  color: #fff;
  filter: contrast(1) ;
  cursor: pointer;
}

.tables {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 2rem;
}

.tables > div {
  width: 100%;
  /* height: 100%; */
}
