.mainContainer {
  // background-color: aliceblue;
  background-color: white;
  border-radius: 1rem;
}

.headerText {
  color: #445eaa;
  font-size: 2.5rem;
  font-weight: 700;
}

.border {
  border-color: #445eaa;
}

.text {
  color: #445eaa;
  font-size: 1.45rem;
}

.btn {
  color: white;
  font-size: 1.35rem;
  background-color: #445eaa;
}

@media (max-width: 650px) {
  .headerText {
    font-size: 2.25rem;
  }
  .text {
    font-size: 1.25rem;
  }
  .btn {
    font-size: 1.15rem;
  }
}

@media (max-width: 550px) {
  .headerText {
    font-size: 1.75rem;
  }
  .text {
    font-size: 1rem;
  }
  .btn {
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  .text {
    font-size: 0.75rem;
  }
  .btn {
    font-size: 0.75rem;
  }
}
