.container {
  background-color: rgba(68, 94, 170, 0.50);
  border-radius: 10%;
  padding: 5%;
  height: 100%;
  width: 100%;
  display: flex;
}

.wrapper {
  background-color: rgba(68, 94, 170, 0.75);
  border-radius: 5%;
  padding: 5%;
  height: 100%;
  width: 100%
}