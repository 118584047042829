@import '../../../responsive';

.basic_btn {
  font-family: var(--theme-font-family) !important;
  background: var(--theme-color) !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  margin: .5em;
  padding: .5em 1em;
  font-size: 1.5em;

  @include mobile {

    padding: .5em .75em;
    font-size: 1em;

  }
}

.heroSection {
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 350px;
  background-size: cover;

  @include tablet {
    height: 700px;
  }

  @include desktop {
    height: 500px;
  }
}

.heroSectionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.girlHeader {
  width: 5rem;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;

  @include tablet {
    width: 15rem;
    align-self: end;
    position: static;
  }

}

.boyHeader {
  width: 5rem;
  left: 0;
  bottom: 0;
  height: auto;
  position: absolute;
  z-index: 1;

  @media (min-width: 768px) {
    width: 15rem;
    align-self: end;
    position: static;
  }

}

.heroInfo {
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  max-width: 500px;
  align-items: center;
  text-align: center;
  gap: 2rem;
  margin-inline: 1rem;

  @include tablet {
    max-width: 50%;
  }

  @include mobile {
    max-width: 50%;
  }
}

.heroInfo h1 {
  color: white;
  text-shadow: 2px 2px black;
  font-size: 36px;

  @include mobile {
    font-size: larger;
  }
}

.heroInfoLogo {
  width: 7rem;
  height: auto;

  @include tablet {
    width: 10rem;
  }
}

.coreValuesSection {
  height: 200px;
  background: hsl(var(--brand-100));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  gap: 1em;

  @include tablet {
    height: 300px;
  }

  @include desktop {
    padding: 3em;
    height: 500px;
  }

  .header {
    font-weight: bold;
    font-size: 1em;
    color: hsl(var(--brand));
    position: relative;

    @include tablet {
      font-size: 1.5em;
    }


    @include desktop {
      font-size: 2.5em;
    }

    &:after {
      content: '';
      position: absolute;
      width: 15vw;
      left: 50%;
      bottom: -5px;
      transform: translateX(-50%);
      height: 2px;
      background: hsl(var(--brand));
    }
  }

  .values {
    flex: 1;
    display: flex;
    gap: 1em;
    align-items: center;

    @include desktop {
      gap: 2em;
    }

    img {
      width: 120px;
      height: auto;

      @include tablet {
        width: 200px;
      }

      @include desktop {
        width: 300px;
      }
    }
  }
}


.howItWorksSection {
  // height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  gap: 1em;

  @include tablet {
    height: 300px;
  }

  @include desktop {
    height: 500px;
    gap: 3em;
  }

  h1 {
    font-weight: bolder;
    font-size: 1em;
    color: hsl(var(--brand));
    position: relative;

    @include tablet {
      font-size: 1.5em;
    }

    @include desktop {
      font-size: 2.5em;
    }

    &:after {
      content: '';
      // z-index: 10;
      position: absolute;
      width: 15vw;
      left: 50%;
      bottom: -5px;
      transform: translateX(-50%);
      height: 2px;
      background: hsl(var(--brand));
    }
  }

  p {
    text-align: center;
    color: hsl(var(--brand));
    font-size: .7em;
    // font-weight: lighter;
    max-width: 600px;

    @include tablet {
      font-size: 1em;
    }

    @include desktop {
      max-width: 900px;
      font-size: 2em;
    }
  }
}

.whySection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  gap: 2em;

  h1 {
    font-weight: bold;
    font-size: 1em;
    color: hsl(var(--brand));
    position: relative;

    @include tablet {
      font-size: 1.5em;
    }


    @include desktop {
      font-size: 2.5em;
    }

    &:after {
      content: '';
      // z-index: 10;
      position: absolute;
      width: 15vw;
      left: 50%;
      bottom: -5px;
      transform: translateX(-50%);
      height: 2px;
      background: hsl(var(--brand));
    }
  }

  .whySectionContainer {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    width: 100%;
    gap: 2em;
    // align-items: end;

    img {
      width: 100%;
      height: auto;
      // padding: 18px;

      @include tablet {
        width: 100%;
      }

      @include desktop {
        width: 100%;
      }
    }

    .achievement {
      align-self: start;
    }
  }

  .whySectionRow {
    display: flex;
    flex-direction: row;
    // gap: 2em;
    padding-block: 2%;
    width: 100%;
    align-items: center;
    // justify-items: center;
    // justify-content: space-between;
    // text-align: center;
    color: hsl(var(--brand));

    p {
      font-weight: bold;
    }

    .arrow {
      width: 30px;
      // height: auto;
      padding-inline: 5px;
      margin: auto;
    }

    @media (max-width: 995px) {
      text-align: center !important;
    }
  }

  .whySectionCol {

    @media (max-width: 995px) {
      flex-direction: column-reverse !important;

    }
  }

  .whySectionColRev {
    text-align: end;

    @media (max-width: 995px) {
      flex-direction: column !important;
    }
  }
}

.joinSection {
  float: left;
  position: relative;
  width: 100%;
  padding: 100px 0px;
  background-position: top center;
  background-size: cover;
  text-align: center;
  background-color: rgb(225, 236, 254);
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;

  h3 {
    font-family: 'montserrat';
    font-weight: bolder;
    color: hsl(var(--brand));
    position: relative;

    &:after {
      content: '';
      // z-index: 10;
      position: absolute;
      width: 15vw;
      left: 50%;
      bottom: -5px;
      transform: translateX(-50%);
      height: 2px;
      background: hsl(var(--brand));
    }
  }

  img {
    max-height: 50vh;
    margin: auto;

  }

  @media (max-width: 900px) {
    padding: 0px 0px;

    img {
      max-height: 40vh;
      margin-top: 50px;
    }

  }
}