@import "../../../index.scss";

.page {
  background: url("#{$S3-PUBLIC-IMAGES}/CenterBox_lg.png");
  background-size: 100% 100%;
  padding-inline: 8%;
  padding-block: 4%;
  height: 100%;
  width: 100%;
  /* min-width: 75%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.questionNumber {
  margin-top: 10px;
  color: #445eaa;
  /* font-size: 24px; */
  font-weight: bold;
}

@media (orientation: portrait) {
  .page {
    max-height: none;
    padding-inline: 12%;
  }

  .questionNumber {
    margin-top: 5%;
  }
}

@media (orientation: landscape) and (max-height: 550px) {
  .page {
    /* padding-inline: 0; */
  }
}