.mainText {
  color: #415aa4;
  font-weight: 700;
  font-size: 2rem;
}

.subText {
  color: #415aa4;
  font-weight: 700;
  font-size: 1rem;
}

.btn {
  background-color: #445eaa;
  color: white;
  font-weight: 600;
}

.continueBtn {
  padding: 0.5rem 1.15rem;
  font-size: 1.15rem;
}

@media (orientation: portrait) {
  @media (max-width: 950px) {
    .mainText {
      font-size: 1.5rem;
    }

    .subText {
      font-size: 0.75rem;
    }

    .btn {
      font-size: 0.95rem;
    }
  }
}

@media (orientation: landscape) {
  @media (max-height: 650px) {
    .mainText {
      font-size: 1.7rem;
      padding-bottom: 0.75rem;
    }

    .subText {
      font-size: 0.9rem;
      padding-bottom: 0.75rem;
    }

    .btn {
      font-size: 0.95rem;
    }
  }
}
