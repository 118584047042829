.adminContainer {
  width: 100%;
  height: 100%;
  padding: 5% 18%;
}

.bg {
  background: url(../../../../assets/images/campaign/mitzvah/kotel_bg.jpg)
    no-repeat;
  background-position: center;
  height: 100vh;
  overflow: hidden;
  background-size: 100% 100%, cover;
  height: 100%;
  position: relative;
  touchaction: none;
  transform: translate3d(0px, 0px, 0px);
}

.ach_banner {
  display: block;
  position: absolute;
  z-index: 10;
  top: -26%;
  left: 1%;
}

.ach_banner img {
  width: 40%;
  height: auto;
}

.statsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55%;
  width: 100%;
  /* margin-top: 13%; */
  margin-bottom: 5%;
}

.desktop_stats {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: scroll;
}

.desktop_stats::-webkit-scrollbar {
  display: none;
}

.desktop_stats {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.mobile_stats {
  display: none;
}

@media (orientation: portrait) {
  .adminContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10% 0;
  }
  .ach_banner {
    display: block;
    position: absolute;
    z-index: 10;
    /* top: -9%;
    margin-left: -1%; */
  }

  .ach_banner img {
    width: 50%;
    height: auto;
  }

  .statsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin-bottom: -5%;
  }

  .desktop_stats {
    display: none;
  }

  .mobile_stats {
    display: flex;
    padding-top: 5%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 13%;
    margin-right: 13%;
    height: 95%;
    overflow-y: scroll;
  }

  .mobile_stats::-webkit-scrollbar {
    display: none;
  }

  .mobile_stats {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@media (orientation: portrait) and (min-width: 550px) {
  .mobile_stats {
    padding-top: 0;
  }
}

@media (orientation: landscape) and (max-width: 940px) and (max-height: 600px) {
  .desktop_stats {
    justify-content: space-around;
  }
}
