@import "../../../index.scss";

.page {
  background: url("#{$S3-PUBLIC-IMAGES}/CenterBox_lg.png");
  background-size: 100% 100%;
  padding-inline: 15%;
  padding-block: 4%;
  padding-bottom: 7%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


@media (orientation: portrait) {
  .page {
    max-height: none;
    padding-inline: 12%;
  }

}

@media (orientation: landscape) and (max-height: 550px) {
  .page {
    padding-inline: 10%;

  }
}