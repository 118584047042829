@import "../../../../../responsive";

.sidebar {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0; // Change from left: 0 to right: 0
  z-index: 100;
  transition: transform 0.3s ease-in-out;
  pointer-events: none;

  @include tablet {
    display: block;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}

.content {
  position: absolute;
  right: 0; // Change from left: 0 to right: 0
  top: 0;
  max-width: 70%;
  height: 100%;
  transition: transform 0.3s ease-in-out;

  @include desktop {
    height: auto;
  }
}