.noneScrollContent {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.scrollContent {
  width: 100%;
}

.mainText {
  font-size: 2.5rem;
  color: #415aa4;
  font-weight: 700;
  white-space: pre-wrap;
}
.btnContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}
.btn {
  background-color: #445eaa;
  color: white;
  font-weight: 600;
}

.continueBtn {
  padding: 0.5rem 1.15rem;
  font-size: 1.15rem;
}

@media (orientation: portrait) {
  @media (max-width: 950px) {
    .btn {
      font-size: 0.95rem;
    }
  }

  @media (max-width: 550px) {
    .mainText {
      font-size: 2rem;
    }
  }
}

@media (orientation: landscape) {
  @media (max-height: 650px) {
    .mainText {
      font-size: 1.7rem;
      padding-bottom: 0.75rem;
    }

    .btn {
      font-size: 0.95rem;
    }
  }
}
