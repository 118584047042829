
.mainDiv{
  height: 100%;
  width: 100%;
}

@media (orientation: portrait) {
  .mainDiv{
    margin-left: 7vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    transform-origin: center center;
    width: calc(var(--vh, 1vh) * 100);
    height: 93vw;
  }
}

@media (orientation: landscape) and (max-width: 940px) and (max-height: 600px) {
  .mainDiv{
    height: calc(var(--vh, 1vh) * 95);
    width: 100%;
    margin-top: calc(var(--vh, 1vh) * 5);
  }
}