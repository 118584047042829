.continue_btn {
  font-family: var(--theme-font-family) !important;
  background: white !important;
  color: var(--theme-color) !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  margin: .5em;
  padding: .75em 1.25em;
  font-size: .90em;
}

.contentContainer{
  scale: 1;
}

@media (orientation: landscape) and (max-height: 650px) {
  .contentContainer{
    padding-top: calc(var(--vh, 1vh) * 25);
    scale: 0.85
  }
}