.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mainText {
  font-size: 2rem;
  font-weight: 700;
  color: #415aa4;
  padding-bottom: 1rem;
}

.addStudentForm {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.inputRow {
  display: flex;
  width: 100%;
  gap: 2rem;
}

.btnContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.submitBtn {
  color: #415aa4;
  font-size: 1.5rem;
  font-weight: 700;
  background-color: #415aa4;
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 8px;
}

.infoTableContainer > * {
  background-color: #415aa400 !important;

  .infoTableCustomClass {
    grid-template-columns: 1fr !important;
  }

  div {
    background-color: #415aa400 !important;
    color: #445eaa;
    font-weight: 700;
  }
}

@media (orientation: portrait) {
  .inputRow {
    flex-direction: column;
  }

  .btnContainer {
    padding-bottom: 1rem;
    padding-top: 2rem;
  }

  @media (max-width: 550px) {
    .mainText {
      font-size: 1.75rem;
    }

    .inputRow {
      flex-direction: column;
      gap: 1rem;
    }

    .btnContainer {
      padding-top: 0;
    }

    .submitBtn {
      font-size: 1.15rem;
      padding: 7px;
    }
  }
}

.basic_btnInv {
  font-family: var(--theme-font-family) !important;
  background: white !important;
  color: var(--theme-color) !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  margin: 0.5em;
  padding: 1em 2em;
  font-size: 1em;
}
