.mainDiv {
  position: absolute;
  display: inline-block;
  margin-left: 3%;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: end;
  font-family: sans-serif;
  font-size: clamp(12px, 1.1vw, 18px);
  font-weight: 900;
}

.stepsLeftMainDiv {
  display: flex;
  max-width: 30vw;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: rgb(255 255 255);
  padding-top: clamp(5px, 5px + 2vh, 3vh);
  padding-bottom: clamp(5px, 5px + 2vh, 3vh);
  padding-left: clamp(5px, 5px + 2vw, 15vw);
  margin-bottom: 4%;
}
.stepsLeftText,
.stepsLeftValue {
  white-space: nowrap;
  color: rgb(67 93 168);
}

.stepsLeftValue {
  box-sizing: border-box;
  padding-left: clamp(5px, 15px + 1vw, 5vw);
  padding-right: clamp(5px, 3vw, 60px);
}

.stepsTimeCounterMainDiv {
  border: 5px solid rgb(149 178 249);
  border-radius: 5px;
  background-color: #445eaa;
  margin-bottom: 6%;
}

.stepsTimeCounterTable {
  margin: 0;
}

.stepsTimeCounterTable * {
  font-family: sans-serif;
  font-size: clamp(12px, 1.1vw, 18px);
  font-weight: 900;
  color: rgb(255 255 255);
}

.stepsTimeCounterTable th {
  padding-bottom: 0;
  padding-top: clamp(2px, 0.4vw, 8px);
}

.stepsTimeCounterTable td {
  padding-bottom: clamp(2px, 0.4vw, 8px);
  padding-top: clamp(2px, 0.4vw, 8px);
}

.progressBarMainDiv {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50vw;
  width: clamp(20vw, 20px + 30vw, 35vw);
  margin-bottom: 2vh;
}

.progressBarText {
  font-family: sans-serif;
  font-size: clamp(13px, 1.5vw, 25px);
  font-weight: 900;
}

@media (orientation: portrait) {
  .flexContainer {
    height: 90vw;
    font-size: clamp(12px, 2vw, 20px);
  }
  .stepsLeftMainDiv {
    max-width: 35vh;
    padding-top: clamp(10px, 1.65vh, 20px);
    padding-bottom: clamp(10px, 1.65vh, 20px);
    padding-right: clamp(10px, 1vh, 20px);
  }
  .stepsTimeCounterTable * {
    font-size: clamp(13px, 2vw, 20px);
  }
  .stepsTimeCounterTable th {
    padding-top: clamp(2px, 1.25vw, 20px);
  }
  
  .stepsTimeCounterTable td {
    padding-bottom: clamp(2px, 2vw, 20px);
    padding-top: clamp(2px, 0.4vw, 8px);
  }
  .progressBarMainDiv {
    left: 40vh;
    width: clamp(20vh, 20px + 30vh, 35vh);
  }
  .progressBarText {
    font-size: clamp(12px, 2.2vh, 25px) ;
  }
}

@media (orientation: landscape) and (max-width: 940px) and (max-height: 600px) {
  .flexContainer{
    height: calc(var(--vh, 1vh) * 79);
    font-size: clamp(10px, 1.5vh, 15px);
  }
  .stepsTimeCounterTable * {
    font-size: clamp(10px, 1.5vh, 15px);
  }
  .progressBarText {
    font-size: clamp(12px, 4vh, 25px) !important;
  }
}