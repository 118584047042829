@font-face {
  font-family: "tintco_demoregular";
  src: url("../fonts/tintco_demo-webfont.woff2") format("woff2"),
    url("../fonts/tintco_demo-webfont.woff") format("woff"),
    url("../fonts/tintco_demo-webfont.ttf") format("truetype"),
    url("../fonts/tintco_demo-webfont.svg#tintco_demoregular") format("svg");
}

@keyframes drop {
  from {
    top: -100%
  }

  to {
    top: 0%;
  }
}

@keyframes portraitRotatedDrop {
  from {
    left: -100%
  }

  to {
    left: 0%;
  }
}

@keyframes progressAnimation {
  from {
    width: 0%
  }

  to {
    width: 100%;
  }
}

@keyframes hoveringCloud {
  from{transform: translateY(0)}
  to{transform: translateY(-20%)}
}

.dragZone {
  cursor: pointer;
  background-color: hsl(224, 53%, 59%);
  .dragZoneCloud{animation: hoveringCloud 1s linear infinite alternate;}
  
}

:root {
  --theme-color: rgb(68, 94, 170);
  --theme-color-bold: rgb(0, 0, 158);
  --theme-color-sec: rgb(225, 236, 254);
  --theme-font-family: 'montserrat'
}

._navbar {
  position: fixed !important;
  background-color: rgba(197, 221, 239, 0.7) !important;
  /* height: 75px; */
  height: min-content;
  max-height: 75px;
  justify-content: center;
  margin: 0%;
  padding: 0%;
}

.navbarLogo {
  width: clamp(300px, 35%, 500px);
}

._btn2 {
  background: #62065b !important;
  color: #fff !important;
  padding: 13px 25px !important;
  width: auto !important;
  display: block !important;
  border-radius: 8px !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  display: inline-block !important;
}

/* Form Page */
.form_page_main_div {
  /* background: url(../images/kotel_bg.jpg) no-repeat;
  background-position: center;
  overflow: hidden;
  background-size: 100% 100%, cover; */
  height: 100%;
  width: 100%;
  /* position: relative; */
  touchAction: none;
  transform: translate3d(0px, 0px, 0px);
}


/* Form Box */
.form_box {
  background: url('../images/home/box3.png') no-repeat;
  aspect-ratio: 841/703;
  background-size: 100% auto;
}

/* Form */
.form_selector_btn {
  position: absolute;
  z-index: 99;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type='radio']:checked {
  background-color: var(--theme-color-bold) !important;
  border-color: var(--theme-color-bold) !important;
}

.form_selector_btn .selector_btn {
  height: 20px;
  width: 20px;
}

.form_content {
  height: 80%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.form_content::-webkit-scrollbar {
  width: 10px;
  margin-left: 5px !important;
}




/* Form Validation */
.err:invalid:focus {
  box-shadow: 0 0 15px rgb(217 48 37);
}

.err:invalid {
  color: rgb(217 48 37) !important;
  border-color: rgb(217 48 37) !important;
}

.err_text {
  display: none;
}

.err:invalid~.err_text {
  color: rgb(217 48 37) !important;
  display: block;
}

.err:invalid~.space {
  display: none;
}

/* Parent Form Validation */
.parent_form:focus {
  box-shadow: none !important;
}

.parent_form[focused="true"][require="true"]::placeholder {
  color: rgb(217 48 37) !important;
}


/* EMAIL VERIFICATION */
/* INPUT FEILD*/
.dialog_input {
  margin: auto;
  text-align: center;
  width: 50% !important;
}

.login_box {
  background: url('../images/login_box.png') no-repeat;
  aspect-ratio: 679/693;
  background-size: 100% auto;
  min-width: 450px;
}

.forgotPassBox {
  background: url('../images/sign_box.png') no-repeat;
  aspect-ratio: 679/607;
  background-size: 100% auto;
  min-width: 575px;
}

.parent {
  overflow: hidden;
}

/* Track */
.form_content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--theme-color-bold);
  border-radius: 10px;
}

/* Handle */
.form_content::-webkit-scrollbar-thumb {
  background: var(--theme-color-bold);
  border-radius: 10px;
}

/* Handle on hover */
.form_content::-webkit-scrollbar-thumb:hover {
  background: var(--theme-color-bold);
}

.signUp-subm-btn {
  float: none !important;
  display: inline-block !important;
}

.radio-button-lable {
  text-transform: capitalize;
}

.profile {
  background: url('../images/mission_box.png') no-repeat;
  background-size: 100% auto;
  aspect-ratio: 1081/760;
}

.profile_scrollbar::-webkit-scrollbar {
  width: 20px;
}

/* Handle */
.profile_scrollbar::-webkit-scrollbar-thumb {
  background: var(--theme-color-bold);
  border: 4px solid rgba(0, 0, 0, 0);
  /* Transparent border */
  background-clip: padding-box;
  /* Clip background within padding area */
  border-radius: 9999px;
  /* Rounded corners */
}

.user_profile {
  position: relative;
  height: 90%;
}

.left_girl {
  float: left;
  left: 5%;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  transition: .5s ease;
  z-index: 999;
}

.right_boy {
  float: left;
  left: 5%;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  transition: .5s ease;
  z-index: 999;
}


.left_girl img:hover,
.right_boy img:hover {
  -webkit-filter: drop-shadow(2px 2px 0 #fff) drop-shadow(-2px -2px 0 #fff);
}

.left_girl:hover,
.right_boy:hover {
  transform: scale(1.1) !important;
  margin-top: -20px !important;
  cursor: pointer;
  transition: .5s ease;
}

.loginErrorText {
  display: none;
  color: rgb(217 48 37) !important;
}

/* Login Error */
.loginError {
  border-color: rgb(217 48 37) !important;
  display: block !important;
}


/* TABLE */
.tableBorder {
  border: 4px solid black;
  border-radius: 10px;
  height: 90%;
}

.profileBorder {
  border: 4px solid black;
  border-radius: 10px;
  height: 100%;
  width: 95%;
}

.profileIcon {
  display: block;
}

.inputBorder {
  border: 1.5px solid black;
}

.inputInvalid {
  /* color: rgb(217 48 37)!important; */
  border: 2px solid rgb(217 48 37);
  border-color: rgb(217 48 37) !important;

}

.inputInvalid:focus {
  border: 2px solid rgb(217 48 37) !important;
  outline: 1px solid rgb(217 48 37);
  border-color: rgb(217 48 37) !important;
  box-shadow: 0 0 15px rgb(217 48 37) !important;
}

.profileTable {
  font-size: x-large;
}

.changePassInput {
  border-radius: 12px !important;
}

.highlight:hover {
  color: blue;
}

.purple {
  color: purple;
}

.purple_btn {
  background-color: #62065b;
}

.dialogMui .MuiPaper-root {
  /* background-image: url('../images/CenterBox_lg.png'); */
  background-repeat: no-repeat;
  aspect-ratio: 2363/1518;
  background-size: 100% auto;
  background-color: #445eaa !important;
  border-radius: 40px;
  animation-name: drop;
  overflow: visible;
  padding: 1%;
}

/* Parent Form */
@media (min-width: 1775px) {
  .school {
    overflow-y: hidden;
  }
}

@media (max-width:1400px) {
  .parent {
    overflow-y: scroll;
  }

  .forgotPassBox {
    min-width: 480px;
  }
}

@media (max-width: 1305px) {
  .form_selector_btn .selector_btn {
    height: 15px;
    width: 15px;
  }

  .radio-button-lable {
    font-size: larger;
  }

}

@media (min-width: 1200px) and (max-width: 1250px) {
  .profileTable {
    font-size: larger;
  }
}

@media (max-width: 1200px) {
  .profileIcon {
    display: none;
  }
}

/* @media (max-width: 1200px) {
  .left_girl{
    margin-top: 22%;
  }

  .right_boy{
    margin-top: 20%;
  }
} */

@media (max-width: 993px) {
  .profile_mobile {
    padding: 0% 10%;
  }

  .left_girl {
    display: none !important;
  }

  .right_boy {
    display: none !important;
  }
}

@media (max-width: 950px) {
  .profile_mobile {
    padding: 0% 10%;
  }
}

@media (max-width: 830px) {
  .profile_mobile {
    padding: 0% 7%;
  }
}

@media (max-width: 770px) {

  .forgotPassBox {
    min-width: 480px;
  }

  .profile {
    background: url('../images/mission_box.png') no-repeat;
    background-size: 100% auto;
    aspect-ratio: 1081/760;
  }

}

@media (min-width: 568px) and (max-width: 1140px) {
  .form_box {
    min-width: 475px;
  }
}

@media (max-width: 576px) {
  .profile_mobile {
    padding: 0% 0%;
  }
}

@media (orientation: portrait) {

  @media (max-width: 500px) {
    .inner_form_mobile {
      padding: 3.5% 5% !important;
    }

    .sponsored_footer {
      display: none;
    }

    .navbarLogo {
      width: clamp(250px, 35%, 400px);
    }
  }


}



@media(max-width:480px) {
  .form_box {
    margin-top: 17%;
    background: url('../images/home/mobile_sign_up.png') no-repeat;
    aspect-ratio: 544/841;
    background-size: 100% auto;
  }

  .forgotPassBox {
    min-width: 400px;
  }

  .teacher {
    overflow-y: hidden;
  }

  .school {
    overflow-y: hidden;
  }

  .form_selector_btn {
    position: absolute;
    z-index: 99;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 309px;
  }

  .form_content {
    height: 75%;
  }

  .login_box {
    min-width: 420px;
  }

  .mobile_input {
    max-width: 90%;
  }
}

@media (max-width: 420px) {
  .login_box {
    min-width: 390px;
  }

  .forgotPassBox {
    min-width: 400px;
  }
}

@media (max-width: 390px) {

  .login_box {
    min-width: 370px;
  }

  .forgotPassBox {
    min-width: 350px;
  }
}

@media (max-width:376px) {
  .form_content {
    height: 60%;
  }

  .login_box {
    min-width: 340px;
  }

  .parent {
    overflow-y: scroll;
  }

  .teacher {
    overflow-y: scroll;
  }

  .school {
    overflow-y: scroll;
  }

  .forgotPassBox {
    min-width: 340px;
  }
}


@media (max-width: 320px) {
  .login_box {
    min-width: 310px;
  }

  .forgotPassBox {
    min-width: 320px;
  }
}

@media (orientation: landscape) and (max-width: 940px) and (max-height: 600px) {
  .dialogMui .MuiPaper-root {
    margin-top: 13%;
    scale: .8;
    padding: 1%;
  }

  .sponsored_footer {
    display: none;
  }
}