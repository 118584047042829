@import '../../../responsive';

.adminPanel {
  display: flex;
  flex-direction: column;
  background: hsl(var(--brand));
  min-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @include tablet {
    padding: 2.5rem;
  }
}

.mainContent {
  max-width: 1200px;
  margin: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.section {
  height: 20rem;
  display: flex;

  @include desktop {
    margin-bottom: 2.5rem;
  }

  @include mobile {
    margin: -2rem 0 -3rem 0;
  }
}

.sideMenu {
  display: none;

  @include tablet {
    display: block;
  }
}

.tables {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2.5rem
}