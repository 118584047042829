.container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(104, 108, 106, 0.597);
  z-index: 100;
}

.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.contentContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.closeIconWrapper{
  position: relative;
  transform: translate(20px, -43px);
  /* transform: translate(5px, -5px); */
}

.closeIconContainer{
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.iconBorder{
  /* border: 2px solid white ; */
  border-radius: 50%;
  /* filter: drop-shadow(1px 1px 3px rgb(0, 0, 0)); */

}

.closeIcon{
  color: white;
  /* filter: drop-shadow(1px 1px 3px rgb(255, 255, 255)); */
  font-size: 2rem !important;
  /* background-color: #445eaa; */
  /* border-radius: 50%; */
  /* padding: 3px; */
}

.content{
  width: 100%;
  margin: 0 10%; 
  align-self: center;
}

@media (orientation: landscape) {
  @media (max-height: 600px) {
    .closeIconWrapper{
      transform: translate(5px, -5px);
    }
    
  }
}