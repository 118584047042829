.achievements_container {
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.page {
  background: url(../../../assets/images/userSite/achievement_box_empty.png) no-repeat;
  background-size: 100% 100%, cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: clamp(14px, 2vh, 30px);
  scale: 1;
}

.ach_banner{
  display: none;
}

/* .ach_banner {
  display: block;
  position: absolute;
  z-index: 10;
  top: -26%;
  left: 1%;
}

.ach_banner img {
  width: 40%;
  height: auto;
} */

@media (orientation: portrait) {
  .achievements_container {
    position: relative;
    width: 100%;
    padding: 0 5%;
    height: 90%;
  }

  .page {
    background: url(../../../assets/images/CenterBoxPortrait.png) no-repeat;
    background-size: 100% 100%, cover;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    scale: 1;
  }

  .ach_banner {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: -13%;
    margin-left: -1%;
  }

  .ach_banner img {
    width: 50%;
    height: auto;
  }
}

@media (orientation: portrait) and (min-width: 465px) and (max-width: 950px) {
  .achievements_container{
    margin-left: 0;
  }
}

@media (orientation: portrait) and (min-width: 750px) {
  .ach_banner {
    top: -17%;
  }

  .ach_banner img {
    width: 50%;
  }
}

/* TEMP */
@media (orientation: portrait) {
  .ach_banner{
    display: none;
  }
}