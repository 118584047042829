.button {
  background-color: hsl(var(--brand));
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;

  &:hover {
    background-color: hsl(var(--brand-700));
  }

  &:active {
    background-color: hsl(var(--brand-600));
  }
}