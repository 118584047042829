.tables {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 2rem;
  background-color: #445eaa;
}

.tables > div {
  width: 100%;
}

.iconContainer {
  border: 2px solid #445eaa;
  border-radius: 50%;
}

.addIcon {
  color: #445eaa;
}

.addStudentTableContainer {
  width: 90vw;
}

@media (orientation: landscape) {
  @media (max-height: 600px) {
    .addStudentTableContainer{
      height: 100vh;
      padding-top: 4rem;
      padding-right: 1rem;
    }
  }
}
