@import "../../../responsive.scss";

.centerContentContainer {
  height: 100%;
}

.title {
  font-family: var(--theme-font-family);
  font-weight: 900;
  color: white;
  position: relative;
  text-align: center;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid var(--theme-color);
  width: 100%;
  height: 5px;
  padding: 5px;
  margin: 0 auto;
  text-align: center;
  width: 200px;
  left: 0;
  right: 0;
  bottom: -13px;
}

.subTitle {
  padding-top: 20px !important;
  color: white;
}

.widgetContainer{
  height: 50vh;
  overflow: auto;
}


@media (orientation: landscape) and (max-height: 600px) { 
  .contentBackCustomClass{
    margin-top: 25vh;
    width: 80vw !important;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .contentBackCustomClass {
    @include tablet {
      width: 90vw !important;
    }
  }
}
