.mainContainer {
  // display: none;
  height: 100%;
  width: 100%;
  background-color: #415aa4;
  padding: 5rem;
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.innerContainer {
  background-color: #f4f4f4;
  width: 100%;
  min-height: 75%;
  border-radius: 15px;
  padding: 4rem 1.5rem;
}

.printLogoContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 5rem;

  .printLogoImg {
    width: 500px;
  }
}

@media print {
  .mainContainer {
    // display: block;
    position: static;
    left: auto;
    top: auto;
  }
}
