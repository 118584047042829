@import '../../../responsive.scss';

.nav {
  height: 4rem;
  background: hsl(var(--brand-100));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  position: relative;
  z-index: 2;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .hamburger {
    display: block;
  }

  @include tablet {
    padding: 0 2rem;

    .hamburger {
      display: block;
    }
  }

  .logo {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    img {
      height: 2.5rem;
      width: auto;
      cursor: pointer;
    }
  }

  .profile {
    background: hsl(var(--brand));
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    color: hsl(var(--brand-100));
  }
}

.menu {
  display: none;

  @include tablet {
    display: none;
  }
}