.achievements_container {
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 6%;
}

.page {
  background: url(../../../assets/images/CenterBox_lg.png) no-repeat;
  background-size: 100% 100%, cover;
  height: 50%;
  width: auto;
  aspect-ratio: 2527/1518;
  padding-inline: 5%;
  max-height: 600px;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  font-size: clamp(14px, 2vh, 30px);
  scale: 1;
  /* padding-top: 16%;
  padding-bottom: 5%; */
}

.ach_banner {
  display: block;
  position: absolute;
  z-index: 10;
  top: -26%;
  left: 1%;
}

.ach_banner img {
  width: 40%;
  height: auto;
}

.statsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  width: 100%;
  margin-bottom: 5%;
}

.desktop_stats {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  height: 100%;
}

.mobile_stats {
  display: none;
}

.carouselBlock {
  width: 28%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scale: 1;
}

.ach_carasolItem {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carouselImgContainer {
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
  margin-bottom: 10%;
}

.bannerImg {
  width: 50%;
  height: auto;
}

/* .coinBanner {
  margin-left: 9% !important;
} */

.starsBanner {
  width: 60%;
}

.ach_carasolItem_bkg {
  /* background-color: rgba(70, 93, 186, 0.562); */
  background: linear-gradient(to bottom, transparent 13%, rgba(70, 93, 186, 0.562) 13%);
  width: 100%;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.carouselInner {
  display: flex;
  flex-direction: column;
  /* padding-top: 25%; */
  height: 100%;
}

.box_text {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  height: 100%;
}

.box_value {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  /* flex-grow: 1; */
  color: white !important;
}

.text {
  color: white;
  font-size: 3vh !important;
  font-weight: 900;
}

.markMissionContainer {
  align-self: center;
  height: 5dvh;
  width: 100%;
  margin-bottom: 20px;
}

.markMissionImg {
  max-width: 100%;
  max-height: 100%;
}

.carouselBlockButton {
  width: 100%;
  padding: .3em .5em;
  background-color: #f8de0d;
  border-radius: .75em;
  color: #445eaa;
  font-weight: 900;
  font-size: 1.25em;
}

@media (orientation: portrait) {
  .achievements_container {
    position: relative;
  }

  .page {
    background: url(../../../assets/images/CenterBoxPortrait.png) no-repeat;
    aspect-ratio: 1518/2383;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-block: 0;
    scale: 1;
    /* margin-top: 60px;
    margin-bottom: -5%; */
  }

  .ach_banner {
    display: block;
    position: absolute;
    z-index: 10;
    top: -9%;
    margin-left: -1%;
  }

  .ach_banner img {
    width: 50%;
    height: auto;
  }

  .statsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    /* margin-bottom: -10%; */
  }

  .desktop_stats {
    display: none;
  }

  .mobile_stats {
    display: flex;
    margin-left: 13%;
    margin-right: 13%;
    height: 100%;
    overflow: visible;
    justify-content: center;
    align-items: center;
  }

  .carouselBlock {
    display: flex;
    width: 100%;
    height: 75%;
    align-self: center;
    justify-self: center;
    margin: auto 0;
  }

  .carouselImgContainer {
    height: 35%;
    margin-top: 20px;
  }

  /* .bannerImg {
    margin-left: 0%;
    padding-top: 30%;
  } */

  .ach_carasolItem_bkg {
    padding: 0 10%;
  }

  .carouselInner {
    width: 100%;
    height: 100%;
    /* min-height: 350px;
    max-height: 70vh; */
  }

  .rankImgContainer {
    padding: 5%;
  }

  .box_text {
    width: 100%;
    /* padding-top: 15%; */
    /* padding-right: 8%; */
    width: 100%;
    word-wrap: break-word;
    justify-content: space-evenly;
  }

  .text {
    font-size: clamp(16px, 4vh, 30px) !important;
  }

  .carouselBlockButton {
    font-size: clamp(16px, 2vh, 30px) !important;
  }

  .box_value {
    /* padding-left: 10%; */
    overflow: hidden;
    margin-top: 20% !important;
  }
}

@media (orientation: portrait) and (min-width: 500px) {
  .mobile_stats{
    padding-top: 15%;
  }
  .carouselImgContainer {
    /* top: -15%; */
    display: flex;
    justify-content: center;
    width: 100%;
    /* left: 8%; */
  }

  .carouselBlock {
    height: 55dvh;
    margin: 0;
    padding-bottom: 10%;
  }

  .bannerImg {
    /* width: 35%; */
    height: auto;
    padding-top: 0;
  }

  .coinBanner {
    margin-left: 0 !important;
    padding-bottom: 15%;
  }

  .starsBanner {
    width: 45%;
  }

  .starsImgContainer {
    width: 100%;
    top: -30%;
    left: 1%;
  }

  .rankImgContainer {
    width: 100%;
    top: -23%;
    left: -1%;
  }

  .starsImgContainer {
    top: -15%;
  }

  .box_text {
    width: 100%;
    padding-right: 0;
    /* padding-left: 3%; */
    margin-bottom: 0 !important;
    padding-top: 0;
  }

  .box_value {
    padding-left: 0;
  }

  .text {
    font-size: clamp(18px, 3.75vh, 50px) !important;
  }

  /* .markMissionImg {
    padding-top: 13%;
    margin-right: 10%;
  } */
}

/* @media (orientation: portrait) and (min-width: 951px){
  .ach_banner {
    top: -15%;
  }

  .ach_banner img {
    width: 60%;
  }
  
  .carouselImgContainer{
    top: -28%;
  }

  .carouselBlock{
    height: 43vh;
  }
  
  .coinBanner{
    margin-left: 0 !important;
  }

  .rankImgContainer{
    top: -30%;
  }

  .ach_carasolItem_bkg {
    padding: 0;
  }

  .box_text{
    padding-right: 0;
    padding-left: 3%;
    margin-bottom: 0 !important;
    padding-top: 0;
  }

  .box_value{
    padding-left: 0;
  }

  .text {
    font-size: clamp(18px, 3.25vh, 45px) !important;
  }

  .markMissionImg{
    padding-top: 13%;
    margin-left: -0.5%;
  }
} */


@media (orientation: landscape) and (max-height: 550px) {

  .carouselBlockButton {
    font-size: clamp(6px, 1em, 3vh);
  }
}

@media (orientation: landscape) and (max-width: 940px) and (max-height: 600px) {
  .achievements_container {
    margin: 11dvh 15% -5% 15%;
    overflow: hidden;
  }

  .statsContainer {
    padding-bottom: 3%;
  }

  .markMissionImg {
    overflow: hidden;
  }
}

@media (orientation: landscape) and (max-width: 1400px) and (min-height: 900px) {
  .statsContainer {
    padding-top: 5%;
    padding-bottom: 0%;
  }
}

@media (orientation: landscape) and (max-width: 1200px) and (min-height: 750px) {
  .statsContainer {
    padding-top: 5%;
    padding-bottom: 0%;
  }
}