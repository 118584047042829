@import "../../../../index.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}


.shootingStars {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.shootingStar {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  width: 10px;
  height: 10px;
  background: url('#{$S3-PUBLIC-IMAGES}/userSite/quests/star.png');
  background-size: contain;
  filter: drop-shadow(0 0 10px white);
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.tile {
  width: 300px;
  height: 180px;
  padding: 1rem;
  z-index: 10;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}